import { useState } from 'react'
import { FC } from '../../Services'
import { Button, Flex, Input, Spinner, Text } from '../../Components'
import { CardResult } from './CardResult'
import { alert } from '../AdminCoefficient/Functions'
import { AutoComplete } from 'primereact/autocomplete'
import options from '../Gigi/Resources/airports.json'
import _ from 'lodash'
import { seatOptions } from './Functions'
import { useAuth } from '../../Provider/AuthProvider'

const checkDateFormat = (date) => (/^\d{1,2}-\d{1,2}-\d{4}$/).test(date)
const checkHourFormat = (hour) => (/^\d{1,2}:\d{1,2}$/).test(hour)

const searchIataCode = (setter) => _.debounce((event) => {
  if (!event.query.trim().length) setter([...options])
  else {
    setter(([
      ...options.filter(({ value }) => value.toLowerCase().startsWith(event.query.toLowerCase())),
      ...options.filter(({ label }) => label.toLowerCase().includes(event.query.toLowerCase()))
    ]))
  }
}, 1000)

export const PlaneServices = (type) => {
  const [departureDate, setDepartureDate] = useState('15-01-2024')
  const [departureHour, setDepartureHour] = useState('10:00')
  const { user: { user: { environment: { codSociety } } } } = useAuth()
  const [idCommunity, setIdCommunity] = useState(codSociety === '90'
    ? '26'
    : process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? '87' : '1')
  const [departureAirport, setDepartureAirport] = useState()
  const [arrivalAirport, setArrivalAirport] = useState()
  const [results, setResults] = useState([])
  const [seatPreference, setSeatPreference] = useState('')
  const [bagKg, setbagKg] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [suggestionsArrivalAirport, setSuggestionsArrivalAirport] = useState([])
  const [suggestionsDepartureAirport, setSuggestionsDepartureAirport] = useState([])

  const sendRequest = async () => {
    setResults([])
    if (!departureAirport) return alert('error', 'Aeroporto di partenza non valido')
    if (!arrivalAirport) return alert('error', 'Aeroporto di arrivo non valido')
    if (!checkDateFormat(departureDate) || !checkHourFormat(departureHour)) return alert('error', 'Formato date/ora non valido')
    setIsSearching(true)
    const res = await FC.service('info').create({ action: 'previewDynResults', serviceType: 'Plane', bagKg, departureDate, seatPreference, departureHour, departureAirport, arrivalAirport, idCommunity })
    setResults(res)
    setIsSearching(false)
  }

  return (
    <>
      <Text value='Ricerca AEREI' bold title size={24} style={{ marginBottom: 30 }} />
      <Flex row wrap style={{ gap: 20 }}>
        <span className='p-float-label'>
          <Input value={idCommunity} onChange={({ idCommunity }) => setIdCommunity(idCommunity)} id='idCommunity' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='idCommunity' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Id Community</label>
        </span>
        <span className='p-float-label'>
          <Input id='bagKg' dropdown options={['', '5', '10', '15', '20', '25']} value={bagKg} onChange={({ bagKg }) => setbagKg(bagKg)} style={{ width: 200 }} onEnter={sendRequest} />
          <label htmlFor='bagKg' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Kg Bagaglio</label>
        </span>
        <span className='p-float-label'>
          <Input id='seatPreference' dropdown options={seatOptions} value={seatPreference} onChange={({ seatPreference }) => setSeatPreference(seatPreference)} style={{ width: 200 }} onEnter={sendRequest} />
          <label htmlFor='idCommunity' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Preferenza posto</label>
        </span>
        <span className='p-float-label'>
          <AutoComplete
            field='value' id='departureAirport' onSelect={({ value: { value } }) => setDepartureAirport(value)} style={{ width: '100%' }}
            value={departureAirport} suggestions={suggestionsDepartureAirport} completeMethod={searchIataCode(setSuggestionsDepartureAirport)} onChange={(e) => setDepartureAirport(e.value)}
            inputStyle={{ width: 300, borderRadius: 20 }} forceSelection itemTemplate={({ both = '' }) => both}
          />
          <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>Aeroporto partenza</label>
        </span>
        <span className='p-float-label'>
          <AutoComplete
            field='value' id='arrivalAirport' onSelect={({ value: { value } }) => setArrivalAirport(value)} style={{ width: '100%' }}
            value={arrivalAirport} suggestions={suggestionsArrivalAirport} completeMethod={searchIataCode(setSuggestionsArrivalAirport)} onChange={(e) => setArrivalAirport(e.value)}
            inputStyle={{ width: 300, borderRadius: 20 }} forceSelection itemTemplate={({ both = '' }) => both}
          />
          <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>Aeroporto arrivo</label>
        </span>
        <span className='p-float-label'>
          <Input value={departureDate} onChange={({ departureDate }) => setDepartureDate(departureDate)} id='departureDate' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='departureDate' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Data Partenza</label>
        </span>
        <span className='p-float-label'>
          <Input value={departureHour} onChange={({ departureHour }) => setDepartureHour(departureHour)} id='departureHour' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='departureHour' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Ora Partenza</label>
        </span>
      </Flex>
      {isSearching
        ? <Spinner />
        : <Button label='Cerca' style={{ marginTop: 20 }} icon='lens' onClick={sendRequest} />}
      <Flex row wrap style={{ marginTop: 10, marginBottom: 30, minHeight: 300, gap: 20 }}>
        {results.map((result, index) => <CardResult key={index} result={result} type={result?.serviceType} />)}
      </Flex>
    </>
  )
}
