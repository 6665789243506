import { Button, Flex, Modal, Text } from '..'

export const ConfirmModal = ({ visible, header, label, onConfirm }) => {
  const [modalVisible, setModalVisible] = visible
  return (
    <Modal visible={modalVisible} header={header} onHide={() => setModalVisible(false)}>
      <Flex fw>
        <Text value={label} size={18} />
        <Flex fw row jb style={{ marginTop: 20 }}>
          <Button label='Annulla' onClick={() => setModalVisible(false)} style={{ marginRight: 20 }} />
          <Button label='Conferma' onClick={() => { onConfirm(); setModalVisible(false) }} />
        </Flex>
      </Flex>
    </Modal>
  )
}
