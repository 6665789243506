import React from 'react'
import { motion } from 'framer-motion'

export const PanelChip = ({ style, value, selected, onClick }) => {
  const doNothing = () => null

  const onClickFunction = (e) => {
    // Evita il doppio click
    if (e?.detail !== 1) doNothing()
    else onClick(e)
  }

  return (
    <motion.div
      onClick={onClickFunction}
      style={{
        display: 'flex',
        padding: '4px 13px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: 8,
        border: '1px solid #EBEBEB',
        fontFamily: 'Lexend',
        cursor: 'pointer',
        ...style
      }}
      className={selected ? 'chip-selected' : 'chip'}
    >
      {value}
    </motion.div>
  )
}
