import React from 'react'
import { Card, Flex, Text } from '../../../Components'
import { Checkbox } from 'primereact/checkbox'

export const Provider = ({ providers: [providers, setProviders], company }) => (
  <Card style={{ marginRight: 10 }}>
    <Flex as js fw row style={{ marginBottom: 10 }}>
      <Checkbox
        checked={providers?.every((provider) => provider?.company !== company || !provider?.disabled)}
        onChange={(e) =>
          setProviders(providers.map((provider) => provider?.company === company
            ? { ...provider, disabled: !e.checked }
            : provider))}
      />
      <Text bold value={company} style={{ marginLeft: 10 }} size={18} />
    </Flex>
    {providers?.filter((provider) => provider?.company === company).map((provider, i) => (
      <Flex as js key={i} fw row style={{ marginBottom: 10 }}>
        <Checkbox
          checked={!provider?.disabled}
          onChange={(e) =>
            setProviders(providers.map((prov) => prov?.provider === provider?.provider
              ? { ...prov, disabled: !e.checked }
              : prov))}
        />
        <Text value={provider?.provider} style={{ marginLeft: 10 }} />
      </Flex>
    ))}
  </Card>
)
