import { Dropdown } from 'primereact/dropdown'
import { Button, Flex } from '../../../Components'
import { InputNumber } from 'primereact/inputnumber'

const commonProps = {
  className: 'inputNumbers',
  disabled: true,
  mode: 'decimal',
  showButtons: true,
  style: { marginTop: 5 },
  inputStyle: { width: '15%', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, borderRight: '0px', height: 45, margin: 'auto' },
  incrementButtonClassName: 'incrementNumberButton',
  decrementButtonClassName: 'decrementNumberButton'
}

export const NewFilterDropdown = ({ options, name, selectedFilters: [selectedFilters, setSelectedFilters], flagAbilitazione: [flagAbilitazione, setFlagAbilitazione] = [] }) => {
  const displayOptions = options.filter((option) => !Object.values(selectedFilters)
    .some((selectedFilter) => selectedFilter.code === option.code)) || []

  if (displayOptions.length) {
    return (
      <Flex row fw js as style={{ gap: 20 }}>
        <Dropdown
          onChange={(e) => setSelectedFilters([...selectedFilters, e.value])}
          options={displayOptions}
          optionLabel='name'
          placeholder='Aggiungi filtri'
          style={{ marginTop: 5, width: '35%', borderRadius: 13 }}
        />
        <InputNumber
          className='inputNumbers'
          placeholder='N. proposte'
          disabled
          mode='decimal'
          showButtons
          min={0}
          max={50}
          style={{ marginTop: 5 }}
          inputStyle={{ width: '15%', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, borderRight: '0px', height: 45, margin: 'auto' }}
          incrementButtonClassName='incrementNumberButton'
          decrementButtonClassName='decrementNumberButton'
        />
        {name === 'Treno' && flagAbilitazione && (
          <>
            <InputNumber
              {...commonProps}
              placeholder='N. rimborsabili'
            />
            <InputNumber
              {...commonProps}
              placeholder='N. non rimborsabili'
            />
          </>
        )}
        <Flex width={40} row>
          <Button round icon='close' hidden />
        </Flex>
      </Flex>
    )
  }
  return null
}
