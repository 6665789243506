import React from 'react'
import { Card, ProgressBarComponent, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { getColor } from './Functions'

export const ServerStatus = ({ dates }) => {
  const { data: { gianpietroStats = [], channels = [] } = {}, isSuccess } = useCQuery(['getStats', ...dates])

  const authenticated = channels.find(({ channel }) => channel === 'authenticated')?.connections?.length || 0

  if (!isSuccess) return null
  return (
    <Card title='Stato server' style={{ width: '100%', height: '100%' }}>
      <Text value={`Operatori collegati: ${authenticated}`} />
      <br />
      {gianpietroStats.map(({ lastMinuteTokens, lastMinuteRequests, inQueueRequests, maxTokensPerMinute, maxExecutionsPerMinute, model }, ind) => (
        <React.Fragment key={ind}>
          <Text style={{ marginTop: 5 }} value={`Modello ${model}`} bold />
          <Text style={{ marginTop: 5 }} value={`Token utilizzati nell'ultimo minuto: ${lastMinuteTokens}`} />
          <div style={{ width: 490 }}>
            <ProgressBarComponent value={lastMinuteTokens} total={maxTokensPerMinute} color={getColor(lastMinuteTokens, maxTokensPerMinute)} />
          </div>
          <Text style={{ marginTop: 5 }} value={`Richieste elaborate nell'ultimo minuto: ${lastMinuteRequests}`} />
          <div style={{ width: 490 }}>
            <ProgressBarComponent value={lastMinuteRequests} total={maxExecutionsPerMinute} color={getColor(lastMinuteRequests, maxExecutionsPerMinute)} />
          </div>
          <Text style={{ marginTop: 5 }} value={`Richieste in coda: ${inQueueRequests}`} />
        </React.Fragment>
      ))}
    </Card>
  )
}
