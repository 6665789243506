import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { FC } from '.'
import { queryClient } from '../App'
import { getDateObj } from './Functions'
import { useEffect } from 'react'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes,
    refetchInterval: 10 * time.seconds
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const DAY_MS = 24 * 60 * 60 * 1000

const queries = {
  environments: {
    config: queryConfig.rare,
    queryFn: () => FC.service('environments').find({})
  },
  environment: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('environments').get(id)
      return res
    }
  },
  users: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find({})
  },
  user: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('users').get(id)
      return res
    }
  },
  travellers: {
    config: queryConfig.rare,
    queryFn: () => FC.service('travellers').find({})
  },
  traveller: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') {
        return { id: 'new' }
      }
      const res = await FC.service('travellers').get(id)
      return res
    }
  },
  prompts: {
    config: queryConfig.rare,
    queryFn: async () => {
      const res = await FC.service('prompts').find({})
      return res
    }
  },
  prompt: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return null
      const res = await FC.service('prompts').get(id)
      return res
    }
  },
  units: {
    config: queryConfig.rare,
    queryFn: () => []
  },
  processes: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, selectedDate] }) => selectedDate ? FC.service('process').find({ query: { $and: [{ createdAt: { $gte: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0) } }, { createdAt: { $lte: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59, 999) } }] } }) : []
  },
  getStats: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, startDate, endDate] }) => {
      if (!startDate || !endDate) return []
      return FC.service('info').get('getStats', { query: { startDate: getDateObj(startDate), endDate: getDateObj(endDate) } })
    }
  },
  pricingPerProcess: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, processId] }) => {
      if (!processId) return []
      return FC.service('info').get('pricingPerProcess', { query: { processId } })
    }
  },
  pricingPerConversation: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, conversationId] }) => {
      if (!conversationId) return []
      return FC.service('info').get('pricingPerConversation', { query: { conversationId } })
    }
  },
  processSteps: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, processId] }) => processId ? FC.service('processStep').find({ query: { processId } }) : []
  },
  errorProcessStepsRange: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, startDate, endDate] }) => (startDate && endDate)
      ? FC.service('processStep').find({ query: { $or: [{ stepState: 5 }, { stepState: 6 }], $and: [{ createdAt: { $gte: new Date(startDate).getTime() } }, { createdAt: { $lte: new Date(endDate).getTime() + DAY_MS } }] } })
      : []
  },
  conversations: {
    config: queryConfig.frequent,
    queryFn: ({ queryKey: [key, selectedDate] }) =>
      selectedDate
        ? FC.service('info').get('getConversationsOperatore', { query: { date: selectedDate } })
        : []
  },
  conversation: {
    config: {
      ...queryConfig.frequent,
      retry: (failureCount, error) => {
        if (error?.toString()?.includes('Conversation not found')) return false
        if (failureCount < 3) return true
      }
    },
    queryFn: ({ queryKey: [key, conversationId] }) => conversationId ? FC.service('info').get('getConversationOperatore', { query: { conversationId } }) : null
  },
  communityPresets: {
    config: queryConfig.rare,
    queryFn: () => FC.service('communityPreset').find()
  },
  communityPreset: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, presetId] }) => presetId ? FC.service('communityPreset').find({ query: { id: presetId, state: 1 } }) : []
  },
  adminCoefficient: {
    config: queryConfig.rare,
    queryFn: () => FC.service('adminCoefficient').get(1)
  },
  autoTests: {
    config: queryConfig.rare,
    queryFn: () => FC.service('autoTests').find()
  },
  autoTest: {
    config: {
      ...queryConfig.rare,
      retry: (failureCount, error) => {
        console.log('error', error?.toString())
        if (error?.toString()?.includes('No record found for id')) return false
        if (failureCount < 3) return true
      }
    },
    queryFn: ({ queryKey: [key, autoTestId] }) => autoTestId ? FC.service('autoTests').get(autoTestId) : null
  },
  flagAbilitazione: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, flagName] }) => flagName ? FC.service('info').get('getFlagAbilitazione', { query: { flagName } }) : null
  }
}

export const useCQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return useQuery({ queryKey, queryFn, ...config })
}

export const prefetchQuery = (queryKey, extraQueries = []) => {
  const { queryFn, config } = { ...queries, ...extraQueries }[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryClient.prefetchQuery({ queryKey, queryFn, config })
}

/* export const prefetchQueries = (extraQueries) =>
  Object.entries({ ...queries, ...extraQueries }).forEach(([queryKey, { config, queryFn, defaultKeys = [], noInitalPrefetch }]) => {
    const key = [queryKey, ...defaultKeys]
    !noInitalPrefetch && queryClient.prefetchQuery(key, queryFn, config)
  }) */

export const invalidateQuery = (queryKeys) =>
  (Array.isArray(queryKeys) ? queryKeys : [queryKeys])
    .forEach(queryKey => queryClient.invalidateQueries({ queryKey: [queryKey] }))

// FETCH QUERY

/* export const fetchQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return queryClient.fetchQuery({ queryKey, queryFn, ...config })
} */

// INFINITE QUERIES
const infiniteQueries = {
  infiniteConversations: {
    firstPageLength: 1,
    pageLength: 1,
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, query = {}] = [] }) =>
      FC.client.service('info').get('getConversationsOperatore', {
        query: {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          $sort: { createdAt: -1 },
          ...query
        }
      }),
    noInitalPrefetch: true
  }
}

export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: queryFn(firstPageLength, pageLength),
    // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
    getNextPageParam: (lastPage, pages) =>
      (pages?.length === 1)
        ? ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === firstPageLength) ? pages?.length + 1 : null
        : ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === pageLength) ? pages?.length + 1 : null,
    ...config
  })

  // controllo se la pagina è un array o un oggetto con data e ritorno un array di pagine.
  // perchè se è definita la paginazione sul server ritorna un oggetto con data e se non è definita ritorna un array
  const pages = data?.pages?.map(page => Array.isArray(page) ? page : page.data).flat()

  useEffect(() => { !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage() }, [isFetchingNextPage, isFetching])

  return { data: pages, isSuccess, isFetchingNextPage }
}
