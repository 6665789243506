import React from 'react'
import { cap } from '../../State'
import ReactJson from 'react-json-view'

export const JsonViewer = ({ style, ...props }) => {
  const [darkMode] = cap.darkMode.useState()
  return (
    <ReactJson
      onAdd={false} displayObjectSize={false} displayDataTypes={false}
      onEdit={false} onDelete={false} collapsed
      style={{ ...style, overflow: 'auto', backgroundColor: 'transparent' }}
      theme={darkMode ? 'monokai' : 'rjv-default'}
      {...props}
    />
  )
}
