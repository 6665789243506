import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'

export const useUser = (userId) => {
  const [user, setUser] = useState()
  const { data, isSuccess } = useCQuery(['user', userId])

  useEffect(() => {
    isSuccess && setUser(data)
  }, [isSuccess, data])

  const setUserProp = (prop, value) =>
    setUser({ ...user, [prop]: value })

  return [user, setUserProp, isSuccess]
}

export const saveUser = async (user) => {
  /* if (!user?.name) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  } */
  if (user.id === 'new') {
    delete user.id
    try {
      const res = await FC.service('users').create({ ...user })
      invalidateQuery(['user', 'users'])
      window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
      return res
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
      return false
    }
  }
  try {
    const res = await FC.service('users').patch(user.id, user)
    invalidateQuery(['user', 'users'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
    return false
  }
}

export const deleteUser = async (userId) => {
  const res = await FC.service('users').remove(userId)
  invalidateQuery(['user', 'users'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
