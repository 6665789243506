import { useState } from 'react'
import { Button, Card, Flex, Modal, Spinner, Text } from '../../../../Components'
import { CustomHeader } from '../Components'
import { getElementsByService } from '../Functions'
import { ClientCodeInput } from '../TransferField/ClientCodeInput'
import { useTransferField } from '../TransferField/Hook'
import { TravellerInput } from '../TransferField/TravellerInput'
import { TravellersCard } from '../TransferField/TravellersCard'
import { FC } from '../../../../Services'
import { setErrors, validateInput } from './Functions'

const { traveller: { name, lastname, email, clientCode, clientBusinessName } } = getElementsByService()

export const SearchTravellerSection = ({ state: [state, setState], travellerIsPresent: [travellerIsPresent, setTravellerIsPresent], readOnly }) => {
  const stateProp = [state, setState]
  const [ragioneSociale, setRagioneSociale] = useTransferField([state, setState], clientBusinessName.location, stateProp.count)
  const [codiceCliente, setCodiceCliente] = useTransferField([state, setState], clientCode.location, stateProp.count)
  const [nome, setNome] = useTransferField([state, setState], name.location, stateProp.count)
  const [cognome, setCognome] = useTransferField([state, setState], lastname.location, stateProp.count)
  const [mail, setMail] = useTransferField([state, setState], email.location, stateProp.count)
  const [foundTravellers, setFoundTravellers] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [lastnameError, setLastnameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const shouldDisplayTravellerInputs = ragioneSociale && codiceCliente

  const searchTraveller = async () => {
    if (!validateInput(
      { nome, cognome, mail },
      { setNome, setCognome, setMail },
      { setNameError, setLastnameError, setEmailError }
    )) return
    setIsSuccess(false)
    setErrors(false, false, false, setNameError, setLastnameError, setEmailError)
    const listProfilesData = await FC.service('info').get('getAutocompleteProfile', { query: { profileData: { nome, cognome, mail }, codiceCliente } })
    setFoundTravellers(listProfilesData)
    setIsSuccess(true)
    setModalVisible(true)
  }

  const chooseTraveller = async (nome, cognome, email, pid, indirizzo, citta) => {
    const res = await FC.service('info').get('getProfileInfo', { query: { pid } })
    if (res) {
      setState({
        ...state,
        transferInfo: {
          ...state.transferInfo,
          infoPax: {
            ...state.transferInfo.infoPax,
            listOfPax: [
              {
                ...state.transferInfo.infoPax.listOfPax[0],
                profileData: {
                  ...state.transferInfo.infoPax.listOfPax[0].profileData,
                  profiloViaggiatoreWS02: res
                }
              }
            ]
          }
        }
      }, true)
      setTravellerIsPresent(true)
    } else {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante la ricerca del viaggiatore' })
    }
  }

  const clearFields = () => {
    setCodiceCliente('')
    setRagioneSociale('')
    setNome('')
    setCognome('')
    setMail('')
    setErrors(false, false, false, setNameError, setLastnameError, setEmailError)
  }

  if (!isSuccess) return <Spinner />

  return (
    <Card customHeader={<CustomHeader title='RICERCA VIAGGIATORE' />} style={{ width: '100%' }}>
      <Modal visible={modalVisible} header='Seleziona un viaggiatore' onHide={() => setModalVisible(false)} style={{ width: '70vw' }}>
        {foundTravellers.length
          ? (
            <Card fw>
              <Flex fw row wrap style={{ gap: 20, justifyContent: 'flex-start' }}>
                {foundTravellers.map((user, index) => (
                  <TravellersCard
                    key={index} nome={user.nome} cognome={user.cognome} email={user.email} sex={user.sesso}
                    onClick={() => chooseTraveller(user.nome, user.cognome, user.email, user.pid, user.indirizzo1, user.localita)}
                  />
                ))}
              </Flex>
            </Card>)
          : (<Text value='Nessun viaggiatore trovato' />)}
        <Flex fw row jb style={{ marginTop: 20, justifyContent: 'flex-end' }}>
          <Button label='Annulla' onClick={() => setModalVisible(false)} style={{ marginRight: 20 }} />
        </Flex>
      </Modal>
      <Flex fw wrap row style={{ justifyContent: 'flex-start' }}>
        <ClientCodeInput state={stateProp} valueCode={[codiceCliente, setCodiceCliente]} valueRS={[ragioneSociale, setRagioneSociale]} itemCode={clientCode} itemRS={clientBusinessName} readOnly={readOnly} />
      </Flex>
      {shouldDisplayTravellerInputs &&
          (
            <>
              <Flex fw wrap row style={{ justifyContent: 'flex-start', marginTop: 5 }}>
                <TravellerInput state={stateProp} value={[nome, setNome]} item={name} readOnly={readOnly} miss={[nameError, setNameError]} />
                <TravellerInput state={stateProp} value={[cognome, setCognome]} item={lastname} readOnly={readOnly} miss={[lastnameError, setLastnameError]} />
                <TravellerInput state={stateProp} value={[mail, setMail]} item={email} readOnly={readOnly} miss={[emailError, setEmailError]} />
              </Flex>
              <Flex fw wrap row style={{ justifyContent: 'flex-start', marginTop: 5 }}>
                <Text value='Inserisci nome e cognome e/o email per effettuare la ricerca' style={{ margin: '0 1%', marginTop: 10 }} />
              </Flex>
              <Flex fw wrap row style={{ marginTop: 25, gap: 10 }}>
                <Button icon='lens' label='Cerca' onClick={() => searchTraveller()} />
                <Button icon='eliminate-round' label='Pulisci campi' onClick={() => clearFields()} />
              </Flex>
            </>
          )}
    </Card>
  )
}
