import { useEffect, useState } from 'react'
import _ from 'lodash'
import { FC } from '../../../../Services'
import { Flex } from '../../../../Components'
import { AutoComplete } from 'primereact/autocomplete'

export const ClientCodeInput = ({ state: [state, setState], valueCode: [clientCode, setClientCode], valueRS: [ragioneSociale, setRagioneSociale], itemCode, itemRS, count, readOnly }) => {
  const { label } = itemCode
  const [suggestionsCode, setSuggestionsCode] = useState([])
  const [suggestionsRS, setSuggestionsRS] = useState([])

  useEffect(() => {
  }, [clientCode])

  const debounceSearch = _.debounce(async ({ queryKey, event, setSuggestions }) => {
    const value = event.query.trim()
    // Early return if the input length is less than 3 characters
    if (value.length < 3) {
      setSuggestions([])
      return
    }

    try {
      const { data: suggestions } = await FC.service('info').get('searchClient', {
        query: { [queryKey]: value }
      })

      const filteredSuggestions = suggestions
        .filter(client => client?.datiGeneraliCliente?.[queryKey])
        .map(client => ({
          codCliente12: client?.datiGeneraliCliente?.codCliente12,
          ragioneSociale: client?.datiGeneraliCliente?.ragioneSociale
        }))

      setSuggestions(filteredSuggestions)
    } catch (error) {
      setSuggestions([])
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore ricerca cliente' })
    }
  }, 500)

  const debounceSearchCode = (event) => debounceSearch({ queryKey: 'codCliente12', event, setSuggestions: setSuggestionsCode })

  const debounceSearchRS = (event) => debounceSearch({ queryKey: 'ragioneSociale', event, setSuggestions: setSuggestionsRS })

  return (
    <>
      <Flex key={itemRS.id} as js style={{ width: '23%', margin: '0px 1%' }}>
        <div className='card flex justify-content-center' style={{ marginTop: 25, width: '100%' }}>
          <span className='p-float-label'>
            <AutoComplete
              disabled={readOnly}
              id={itemRS.id}
              inputId='ragioneSociale'
              value={ragioneSociale || ''}
              suggestions={suggestionsRS}
              completeMethod={debounceSearchRS}
              onChange={({ value }) => {
                setRagioneSociale(value)
                setClientCode('')
              }}
              onSelect={({ value }) => {
                setClientCode(value?.codCliente12)
                setRagioneSociale(value?.ragioneSociale)
              }}
              inputStyle={{ fontFamily: 'Lexend', width: '100%' }}
              style={{ width: '100%' }}
              forceSelection
              field='ragioneSociale'
            />
            <label style={{ fontFamily: 'Lexend', color: 'inherit' }}>{itemRS.label.it}</label>
          </span>
        </div>
      </Flex>
      <Flex key={itemCode.id} as js style={{ width: '23%', margin: '0px 1%' }}>
        <div className='card flex justify-content-center' style={{ marginTop: 25, width: '100%' }}>
          <span className='p-float-label'>
            <AutoComplete
              disabled={readOnly}
              id={itemCode.id}
              inputId='clientCode'
              value={clientCode || ''}
              suggestions={suggestionsCode}
              completeMethod={debounceSearchCode}
              onChange={({ value }) => {
                setRagioneSociale('')
                setClientCode(value)
              }}
              onSelect={({ value }) => {
                setRagioneSociale(value?.ragioneSociale)
                setClientCode(value?.codCliente12)
              }}
              inputStyle={{ fontFamily: 'Lexend', width: '100%' }}
              style={{ width: '100%' }}
              forceSelection
              field='codCliente12'
            />
            <label style={{ fontFamily: 'Lexend', color: 'inherit' }}>{label.it}</label>
          </span>
        </div>
      </Flex>
    </>
  )
}
