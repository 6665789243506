import React from 'react'
import { useCQuery } from '../../../Services/QueryCache'
import { useParams } from 'react-router-dom'
import { Flex, Spinner } from '../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatEuro3Decimals } from '../../../Services/Functions'

export const Pricing = () => {
  const { processId } = useParams()
  const { data: totalTokens = [], isSuccess } = useCQuery(['pricingPerProcess', processId])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <DataTable value={totalTokens} className='TableSignatures'>
        <Column field='provider' header='Provider' />
        <Column field='tokenCount' header='Token/Richieste' />
        <Column field='price' header='Costo' body={({ price }) => formatEuro3Decimals(price)} />
        <Column field='total' header='Totale' body={({ total }) => formatEuro3Decimals(total)} />
      </DataTable>
    </Flex>
  )
}
