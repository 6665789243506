export const examplesMessages = [
  [
    {
      message: 'Ciao Nora,\ndevo andare in trasferta a Roma, parto da Milano.\nParto il 10 ottobre e torno il 12. Mi serve un aereo ed un hotel in zona Colosseo.',
      user: 'Me',
      createdAt: new Date()
    },
    {
      message: 'Ciao Luca, mi servirebbero alcune informazioni per poter preparare un preventivo per il tuo viaggio. Potresti gentilmente fornirmi gli orari di partenza e ritorno?',
      user: 'Nora',
      createdAt: new Date()
    },
    {
      message: 'Vorrei la partenza al mattino e il ritorno in tarda serata.',
      user: 'Me',
      createdAt: new Date()
    },
    {
      message: 'Ciao Luca, le quotazioni dei servizi richiesti sono pronte. Puoi trovare tutte le informazioni necessarie nel seguente link: https://noraapi.siap.cloud/transfer/jfvpg8DRmgyPjqUmAE7M.',
      user: 'Nora',
      createdAt: new Date()
    }
  ]
]
