import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Spinner } from '../../Components'
import ServiceSections from './Components/ServiceSections'
import Header from './Components/Header'
import { FC, invalidateQuery, useCQuery } from '../../Services'
import { alert } from './Functions'

export const AdminCoefficient = () => {
  const { data: adminCoefficient = [], isSuccess } = useCQuery(['adminCoefficient'])
  const [planeCoefficient, setPlaneCoefficient] = useState([])
  const [trainCoefficient, setTrainCoefficient] = useState([])
  const [hotelCoefficient, setHotelCoefficient] = useState([])
  const [carCoefficient, setCarCoefficient] = useState([])

  useEffect(() => {
    const { planeCoefficient, trainCoefficient, hotelCoefficient, carCoefficient } = adminCoefficient
    setPlaneCoefficient(planeCoefficient)
    setTrainCoefficient(trainCoefficient)
    setHotelCoefficient(hotelCoefficient)
    setCarCoefficient(carCoefficient)
  }, [adminCoefficient])

  if (!isSuccess) return <Spinner />

  const save = async () => {
    await FC.service('adminCoefficient').patch(1, { planeCoefficient, trainCoefficient, hotelCoefficient, carCoefficient })
    alert('success', 'Salvataggio effettuato', '')
    invalidateQuery(['adminCoefficient'])
  }

  return (
    <Flex>
      <Card style={{ width: '80%' }}>
        <Header />
        <ServiceSections
          plane={{ coefficient: [planeCoefficient, setPlaneCoefficient] }}
          train={{ coefficient: [trainCoefficient, setTrainCoefficient] }}
          hotel={{ coefficient: [hotelCoefficient, setHotelCoefficient] }}
          car={{ coefficient: [carCoefficient, setCarCoefficient] }}
        />
        <Flex fw>
          <Button label='Salva' style={{ width: 120, marginTop: 20 }} onClick={save} />
        </Flex>
      </Card>
    </Flex>
  )
}
