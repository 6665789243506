import { useEffect, useState } from 'react'
import { readKey } from '../Functions'
import { useTransferField } from './Hook'
import _ from 'lodash'
import { FC } from '../../../../Services'
import { Flex } from '../../../../Components'
import { AutoComplete } from 'primereact/autocomplete'
import { getColorDiff } from './Functions'

export const StationInput = ({ state: [state, setState], item, count, readOnly, diffStatus }) => {
  const { location, label, id } = item
  let [data, setData] = useTransferField([state, setState], location, count)
  const skipTrainSearch = readKey(state, 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.skipTrainSearch', count)
  const [error, setError] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    setError(!data && !skipTrainSearch)
  }, [data])

  const debounceSearch = _.debounce(async ({ query }) => {
    if (query?.length < 3) return setSuggestions([])
    const res = await FC.service('info').get('getTrainStation', { query: { inputTrain: query } })
    setSuggestions(res?.slice(0, 10))
  }, 1000)
  if (typeof data === 'object' && !Object.keys(data || {}).length) data = ''
  return (
    <Flex key={item.id} as js style={{ width: '23%', margin: '0px 1%' }}>
      <div className='card flex justify-content-center' style={{ marginTop: 25, width: '100%' }}>
        <span className='p-float-label'>
          <AutoComplete
            disabled={readOnly} className={error ? 'p-invalid' : ''} id={id} onSelect={(e) => setData(e.value)} field='stationName'
            value={data} suggestions={suggestions} completeMethod={debounceSearch} onChange={(e) => setData(e.value)}
            inputStyle={{ fontFamily: 'Lexend', width: '100%', color: getColorDiff(diffStatus, error) }} style={{ width: '100%' }} forceSelection
          />
          <label style={{ fontFamily: 'Lexend', color: getColorDiff(diffStatus, error) }}>{label.it}</label>
        </span>
      </div>
    </Flex>
  )
}
