import React, { useEffect } from 'react'
import { Flex, Spinner } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from './Form'

export const GigiForm = () => {
  const { conversationId } = useParams()
  const { isSuccess, isError } = useCQuery(['conversation', conversationId])
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Conversazione non trovata' })
      navigate('/operatore/list')
    }
  }, [isError])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw as row wrap>
      <Form />
    </Flex>
  )
}
