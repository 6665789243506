import { useNavigate, useParams } from 'react-router-dom'
import { saveSociety, useSociety } from './Functions'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  societyName: { label: 'Nome' },
  codSociety: { label: 'Codice Società' },
  atlanteUrl: { label: 'Url Atlante' },
  userWS: { label: 'Utente WS' },
  passwordWS: { label: 'Password WS' },
  b4bDbUrl: { label: 'Url B4B' },
  b4bDbUser: { label: 'Utente B4B' },
  b4bDbPassword: { label: 'Password B4B' },
  b4bDbName: { label: 'Nome DB B4B' },
  dinamicoPhpUrl: { label: 'Url Dinamico' },
  dinamicoDbUrl: { label: 'Url DB Dinamico' },
  dinamicoDbUser: { label: 'Utente DB Dinamico' },
  dinamicoDbPassword: { label: 'Password DB Dinamico' },
  dinamicoDbName: { label: 'Nome DB Dinamico' },
  googleApiKey: { label: 'Google API Key' },
  tomcatCode: { label: 'Codice Tomcat' },
  whatsappPhone: { label: 'Telefono Whatsapp' }
}

export function SocietyEditor () {
  const { societyId } = useParams()
  const navigate = useNavigate()
  const [society, setSociety, isSuccess] = useSociety(societyId)
  const { id = '' } = society || {}

  const save = async () => {
    const res = await saveSociety(society)
    res?.id && navigate('/admin/society/list')
    return res
  }

  if (!isSuccess || !society) return <Spinner />

  const isNew = id === 'new'

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Società`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {Object.keys(fields).map((key) =>
          <Input key={key} value={society[key]} onChange={({ [key]: value }) => setSociety(key, value)} label={fields[key]?.label} id={key} style={{ ...defaultStyle }} />
        )}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/admin/society/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
