import React from 'react'
import { Button } from './Button'
import { Input } from './Input'
import { Flex } from './Flex'
import { getDateObj, getDateString } from '../../Services/Functions'

export const DateSelect = ({ date: [selectedDate, setSelectedDate], style = {}, minDate, maxDate }) => {
  const changeDate = (offset) => {
    const date = getDateObj(selectedDate)
    setSelectedDate(getDateString(new Date(date.setDate(date.getDate() + offset))))
  }

  return (
    <Flex row style={style}>
      <Button allowMultipleClick round icon='left' style={{ marginRight: 5 }} onClick={() => changeDate(-1)} />
      <Input
        {...{ minDate, maxDate }} value={getDateObj(selectedDate)}
        date label='Data' style={{ width: 300 }} id='date'
        onChange={({ date }) => setSelectedDate(getDateString(date))}
      />
      <Button allowMultipleClick round icon='right' style={{ marginLeft: 5 }} onClick={() => changeDate(1)} />
    </Flex>
  )
}

export const DateSelectRange = ({ startDate: [selectedStartDate, setSelectedStartDate], endDate: [selectedEndDate, setSelectedEndDate], style = {} }) => (
  <Flex row style={{ ...style }}>
    <DateSelect date={[selectedStartDate, setSelectedStartDate]} />
    <DateSelect date={[selectedEndDate, setSelectedEndDate]} style={{ marginLeft: 20 }} />
  </Flex>
)
