import React from 'react'
import { Flex, Text } from '../../../Components'
import { motion } from 'framer-motion'
import { useWindowSize } from 'react-use'
const WIDTH_DIMENSION = 1000
const cssGlass = {
  backdropFilter: 'blur(22px) saturate(146%)',
  WebkitBackdropFilter: 'blur(22px) saturate(146%)',
  backgroundColor: 'rgba(17, 25, 40, 0.78)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.125)'
  /*
  background: 'rgba(255, 255, 255, 0.15)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(14.1px)',
  WebkitBackdropFilter: 'blur(14.1px)'
  */
}
export default function ParagrafiBrochure () {
  const paragraphs = [
    { title: 'Raccolta di informazioni', text: 'Il sistema di intelligenza artificiale raccoglie tutte le informazioni rilevanti relative al viaggio, che includono:\n• l’identificazione del viaggiatore\n• l’azienda di appartenenza\n• i campi contabili obbligatori per la creazione di una trasferta\n• le preferenze di viaggio\n• le date e le destinazioni previste.' },
    { title: 'Creazione della trasferta', text: 'Utilizzando i dati raccolti, NORA genera in modo automatico la trasferta sul Middle Office di Siap.' },
    { title: 'Creazione di preventivi', text: 'Una volta analizzati i dettagli del viaggio, il sistema di intelligenza artificiale genera preventivi dettagliati, personalizzati per ciascuna tipologia di servizio richiesto. I preventivi generati automaticamente, seguono le linee guida della travel policy aziendale, i criteri specifici dell’azienda per la generazione delle soluzioni di viaggio e le scelte pregresse del viaggiatore.' },
    { title: 'Prenotazione dei servizi', text: 'Dopo aver ricevuto l\'approvazione sia dall\'azienda che dal viaggiatore, NORA avvia il processo di prenotazione delle soluzioni approvate, assicurando un\'organizzazione efficiente e piena conformità alle politiche aziendali.' },
    { title: '', text: 'In sintesi, l\'intelligenza artificiale integrata nella suite software SIAP per il Business Travel rappresenta un importante progresso nella gestione dei viaggi aziendali. Attraverso l\'automazione di queste fasi cruciali, NORA offre maggiore efficienza, precisione e conformità, migliorando così l\'esperienza sia dei viaggiatori che delle aziende, contribuendo al risparmio di tempo e risorse preziose.' }
  ]

  const { width = 0 } = useWindowSize()
  return (
    <Flex
      as
      fh
      js
      style={{
        width: width > WIDTH_DIMENSION ? '60%' : '100%',
        borderRadius: 30,
        margin: 10,
        marginRight: 0,
        ...cssGlass,
        overflowX: 'hidden'
      }}
    >
      <Text
        color='white'
        value="L'applicazione dell'intelligenza artificiale nel settore dei viaggi d'affari
presenta una serie di vantaggi considerevoli, contribuendo a semplificare
e arricchire l'esperienza sia per i viaggiatori che per le aziende coinvolte.
SIAP ha implementato l'intelligenza artificiale nella sua suite software
dedicata al business travel, NORA AI Travel Assistant, che opera attraverso
una sequenza di azioni chiave in risposta a una richiesta di viaggio:"
        style={{ fontSize: 18, padding: 20, textAlign: 'justify-left' }}
      />
      {paragraphs.map((paragraph, index) => (
        <motion.div
          key={index}
          viewport={{ once: true }}
          style={{
            width: '100%',
            padding: 20,
            marginTop: 10
          }}
          initial={{ x: '-99%' }}
          whileInView={{ x: 0 }}
          transition={{ duration: 1.5, delay: 0.8 }}
        >
          <Text value={paragraph?.title || ''} color='white' style={{ fontSize: 20, fontWeight: 'bold' }} />
          {paragraph.text?.split('\n').map((value, index2) => (<Text key={index2} value={value || ''} color='white' style={{ fontSize: 18, textAlign: 'justify' }} />))}

        </motion.div>
      ))}
    </Flex>
  )
}
