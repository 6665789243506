import { Button, Card, Flex } from '../../../../Components'
import { CustomHeader, TransferField } from '../Components'
import { getElementsByService } from '../Functions'
import { getDiffStatus } from './Functions'

const { traveller: { pid, name, lastname, email, clientCode, clientBusinessName, clientCity, clientStreet } } = getElementsByService()

export const TravellerSection = ({ state: [state, setState], readOnly, onClickClear, diff }) => {
  const stateProp = [state, setState]

  return (
    <Card customHeader={<CustomHeader title='DATI VIAGGIATORE' />} style={{ width: '100%' }}>
      <Flex fw wrap row>
        <TransferField state={stateProp} item={pid} readOnly diffStatus={getDiffStatus(pid, diff)} />
        <TransferField state={stateProp} item={name} readOnly diffStatus={getDiffStatus(name, diff)} />
        <TransferField state={stateProp} item={lastname} readOnly diffStatus={getDiffStatus(lastname, diff)} />
        <TransferField state={stateProp} item={email} readOnly diffStatus={getDiffStatus(email, diff)} />
        <TransferField state={stateProp} item={clientCode} readOnly diffStatus={getDiffStatus(clientCode, diff)} />
        <TransferField state={stateProp} item={clientBusinessName} readOnly diffStatus={getDiffStatus(clientBusinessName, diff)} />
        <TransferField state={stateProp} item={clientCity} readOnly diffStatus={getDiffStatus(clientCity, diff)} />
        <TransferField state={stateProp} item={clientStreet} readOnly diffStatus={getDiffStatus(clientStreet, diff)} />
      </Flex>
      <Flex fw wrap row style={{ marginTop: 15 }}>
        <Button icon='edit' label='Modifica viaggiatore' onClick={onClickClear} disabled={readOnly} style={{ display: readOnly ? 'none' : 'flex' }} />
      </Flex>
    </Card>
  )
}
