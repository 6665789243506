import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../../Components'
import { TransferField } from './TransferField'

export const CustomHeader = ({ title, isError }) => (
  <Flex style={{ width: 'calc(100% - 50px)', marginRight: 50 }} jb row>
    <Text title bold value={title} style={{ marginRight: 20, marginBottom: 10 }} size={20} />
  </Flex>
)

export const SingleServiceContainer = ({ children, onRemove, readOnly }) => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <Flex fw ae style={{ border: '1px solid black', borderRadius: 10, padding: 20, margin: '10px 0px' }}>
      <ConfirmModal header='Cancellazione Servizio' label='Vuoi cancellare questo servizio?' onConfirm={onRemove} visible={[modalVisible, setModalVisible]} />
      {!readOnly && <Button icon='close' round onClick={() => setModalVisible(true)} />}
      <Flex fw row wrap js as>
        {children}
      </Flex>
    </Flex>
  )
}

export const ConfirmModal = ({ visible, header, label, onConfirm }) => {
  const [modalVisible, setModalVisible] = visible
  return (
    <Modal visible={modalVisible} header={header} onHide={() => setModalVisible(false)}>
      <Flex fw>
        <Text value={label} size={18} />
        <Flex fw row jb style={{ marginTop: 20 }}>
          <Button label='Annulla' onClick={() => setModalVisible(false)} style={{ marginRight: 20 }} />
          <Button label='Conferma' onClick={() => { onConfirm(); setModalVisible(false) }} />
        </Flex>
      </Flex>
    </Modal>
  )
}

export { TransferField }
