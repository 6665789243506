import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Spinner, Text, Card, ChatMessages } from '../../../Components'
import { Column } from 'primereact/column'
import { columnProps } from '../../Prompts/Functions'
import { useCQuery } from '../../../Services'
import { handleContinueClick } from './Functions'
import { STATE_ENUM } from '../../Gigi/GigiList/Functions'
import { useAuth } from '../../../Provider/AuthProvider'

const BtnActionStep = ({ id, navigate }) => (
  <Flex width={40} jb row>
    <Button round icon='beauty' onClick={() => { navigate('/process/' + id) }} />
  </Flex>
)
const BtnActionStat = ({ id, navigate }) => (
  <Flex width={40} jb row>
    <Button round icon='statistics' onClick={() => navigate('/process/stats/' + id)} />
  </Flex>
)

export function ProcessList () {
  const { user: { user } } = useAuth()
  const { conversationId } = useParams()
  const { data: conversation = [], isSuccess } = useCQuery(['conversation', conversationId])
  const [processesTable, setProcessesTable] = useState([])
  const [messagesList, setMessagesList] = useState([])
  const [showContinueBtn, setShowContinueBtn] = useState(false)
  const navigate = useNavigate([])

  useEffect(() => {
    if (isSuccess) {
      setMessagesList(conversation?.messages?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)).map((message) => ({ message: message.message, user: message.sender === 'traveller' ? conversation?.traveller?.email : 'Nora', createdAt: new Date(message.createdAt) })))
      setShowContinueBtn(!!conversation?.processes?.find(({ state }) => state === 5))
      setProcessesTable(conversation?.processes?.map((r) => ({
        ...r,
        state: STATE_ENUM[r.state - 1]?.label,
        createdAt: new Date(r.createdAt).toLocaleString()
      })))
    }
  }, [isSuccess, conversation])

  if (!isSuccess) return <Spinner />

  return (
    <Flex fw js>
      <Text value='Lista Processi' center size={20} bold style={{ marginBottom: 20 }} />
      <Flex row>
        <Button icon='left' label='Torna indietro' onClick={() => navigate('/conversations/list')} style={{ }} />
        {user?.role === 'admin' &&
          <>
            <Button label='Vai a form' icon='settings' onClick={() => navigate('/operatore/' + conversationId)} style={{ marginLeft: 10, marginRight: 10 }} />
            {conversation?.status === 'ended' && <Button label='Pricing' icon='settings' onClick={() => navigate('/pricing/' + conversationId)} style={{ }} />}
          </>}
      </Flex>
      <Flex row fw js as>
        <Flex js as style={{ width: '50%', height: '100%' }}>
          <DataTable
            style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
            value={processesTable} emptyMessage='Nessun processo trovato' responsiveLayout='scroll' paginator rows={10}
          >
            <Column field='id' {...columnProps('Id Processo')} />
            <Column field='state' {...columnProps('Stato')} />
            <Column field='createdAt' {...columnProps('Data creazione')} />
            <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionStep navigate={navigate} {...props} />} />
            <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionStat navigate={navigate} {...props} />} />
          </DataTable>
        </Flex>
        <Flex js ae fh style={{ width: '50%', height: '100%', marginTop: 50 }}>
          <Card title='Conversazione' style={{ width: '98%' }}>
            <Flex fw style={{ justifyContent: 'space-between', height: '95%' }}>
              <ChatMessages messages={messagesList} jsonVisualized={false} />
            </Flex>
            <Flex>
              <Button icon='play' hidden={!showContinueBtn} label='Prosegui esecuzione' style={{ marginTop: 10, width: 250 }} onClick={() => handleContinueClick(conversation)} />
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  )
}
