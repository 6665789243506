import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMount, useUnmount } from 'react-use'
import { motion } from 'framer-motion'
import { Icon } from './Icon'
import { Flex } from './Flex'
import { Text } from './Text'
import { cap } from '../../State'

const modalMenu = document.getElementById('modal-menu')
const modalDiv = document.createElement('div')

const MENU = { miniWidth: 90, menuWidth: 300, toggle: null }

const menuColors = {
  dark: { hover: { backgroundColor: 'rgba(255, 255, 255, 0.95)', color: '#3a3b3d' }, normal: { backgroundColor: '#3a3b3d', color: 'rgba(255, 255, 255, 0.95)' } },
  light: { hover: { backgroundColor: '#3a3b3d', color: 'rgba(255, 255, 255, 0.95)' }, normal: { backgroundColor: 'rgba(255, 255, 255, 0.95)', color: '#3a3b3d' } }
}

export const HamburgerMenu = () => (
  <motion.div
    whileHover='hovered' whileTap='click' onTap={() => MENU.toggle && MENU.toggle(2)}
    onHoverStart={() => MENU.toggle && MENU.toggle(1)}
    onHoverEnd={() => MENU.toggle && MENU.toggle(0)}
    variants={{ click: { rotate: '270deg' }, hovered: { rotate: '90deg' } }}
  >
    <Icon name='menu' size={30} />
  </motion.div>
)

const MenuItem = ({ item: { label, icon, path, disabled, img }, action }) => {
  const [isDark] = cap.darkMode.useState()
  if (disabled) return null
  return (
    <motion.div
      onTap={action} style={Styles.menuElement(isDark)}
      whileHover='hovered' transition={{ default: { duration: 0.5 } }}
      variants={{ hovered: menuColors[isDark ? 'dark' : 'light'].hover }}
    >
      <Flex style={{ minWidth: 70, marginLeft: 10, marginRight: 20 }}>
        {icon && <Icon color='inherit' name={icon} size={35} />}
        {img && <img src={img} style={{ width: 55, height: 55 }} />}
      </Flex>
      <Text color='inherit' size={20} bold value={label} />
    </motion.div>
  )
}

export const SlidingMenu = ({ menuItems, menuAction }) => {
  const [visibility, setVisibility] = useState(0)
  const [isDark] = cap.darkMode.useState()
  const [renderNull, setRenderNull] = useState(false)

  useEffect(() => {
    // hard refresh del menu se cambia il tema, perchè se no ci sono problemi di stile
    setRenderNull(true)
    setTimeout(() => setRenderNull(false), 100)
  }, [isDark])

  useMount(() => { modalMenu.appendChild(modalDiv); MENU.toggle = setVisibility })
  useUnmount(() => { modalMenu.removeChild(modalDiv); MENU.toggle = null })

  const renderMenu = renderNull
    ? null
    : (
      <motion.div
        onHoverStart={() => setVisibility(2)} onHoverEnd={() => setVisibility(0)}
        animate={{
          width: [MENU.miniWidth, MENU.miniWidth, MENU.menuWidth][visibility],
          x: [-MENU.miniWidth, 0, 0][visibility],
          boxShadow: ['2px 0 4px 0px rgba(100, 100, 100, 0)', '2px 0 4px 0px rgba(100, 100, 100, 0.7)', '2px 0 4px 0px rgba(100, 100, 100, 0.7)'][visibility]
        }}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        style={Styles.menuContainer}
        className={`menu-list ${isDark ? 'theme-dark' : 'theme-light'}`}
      >
        {menuItems.map(({ path, ...item }, ind) => <MenuItem key={ind} item={item} action={() => { menuAction(path); setVisibility(0) }} />)}
      </motion.div>
      )

  return ReactDOM.createPortal(renderMenu, modalMenu)
}

const Styles = {
  menuContainer: {
    position: 'absolute',
    top: 50,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    width: MENU.miniWidth,
    x: -MENU.miniWidth,
    zIndex: 20
  },
  menuElement: (isDark) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 60,
    ...menuColors[isDark ? 'dark' : 'light'].normal
  })
}
