export const getProcessState = (conv) => {
  const value = conv?.processes
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    ?.[0]?.state
  if (value === 3 && conv.status === 'ended') return ({ label: 'Terminato', value })
  return ({ label: STATE_ENUM?.find((e) => e.value === value)?.label, value })
}

export const STATE_ENUM = [
  { label: 'Creato', value: 1, code: 'INITIAL', color: 'blue' },
  { label: 'In esecuzione', value: 2, code: 'RUNNING', color: 'violet' },
  { label: 'Terminato', value: 3, code: 'ENDED', color: 'green' },
  { label: 'In pausa', value: 4, code: 'PAUSE', color: 'azure' },
  { label: 'Errore', value: 5, code: 'ERROR', color: 'red' },
  { label: 'In esecuzione', value: 7, code: 'READYTORESTART', color: 'orange' }
]

export const getStateValues = () => {
  const obj = {}
  STATE_ENUM.forEach((e) => { obj[e.code] = e.value })
  return obj
}
