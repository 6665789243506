import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'

export const useTraveller = (travellerId) => {
  const [traveller, setTraveller] = useState()
  const { data, isSuccess } = useCQuery(['traveller', travellerId])

  useEffect(() => {
    isSuccess && setTraveller(data)
  }, [isSuccess, data])

  const setTravellerProp = (prop, value) =>
    setTraveller({ ...traveller, [prop]: value })

  return [traveller, setTravellerProp, isSuccess]
}

export const saveTraveller = async (traveller) => {
  /* if (!traveller?.name) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  } */
  if (traveller.id === 'new') {
    delete traveller.id
    const res = await FC.service('travellers').create(traveller)
    invalidateQuery(['traveller', 'travellers'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('travellers').patch(traveller.id, traveller)
  invalidateQuery(['traveller', 'travellers'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

export const deleteTraveller = async (travellerId) => {
  const res = await FC.service('travellers').remove(travellerId)
  invalidateQuery(['traveller', 'travellers'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
