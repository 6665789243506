import { objectMap } from './Functions'
import { StoredCapsule, Capsule } from './CapsuleClasses'

export const getCurrentDate = () => {
  // Ottieni la data corrente
  const currentDate = new Date()

  // Imposta l'orario a mezzanotte
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  return currentDate
}

const sessionState = {
  selectedDateGigiList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedDateConversationList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedStartDateDashboard: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedEndDateDashboard: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

const storedState = {
  darkMode: window?.matchMedia('(prefers-color-scheme: dark)')?.matches || false
}

export const state = {
  ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
  ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
}
