import { useEffect, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCQuery } from '../../../Services'
import { getElementsByService, readKey } from '../../Gigi/GigiForm/Functions'

export const useDefaultChatData = () => {
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id: userId } = userObj
  const [codSociety, setCodSociety] = useState()
  const [pid, setPid] = useState()

  const { data: travellersData = [], isSuccess: isSuccessTravellers } = useCQuery(['travellers'])
  const { data: environments = [], isSuccess: isSuccessEnvironments } = useCQuery(['environments'])

  useEffect(() => {
    isSuccessEnvironments && !codSociety && setCodSociety(environments.find(({ tomcatCode }) => user?.user?.environment?.tomcatCode === tomcatCode)?.codSociety || '')
  }, [environments])

  useEffect(() => {
    if (isSuccessTravellers) {
      const environmentId = environments?.find((env) => env.codSociety === codSociety)?.id
      setPid(travellersData?.filter(({ environmentId: envId }) => envId === environmentId)?.[0]?.pid)
    }
  }, [travellersData, codSociety])

  return {
    userId,
    pid,
    tomcatCode: user?.user?.environment?.tomcatCode,
    codSociety,
    isSuccess: isSuccessTravellers && isSuccessEnvironments,
    userEmail: 'mranocchia@siapcn.it',
    operatorEmail: 'mranocchia@siapcn.it',
    levelFormalita: 75,
    isDebug: false,
    type: 'testUnit',
    unit: 'newMessage'
  }
}

export const getDiffObj = (correctState, newState) => {
  const servicesElements = getElementsByService()

  const getDiffPerService = (correctState, newState, service, i = false) => {
    const arr = []
    const countObj = i !== false ? { count: i } : {}
    Object.keys(servicesElements?.[service]).forEach((key) => {
      const field = servicesElements?.[service]?.[key]
      const { id, location, type } = field
      if (type === 'airport') {
        const correctValue = readKey(correctState, location.value, i) || ''
        const newValue = readKey(newState, location.value, i) || ''
        if (!correctValue && newValue) {
          arr.push({ id, diffStatus: 'new', ...countObj })
        } else if (correctValue !== newValue) {
          arr.push({ id, diffStatus: 'changed', ...countObj })
        }
        return null
      }
      if (type === 'station') {
        const { stationName: correctValue = '' } = readKey(correctState, location, i) || {}
        const { stationName: newValue = '' } = readKey(newState, location, i) || {}
        if (!correctValue && newValue) {
          arr.push({ id, diffStatus: 'new', ...countObj })
        } else if (correctValue !== newValue) {
          arr.push({ id, diffStatus: 'changed', ...countObj })
        }
        return null
      }
      const correctValue = readKey(correctState, location, i) || ''
      const newValue = readKey(newState, location, i) || ''
      if (!correctValue && newValue) {
        arr.push({ id, diffStatus: 'new', ...countObj })
      } else if (correctValue !== newValue) {
        arr.push({ id, diffStatus: 'changed', ...countObj })
      }
    })
    return arr
  }

  const newDiff = { transfer: [], traveller: [], train: [], hotel: [], plane: [] }
  newDiff.traveller = getDiffPerService(correctState, newState, 'traveller')
  newDiff.transfer = getDiffPerService(correctState, newState, 'transfer')
  newState?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.train?.forEach((service, i) =>
    newDiff.train.push(...getDiffPerService(correctState, newState, 'train', i)))
  newState?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.hotel?.forEach((service, i) =>
    newDiff.hotel.push(...getDiffPerService(correctState, newState, 'hotel', i)))
  newState?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.plane?.forEach((service, i) =>
    newDiff.plane.push(...getDiffPerService(correctState, newState, 'plane', i)))

  return newDiff
}
