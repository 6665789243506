import React from 'react'
import { Flex, TabHeader, Tabs } from '../../Components'
import { useNavigate } from 'react-router-dom'
import { PromptsTable } from './PromptsTable'
import { getUrlParams } from '../../Services/Functions'

const getTabs = () => [
  { id: 1, icon: 'edit-2', title: 'Elenco Prompts', content: <PromptsTable /> }
]

export default function TabPrompts () {
  const tab = getUrlParams('tab')
  const navigate = useNavigate()
  const currentTab = parseInt(tab) || 0

  return (
    <Flex fw>
      <Tabs
        style={{ width: '100%', height: '100%', padding: 20 }}
        onTabSelection={(e) => navigate('/cron/list?tab=' + e)}
        currentTab={currentTab}
      >
        {getTabs()?.map((tab, index) => (
          <TabHeader title={tab?.title} key={index} index={index} icon={tab?.icon}>
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </Flex>
  )
}
