import { FC, invalidateQuery } from '../../../Services'

export const handleContinueClick = async (conversation) => {
  const { id: processId } = conversation.processes?.find(process => process.state === 5)
  try {
    const processSteps = await FC.service('processStep').find({ query: { processId } })
    const firstErrorStep = processSteps.find(item => item.stepState === 5)
    if (firstErrorStep) {
      const firstErrorStepIndex = processSteps.findIndex(item => item.stepState === 5)
      await FC.service('processStep').patch(firstErrorStep.id, { stepState: 2 })
      for (let i = firstErrorStepIndex + 1; i < processSteps.length; i++) await FC.service('processStep').remove(processSteps[i].id)
    }
    await FC.service('process').patch(processId, { state: 7 })
    await FC.service('conversations').patch(conversation.id, { status: 'open' })
    invalidateQuery('conversation')
    window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Operazione effettuata con successo' })
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'esecuzione dell\'operazione' })
    console.error(`Errore durante il recupero dei processSteps per il processo ${processId}:`, error)
  }
}
