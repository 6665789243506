import React, { useEffect, useState } from 'react'
import { useCQuery } from '../../../Services'
import { Spinner, Text, Flex, Icon, Button } from '../..'
import { formatTime } from './Functions'

const STATE_MAP = ['', 'not-loaded', 'started', 'success', 'SCHEDULED', 'error']
const statusColors = { success: 'rgb(0, 128, 0)', started: 'rgb(0, 102, 204)', error: 'rgb(255, 0, 0)', 'not-loaded': 'rgb(0, 0, 0)' }
const iconStatus = { success: 'check', started: 'play', error: 'circle', 'not-loaded': 'clock' }

const getUnits = (unit) => {
  if (unit?.type === 'process') return unit?.units?.map(u => getUnits(u))
  return [{ name: unit?.name }]
}

export const UnitsVisualizer = ({ selectedUnit = 'newMessage' }) => {
  const { data: units = [], isSuccess } = useCQuery(['units'])
  const [functions, setFunctions] = useState([])

  useEffect(() => {
    if (isSuccess) {
      const foundUnit = units.find(({ name }) => name === selectedUnit)
      setFunctions(getUnits(foundUnit).flat(Infinity).map(u => ({ name: u.name, status: STATE_MAP[1], executionTime: 0 })))
    }
  }, [units, selectedUnit])

  if (!isSuccess) return <Spinner />

  return (
    <Flex as js style={{ overflowY: 'auto', width: 'calc(100% - 20px)', marginLeft: 20, padding: 20, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 20, backdropFilter: 'blur(10px)', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}>
      {functions.map((data, ind) => <SingleSocketData data={data} key={ind} />)}
    </Flex>
  )
}

const SingleSocketData = ({ data: { name, status = '', payload = '', executionTime } }) => {
  const color = statusColors[status] || 'rgb(0, 0, 0)'
  const icon = iconStatus[status] || 'clock'

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(payload))
    window.growl.show({ severity: 'success', summary: 'Copiato negli appunti', detail: 'Il payload copiato negli appunti' })
  }

  return (
    <Flex as style={{ maxWidth: '100%' }}>
      <Flex js row style={{ maxWidth: '100%' }}>
        <Icon name={icon} size={18} style={{ marginRight: 10, marginTop: 5 }} color={color} />
        <Text size={22} value={name} color={color} />
        {(status === 'success' || status === 'error') &&
          <>
            <Text style={{ marginLeft: 10 }} size={22} value={formatTime(executionTime)} color={color} />
            <Button round icon='copy' onClick={copyToClipboard} style={{ marginLeft: 10 }} />
          </>}
      </Flex>
    </Flex>
  )
}
