import React, { useState } from 'react'
import { Button, Flex, Spinner, Text, Input, Card, BarChartComponent } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useNavigate } from 'react-router-dom'

const getCurrentDate = () => {
  // Ottieni la data corrente
  const currentDate = new Date()

  // Imposta l'orario a mezzanotte
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  return currentDate
}

function countUnits (jsonArray) {
  const unitCountMap = {}

  jsonArray.forEach(item => {
    const unit = item.unit

    if (!unitCountMap[unit]) {
      unitCountMap[unit] = 1
    } else {
      unitCountMap[unit]++
    }
  })

  const result = Object.keys(unitCountMap).map(unit => ({
    unit,
    count: unitCountMap[unit]
  }))

  return result
}

export function ProcessesErrorStats () {
  const [selectedStartDay, setSelectedStartDay] = useState(getCurrentDate())
  const [selectedFinalDay, setSelectedFinalDay] = useState(getCurrentDate())
  const { data: errorProcessesSteps = [], isSuccess } = useCQuery(['errorProcessStepsRange', selectedStartDay, selectedFinalDay])
  const navigate = useNavigate([])

  if (!isSuccess) return (<Spinner />)

  // Calcola il conteggio delle unità errore
  const errors = errorProcessesSteps.filter(item => item.stepState === 5)
  const countedErrors = countUnits(errors)
  countedErrors.sort((a, b) => b.count - a.count)
  const dataKeys = [{ dataKey: 'count', color: 'red' }]

  return (
    <Flex fw fh js>
      <Button label='Torna indietro' icon='left' onClick={() => navigate('/process')} />
      <Flex js as fw>
        <Flex row fw style={{ width: '100%', marginTop: 30 }}>
          <Flex row>
            <Button round icon='left' style={{ marginRight: 5 }} onClick={() => setSelectedStartDay(new Date(selectedStartDay.setDate(selectedStartDay.getDate() - 1)))} />
            <Input
              date label='Data inizio' value={selectedStartDay} id='selectedStartDay' style={{ width: 300 }} onChange={({ selectedStartDay }) => {
                setSelectedStartDay(selectedStartDay)
              }}
            />
          </Flex>
          <Flex row style={{ marginLeft: 20 }}>
            <Button round icon='left' style={{ marginRight: 5 }} onClick={() => setSelectedFinalDay(new Date(selectedFinalDay.setDate(selectedFinalDay.getDate() - 1)))} />
            <Input
              date label='Data fine' value={selectedFinalDay} id='selectedFinalDay' style={{ width: 300 }} onChange={({ selectedFinalDay }) => {
                setSelectedFinalDay(selectedFinalDay)
              }}
            />
            <Button round icon='right' style={{ marginLeft: 5 }} onClick={() => setSelectedFinalDay(new Date(selectedFinalDay.setDate(selectedFinalDay.getDate() + 1)))} />
          </Flex>
        </Flex>
        <Flex js as style={{ marginTop: 30, width: '100%' }}>
          <Card title='Processi in errore' collapsable style={{ width: '100%' }}>
            <Flex js as row style={{ width: '100%' }}>
              <Flex js as style={{ marginTop: 5, width: '40%' }}>
                {countedErrors.length
                  ? countedErrors.map((ps) => (
                    <Text key={ps.unit} value={`${ps.unit}: ${ps.count}`} style={{ marginTop: 10 }} />
                  ))
                  : (<Text value='Non ci sono errori' />)}
              </Flex>
              <Flex style={{ height: 300, marginTop: 30, width: '60%' }}>
                {
                    countedErrors.length ? (<BarChartComponent data={countedErrors} dataKeys={dataKeys} xaxis='unit' yaxis />) : (<></>)
                  }
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Flex>
    </Flex>
  )
}
