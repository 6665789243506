import React, { useState } from 'react'
import { Outlet, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ProtectedRoute, Login, ErrorHandler } from './Pages'
import AuthProvider, { useAuth } from './Provider/AuthProvider'
import { FC } from './Services'
import { Flex, Spinner, Background } from './Components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import TabPrompts from './Pages/Prompts'
import NewPrompt from './Pages/Prompts/NewPrompt'
import { TestChat } from './Pages/Chat/TestChat'
import { NewChat } from './Pages/Chat/NewChat'
import { SocietyList } from './Pages/Admin/Society/List'
import { SocietyEditor } from './Pages/Admin/Society/Editor'
import { UsersList } from './Pages/Admin/Users/List'
import { UserEditor } from './Pages/Admin/Users/Editor'
import { Brochure } from './Pages/Brochure'
import { TravellersList } from './Pages/Admin/Travellers/List'
import { TravellerEditor } from './Pages/Admin/Travellers/Editor'
import { TravellersImport } from './Pages/Admin/Travellers/Import'
import { ProcessesDashboard } from './Pages/Process/Dashboard'
import { ProcessList } from './Pages/Process/List'
import { ProcessStepsVisualizer } from './Pages/Process/Editor'
import { ProcessesErrorStats } from './Pages/Process/ErrorStats'
import { ProcessStepsStats } from './Pages/Process/Stats'
import { CommunityPanel } from './Pages/CommunityPanel'
import { TestDyn } from './Pages/TestDyn'
import { ConversationsList } from './Pages/Process/Conversations'
import { GigiForm } from './Pages/Gigi/GigiForm'
import { GigiList } from './Pages/Gigi/GigiList'
import { ChatFiera } from './Pages/Fiera/ChatFiera'
import { CommunityPresetsSearch } from './Pages/CommunityPanel/Search'
import { AdminCoefficient } from './Pages/AdminCoefficient'
import { ConversationPricing } from './Pages/Process/Pricing'
import { cap } from './State'
import { ProcessCompare } from './Pages/Process/Compare'
import { AutoTestsList } from './Pages/Test/AutoTestList'
import { AutoTest } from './Pages/Test/AutoTest'

export const queryClient = new QueryClient()

const LightTheme = React.lazy(() => import('./Themes/Light/Theme'))
const DarkTheme = React.lazy(() => import('./Themes/Dark/Theme'))

const ThemeSelector = ({ children }) => {
  const [darkMode] = cap.darkMode.useState()
  return (
    <>
      <React.Suspense fallback={<></>}>
        {darkMode ? <DarkTheme /> : <LightTheme />}
      </React.Suspense>
      <div className={`theme-${darkMode ? 'dark' : 'light'}`}>
        {children}
      </div>
    </>
  )
}

function AppSetup () {
  const { user: storedUser, onLoginJWT } = useAuth()
  const [loading, setLoading] = useState(true)

  useMount(async () => {
    if (FC.authenticated && !storedUser?.accessToken) {
      setLoading(false)
      return true
    }
    storedUser?.accessToken && await onLoginJWT(storedUser?.accessToken)
    setLoading(false)
  })

  return (
    <ThemeSelector>
      <Background whiteBackground>
        <ReactQueryDevtools initialIsOpen={false} />
        {loading ? <Flex fw fh><Spinner /></Flex> : <Outlet />}
      </Background>
    </ThemeSelector>
  )
}

const CheckRoute = ({ children, role = [] }) => {
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  if (role?.length && !role.includes(user?.role)) {
    if (user.role === 'dynamicTester') return <Navigate to='/testDyn' />
    return <Navigate to='/operatore/list/' />
  }
  return children
}

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorHandler />,
    children: [
      {
        element: <AppSetup />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              { path: '*', element: <CheckRoute> <Navigate to='/operatore/list/' /> </CheckRoute> },
              { path: '/chat/*', element: <CheckRoute role={['admin', 'tester']}> <Navigate to='/chat/new' /> </CheckRoute> },
              { path: '/chat/new', element: <CheckRoute role={['admin', 'tester']}> <NewChat /> </CheckRoute> },
              { path: '/chat/fiera', element: <CheckRoute role={['admin']}><ChatFiera /></CheckRoute> },
              { path: '/conversations/*', element: <CheckRoute role={['admin']}> <Navigate to='/conversations/list' /> </CheckRoute> },
              { path: '/conversations/list', element: <CheckRoute role={['admin']}><ConversationsList /></CheckRoute> },
              { path: '/conversations/:conversationId', element: <CheckRoute role={['admin']}> <ProcessList /> </CheckRoute> },
              { path: '/pricing/:conversationId', element: <CheckRoute role={['admin']}> <ConversationPricing /> </CheckRoute> },
              { path: '/community/search', element: <CheckRoute role={['admin', 'dynamicTester', 'tester', 'operatorCommunity']}>  <CommunityPresetsSearch /> </CheckRoute> },
              { path: '/community/panel/:presetId', element: <CheckRoute role={['admin', 'dynamicTester', 'tester', 'operatorCommunity']}> <CommunityPanel /></CheckRoute> },
              { path: '/community/panel/:operation/:presetId', element: <CheckRoute role={['admin', 'dynamicTester', 'tester', 'operatorCommunity']}> <CommunityPanel /></CheckRoute> },
              { path: '/operatore/*', element: <CheckRoute role={['admin', 'operator', 'tester', 'operatorCommunity']}> <Navigate to='/operatore/list' /> </CheckRoute> },
              { path: '/operatore/list/', element: <CheckRoute role={['admin', 'operator', 'tester', 'operatorCommunity']}><GigiList /> </CheckRoute> },
              { path: '/operatore/:conversationId', element: <CheckRoute role={['admin', 'operator', 'tester', 'operatorCommunity']}><GigiForm /> </CheckRoute> },
              { path: '/testChat/*', element: <CheckRoute role={['admin']}> <Navigate to='/testChat/new' /> </CheckRoute> },
              { path: '/testChat/new', element: <CheckRoute role={['admin']}> <TestChat /> </CheckRoute> },
              { path: '/admin/*', element: <CheckRoute role={['admin']}> <Navigate to='/admin/society/list' /> </CheckRoute> },
              { path: '/admin/society/list', element: <CheckRoute role={['admin']}> <SocietyList /> </CheckRoute> },
              { path: '/admin/society/:societyId', element: <CheckRoute role={['admin']}> <SocietyEditor /> </CheckRoute> },
              { path: '/admin/users/list', element: <CheckRoute role={['admin']}> <UsersList /> </CheckRoute> },
              { path: '/admin/users/:userId', element: <CheckRoute role={['admin']}> <UserEditor /> </CheckRoute> },
              { path: '/admin/travellers/list', element: <CheckRoute role={['admin']}> <TravellersList /> </CheckRoute> },
              { path: '/admin/travellers/import', element: <CheckRoute role={['admin']}> <TravellersImport /> </CheckRoute> },
              { path: '/admin/travellers/:travellerId', element: <CheckRoute role={['admin']}> <TravellerEditor /> </CheckRoute> },
              { path: '/admin/coefficient', element: <CheckRoute role={['admin', 'dynamicTester', 'operatorCommunity']}> <AdminCoefficient /> </CheckRoute> },
              { path: '/testDyn', element: <CheckRoute role={['admin', 'dynamicTester', 'tester', 'operatorCommunity']}> <TestDyn /> </CheckRoute> },
              { path: '/process/*', element: <CheckRoute role={['admin']}> <Navigate to='/process/dashboard' /> </CheckRoute> },
              { path: '/process/dashboard', element: <CheckRoute role={['admin']}> <ProcessesDashboard /> </CheckRoute> },
              { path: '/prompts/list', element: <CheckRoute role={['admin']}> <TabPrompts /> </CheckRoute> },
              { path: '/prompts/:promptId', element: <CheckRoute role={['admin']}> <NewPrompt /> </CheckRoute> },
              { path: '/process/errorStats', element: <CheckRoute role={['admin']}> <ProcessesErrorStats /> </CheckRoute> },
              { path: '/process/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsVisualizer /> </CheckRoute> },
              { path: '/process/stats/:processId', element: <CheckRoute role={['admin']}> <ProcessStepsStats /></CheckRoute> },
              { path: '/process/compare/:processId', element: <CheckRoute role={['admin']}> <ProcessCompare /></CheckRoute> },
              { path: '/autotest', element: <CheckRoute role={['admin']}> <Navigate to='/autotest/list' /> </CheckRoute> },
              { path: '/autotest/list', element: <CheckRoute role={['admin']}> <AutoTestsList /> </CheckRoute> },
              { path: '/autotest/:autoTestId', element: <CheckRoute role={['admin']}> <AutoTest /> </CheckRoute> }
            ]
          },
          window?.location?.origin === 'https://nora.siap.cloud' && { path: '/', element: <Brochure /> },
          { path: '/login', element: <Login /> }
        ]
      }
    ]
  }
])

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
export default App
