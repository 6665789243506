import React from 'react'
import { Button, Card, Flex, Icon, PanelText } from '../../Components'

const enabledStyle = { width: 25, height: 25, background: '#6058ca', color: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }
const disabledStyle = { width: 25, height: 25, background: 'rgba(127, 86, 217, 0.08)', border: '1px solid rgba(127, 86, 217, 0.10)', color: 'white', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }

export const CheckBoxGroupItem = ({ style, value, selected, i, onClick, steps }) => {
  const doNothing = () => null

  const onClickFunction = (e) => {
    // Evita il doppio click
    if (e?.detail !== 1) doNothing()
    else onClick(e)
  }

  return (
    <Card
      style={{
        display: 'inline-flex',
        height: 270,
        padding: '19px 18px 16px 18px',
        alignItems: 'flex-start',
        borderRadius: 8,
        border: selected ? '1px solid #D6BBFB' : '1px solid #EBEBEB',
        fontFamily: 'Lexend',
        ...style
      }}
    >
      <Flex fw as style={{ padding: '0px 1px', gap: 23 }}>
        {steps.map((step, index) => (
          <Flex row key={step}>
            <div style={(index <= i) ? enabledStyle : disabledStyle}>
              <Icon name='check' size={15} color='white' />
            </div>
            <PanelText disabled={(index > i)} value={step} style={{ marginLeft: 10 }} />
          </Flex>))}
        <Flex fw je>
          <Button
            label={selected ? 'Selezionato' : 'Seleziona'} disabled={selected} onClick={onClickFunction}
            style={{ width: '100%', ...selected ? { background: '#6058ca', color: 'white' } : { } }}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
