import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

export const LineChartComponent = ({
  data,
  xaxis, // se presente e valorizzato mostra l'asse delle x con la relativa etichetta
  yaxis, // se presente mostra l'asse delle y
  cartesian, // se presente mostra il piano cartesiano
  legend, // se presente mostra la legenda
  dataKeys,
  yAxisProps,
  xAxisProps,
  tooltipProps
}) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={data}>
        {cartesian ? <CartesianGrid strokeDasharray='3 3' /> : null}
        {xaxis ? <XAxis dataKey='name' {...xAxisProps} /> : null}
        {yaxis ? <YAxis {...yAxisProps} /> : null}
        <Tooltip {...tooltipProps} />
        {legend ? <Legend /> : null}
        {dataKeys.map((dk) => (<Line type='monotone' dataKey={dk.dataKey} key={dk.dataKey} stroke={dk.color} strokeWidth={2} dot={false} />))}
      </LineChart>
    </ResponsiveContainer>
  )
}
