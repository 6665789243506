import { Button, Card, Flex, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services/QueryCache'
import { CustomHeader, SingleServiceContainer, TransferField } from '../Components'
import { addService, getElementsByService, getServiceCount, readKey, removeService } from '../Functions'
import { getDiffStatus } from './Functions'

const { train: { typeTrip, companyName, classType, seatPreference, departureDate, trainCityDeparture, fromHourDeparture, toHourDeparture, trainStationDeparture, arrivalDate, trainCityArrival, fromHourReturn, toHourReturn, trainStationArrival, withinHoursDeparture /*, withinHoursArrival */ } } = getElementsByService()

export const TrainSection = ({ state: [state, setState], readOnly, diff }) => {
  const { data: flagAbilitazioneArrivoEntro = false } = useCQuery(['flagAbilitazione', 'FLAG_ABILITAZIONE_ARRIVOENTRO'])
  const stateProp = [state, setState]
  const statePropRender = [state, (data) => setState(data, true)]

  const getProps = (stateRender, item, key, count, readOnly, diff) => ({
    state: stateRender ? statePropRender : stateProp,
    item,
    key,
    count,
    readOnly,
    diffStatus: getDiffStatus(item, diff, count)
  })

  return (
    <Card key='train' collapsable customHeader={<CustomHeader title='SERVIZI TRENO' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw>
        <Flex fw row jb>
          {!getServiceCount(state, 'train') ? <Text value='Nessun servizio inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <div />}
          {!readOnly && <Button icon='plus' label='Aggiungi servizio' onClick={async () => setState(await addService(state, 'train'))} />}
        </Flex>
        {Array(getServiceCount(state, 'train')).fill().map((_, i) => {
          const departureDateValue = readKey(state, departureDate?.location, i)
          const minDateArrival = departureDateValue
            ? new Date(new Date(departureDateValue.split('/')[2], departureDateValue.split('/')[1] - 1, departureDateValue.split('/')[0]))
            : new Date()

          const isWithinHoursDeparture = readKey(state, withinHoursDeparture?.location, i)
          const isRoundTrip = readKey(state, typeTrip?.location, i) === 'RT'
          return (
            <SingleServiceContainer key={i} onRemove={() => setState(removeService(state, 'train', i))} readOnly={readOnly}>
              <TransferField {...getProps(true, typeTrip, 'typeTrip', i, readOnly, diff)} />
              <TransferField {...getProps(false, companyName, 'companyName', i, readOnly, diff)} />
              <TransferField {...getProps(false, classType, 'classType', i, readOnly, diff)} />
              <TransferField {...getProps(false, seatPreference, 'seatPreference', i, readOnly, diff)} />
              <TransferField {...getProps(true, departureDate, 'departureDate', i, readOnly, diff)} />
              <TransferField {...getProps(false, trainCityDeparture, 'trainCityDeparture', i, readOnly, diff)} />
              <TransferField {...getProps(false, { ...fromHourDeparture, notRequired: isWithinHoursDeparture }, 'fromHourDeparture', i, readOnly, diff)} />
              {flagAbilitazioneArrivoEntro && <TransferField {...getProps(false, toHourDeparture, 'toHourDeparture', i, readOnly, diff)} />}
              <TransferField {...getProps(false, trainStationDeparture, 'trainStationDeparture', i, readOnly, diff)} />
              <TransferField {...getProps(false, { ...arrivalDate, notRequired: !isRoundTrip, minDate: minDateArrival }, 'arrivalDate', i, readOnly || !isRoundTrip, diff)} />
              <TransferField {...getProps(false, trainCityArrival, 'trainCityArrival', i, readOnly, diff)} />
              <TransferField {...getProps(false, { ...fromHourReturn, notRequired: !isRoundTrip }, 'fromHourReturn', i, readOnly || !isRoundTrip, diff)} />
              {flagAbilitazioneArrivoEntro && <TransferField {...getProps(false, toHourReturn, 'toHourReturn', i, readOnly, diff)} />}
              <TransferField {...getProps(false, trainStationArrival, 'trainStationArrival', i, readOnly, diff)} />
            </SingleServiceContainer>
          )
        })}
      </Flex>
    </Card>
  )
}
