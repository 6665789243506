import { FC } from '../../../Services'
import airports from '../Resources/airports.json'

export const fetchFlagAbilitazioneModuli = async (flagName) => {
  const FLAG_ABILITAZIONE_ARRIVOENTRO = await FC.service('info').get('getFlagAbilitazione', { query: { flagName } })
  return FLAG_ABILITAZIONE_ARRIVOENTRO
}

export const readKey = (json, location, count) => { // funzione per leggere una chiave di un json
  const chiavi = location.split('.') // splitto la location per ottenere le chiavi
  let valore = json // inizializzo il valore con il json
  for (const chiave of chiavi) valore = valore?.[chiave === '#COUNT' ? count : chiave] // per ogni chiave, se è #COUNT prendo il count, altrimenti prendo la chiave
  return valore // ritorno il valore
}

export const writeKey = (json, location, count, valore) => { // funzione per scrivere una chiave di un json
  const chiavi = location.split('.') // splitto la location per ottenere le chiavi
  let puntatore = json // inizializzo il puntatore con il json
  for (let i = 0; i < chiavi.length - 1; i++) puntatore = puntatore?.[chiavi[i] === '#COUNT' ? count : chiavi[i]] // per ogni chiave, se è #COUNT prendo il count, altrimenti prendo la chiave
  if (puntatore) {
    puntatore[chiavi[chiavi.length - 1]] = valore // scrivo il valore
    return json
  }
  return {}
}

export const getServiceCount = (json, service) =>
  json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.[service]?.length || 0

const getAirportFromOptions = (city, options) => {
  const [filteredDepartureAirport] = [
    ...options.filter(({ label }) => label?.includes('TUTTI AEROPORTI') && label.toLowerCase().includes(city.toLowerCase())),
    ...options.filter(({ label }) => label.toLowerCase().includes(city.toLowerCase()))
  ]
  return { value: filteredDepartureAirport?.value || '', label: filteredDepartureAirport?.label || '' }
}

export const addService = async (json, service) => {
  const newJson = JSON.parse(JSON.stringify(json))
  const necessaryFields = serviceFields.filter((field) => field.service === service)
  let jsonToPush = {}
  for (const field of necessaryFields) jsonToPush[field.id] = ''
  const {
    transfer: { departureDate, arrivalDate, departurePlace, arrivalPlace },
    plane: { airportDeparture, airportArrival }
  } = getElementsByService()

  // precarico i campi necessari per ogni nuovo servizio
  if (service === 'hotel') {
    jsonToPush.checkInDate = readKey(json, departureDate.location)
    jsonToPush.checkOutDate = readKey(json, arrivalDate.location)
    jsonToPush.hotelCity = readKey(json, arrivalPlace.location)
  }
  if (service === 'car') {
    jsonToPush.pickupDate = readKey(json, departureDate.location)
    jsonToPush.dropoffDate = readKey(json, arrivalDate.location)
    jsonToPush.pickupPlace = readKey(json, arrivalPlace.location)
    jsonToPush.dropoffPlace = readKey(json, arrivalPlace.location)
    jsonToPush.carCategory = '_'
    jsonToPush.carType = '_'
    jsonToPush.carTransmission = '_'
  }
  if (service === 'train') {
    jsonToPush.typeTrip = 'OW'
    jsonToPush.departureDate = readKey(json, departureDate.location)
    jsonToPush.arrivalDate = readKey(json, arrivalDate.location)
    jsonToPush.trainCityDeparture = readKey(json, departurePlace.location)?.split(',')[0]
    jsonToPush.trainCityArrival = readKey(json, arrivalPlace.location)?.split(',')[0]
    if (jsonToPush.trainCityDeparture) {
      const [res] = await FC.service('info').get('getTrainStation', { query: { inputTrain: jsonToPush.trainCityDeparture } })
      if (res) jsonToPush.trainStationDepartureDyn = res
    }
    if (jsonToPush.trainCityArrival) {
      const [res2] = await FC.service('info').get('getTrainStation', { query: { inputTrain: jsonToPush.trainCityArrival } })
      if (res2) jsonToPush.trainStationArrivalDyn = res2
    }
  }
  if (service === 'plane') {
    jsonToPush.typeTrip = 'OW'
    jsonToPush.departureDate = readKey(json, departureDate.location)
    jsonToPush.arrivalDate = readKey(json, arrivalDate.location)
    const departureCity = readKey(json, departurePlace.location)?.split(',')[0]
    if (departureCity) {
      const { value, label } = getAirportFromOptions(departureCity, airportDeparture.options)
      jsonToPush = { ...jsonToPush, airportDeparture: value, airportDepartureDescription: label }
    }
    const arrivalCity = readKey(json, arrivalPlace.location)?.split(',')[0]
    if (arrivalCity) {
      const { value: arrivalValue, label: arrivalLabel } = getAirportFromOptions(arrivalCity, airportArrival.options)
      jsonToPush = { ...jsonToPush, airportArrival: arrivalValue, airportArrivalDescription: arrivalLabel }
    }
  }
  if (!newJson?.transferInfo?.infoPax?.listOfPax[0]?.infoServices?.[service]?.length) {
    newJson.transferInfo = { ...newJson?.transferInfo, infoPax: { ...newJson.transferInfo?.infoPax, listOfPax: [{ ...newJson.transferInfo?.infoPax?.listOfPax[0], infoServices: { ...newJson?.transferInfo?.infoPax?.listOfPax[0]?.infoServices, [service]: [] } }] } }
  }
  newJson.transferInfo.infoPax.listOfPax[0].infoServices[service].push(jsonToPush)
  return newJson
}

export const removeService = (json, service, index) => {
  const newJson = JSON.parse(JSON.stringify(json))
  newJson.transferInfo.infoPax.listOfPax[0].infoServices[service].splice(index, 1)
  return newJson
}

export const getCorrectState = (state) => {
  state?.transferInfo?.infoPax?.listOfPax?.forEach((pax) => {
    const services = Object.keys(pax.infoServices)
    pax.requestedServices = {}
    for (const service of services) {
      pax.requestedServices[service] = pax.infoServices[service]?.length > 0
      if (!pax.requestedServices[service]) delete pax.requestedServices[service]
    }
  })
  const {
    pid, unit, user, codSociety, tomcatCode, travellerId, languageInfo, transferInfo, environmentId, userEmail, referenceEmail, referenceWhatsapp, referencesBtmEmail, moduleTag, moduleEnv,
    operatorEmail, analisiStorico, levelFormalita, environmentInfo, conversationData, profileDataMittente, communicationChannel, googleCoordinates, communityPreset, modificationCancellationData, typeOfMessage
  } = state
  return {
    moduleTag,
    moduleEnv,
    pid,
    unit,
    userEmail,
    user,
    codSociety,
    tomcatCode,
    travellerId,
    languageInfo,
    transferInfo,
    environmentId,
    operatorEmail,
    analisiStorico,
    levelFormalita,
    environmentInfo,
    conversationData: {
      conversationId: conversationData?.conversationId
    },
    referenceEmail,
    profileDataMittente,
    referenceWhatsapp,
    referencesBtmEmail,
    communicationChannel,
    googleCoordinates,
    communityPreset,
    modificationCancellationData,
    typeOfMessage
  }
}

/**
 * Crea un nuovo processo asincrono basato sullo stato corrente, un identificativo di conversazione e il nome dell'unità.
 *
 * Questa funzione asincrona tenta di creare un nuovo processo invocando un servizio esterno.
 * Utilizza i parametri forniti per costruire la richiesta al servizio 'info' con azione 'createNewProcess'.
 * In caso di successo, estrae l'ID del processo creato dalla risposta e lo restituisce.
 * Se si verifica un errore durante la creazione del processo, viene mostrato un messaggio di errore
 * tramite una notifica 'growl' e viene restituito 'null'.
 *
 * @param state Oggetto che rappresenta lo stato attuale utilizzato nella creazione del processo.
 * @param conversationId Identificativo della conversazione associata al nuovo processo.
 * @param unitName Nome dell'unità associata al processo da creare.
 * @return {Promise<string|null>} Promessa che risolve nell'ID del nuovo processo creato o 'null' in caso di errore.
 */
export const createNewProcess = async (state, conversationId, unitName) => {
  try {
    const { id: processId } = await FC.service('info').create({ action: 'createNewProcess', state, conversationId, unitName })
    return processId
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore conferma form' })
    return null
  }
}

/**
 * Ottiene i dati di default per uno stato, con informazioni aggiuntive sui trasferimenti.
 *
 * Questa funzione prende come parametro uno stato e restituisce un nuovo oggetto stato.
 * All'interno dello stato, si assicura che la sezione 'transferInfo' sia aggiornata:
 * - Mantiene tutte le proprietà esistenti dello stato e di 'transferInfo'.
 * - Aggiorna 'infoPax' all'interno di 'transferInfo', includendo una lista di passeggeri 'listOfPax'.
 * - Per ogni passeggero nella lista, mantiene tutti i campi esistenti e aggiunge o mantiene due oggetti:
 *   'campi1a10Found' e 'requestDataTransfer'.
 * - Se la lista dei passeggeri 'listOfPax' è undefined, la inizializza come un array vuoto.
 *
 * @param state Oggetto che rappresenta lo stato attuale da cui ottenere i dati di default.
 * @return Un nuovo oggetto stato con i dati di default aggiornati per i trasferimenti.
 */
export const getStateDefaultData = (state) => ({
  ...state,
  transferInfo: {
    ...state?.transferInfo,
    infoPax: {
      ...state?.transferInfo?.infoPax,
      listOfPax: state?.transferInfo?.infoPax?.listOfPax?.map((pax) => ({
        ...pax,
        campi1a10Found: {
          ...pax?.campi1a10Found
        },
        requestDataTransfer: {
          ...pax?.requestDataTransfer
        }
      })) || []
    }
  }
})

export const transferFields = [
  { label: { it: 'Data di partenza' }, location: 'transferInfo.infoPax.listOfPax.0.requestDataTransfer.departureDate', id: 'departureDate', type: 'date', minDate: new Date() },
  { label: { it: 'Data di ritorno' }, location: 'transferInfo.infoPax.listOfPax.0.requestDataTransfer.arrivalDate', id: 'arrivalDate', type: 'date', minDate: new Date() },
  { label: { it: 'Luogo di partenza' }, location: 'transferInfo.infoPax.listOfPax.0.requestDataTransfer.departurePlace', id: 'departurePlace' },
  { label: { it: 'Luogo di destinazione' }, location: 'transferInfo.infoPax.listOfPax.0.requestDataTransfer.arrivalPlace', id: 'arrivalPlace' }
]

export const travellerFields = [
  { label: { it: 'PID' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.keyProfilo.personalID', id: 'pid' },
  { label: { it: 'Nome' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiPersonali.nome', id: 'name' },
  { label: { it: 'Cognome' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiPersonali.cognome', id: 'lastname' },
  { label: { it: 'Email' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.recapitiProfessionali.eMail', id: 'email' },
  { label: { it: 'Codice Cliente' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiCliente.codiceCliente', id: 'clientCode', type: 'clientCode' },
  { label: { it: 'Ragione Sociale Cliente' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiCliente.ragioneSocLunga', id: 'clientBusinessName', type: 'clientBusinessName' },
  { label: { it: 'Città Cliente' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiCliente.localita', id: 'clientCity', notRequired: true },
  { label: { it: 'Indirizzo Cliente' }, location: 'transferInfo.infoPax.listOfPax.0.profileData.profiloViaggiatoreWS02.datiCliente.indirizzo', id: 'clientStreet', notRequired: true }
]

export const serviceFields = [
  {
    id: 'typeTrip',
    label: {
      it: 'Tipo di viaggio',
      en: 'Trip Type'
    },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.typeTrip',
    type: 'dropdown',
    options: [{ label: 'Sola andata', value: 'OW' }, { value: 'RT', label: 'Andata e ritorno' }],
    order: 1
  },
  {
    id: 'companyName',
    label: { it: 'Nome della compagnia', en: 'Company Name' },
    service: 'train',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.companyName',
    order: 2
  },
  {
    id: 'classType',
    notRequired: true,
    label: { it: 'Tipo di classe', en: 'Class Type' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.classType',
    order: 3
  },
  {
    id: 'seatPreference',
    label: { it: 'Preferenza posto', en: 'Seat Preference' },
    notRequired: true,
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.seatPreference',
    order: 4
  },
  {
    id: 'departureDate',
    label: { it: 'Data di partenza', en: 'Departure Date' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.departureDate',
    type: 'date',
    minDate: new Date(),
    order: 5
  },
  {
    id: 'trainCityDeparture',
    label: { it: 'Città di partenza', en: 'Train Departure City' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.trainCityDeparture',
    order: 6
  },
  {
    id: 'fromHourDeparture',
    label: { it: 'Ora di partenza andata', en: 'Departure Time' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.fromHourDeparture',
    type: 'time',
    order: 7
  },
  {
    id: 'toHourDeparture',
    label: { it: 'Ora di arrivo andata', en: 'Arrival Time Departure' },
    notRequired: true,
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.toHourDeparture',
    type: 'time',
    order: 8
  },
  {
    id: 'arrivalDate',
    label: { it: 'Data di ritorno', en: 'Arrival Date' },
    service: 'train',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.arrivalDate',
    type: 'date',
    minDate: new Date(),
    order: 9
  },
  {
    id: 'trainCityArrival',
    label: { it: 'Città di arrivo', en: 'Train Arrival City' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.trainCityArrival',
    order: 10
  },
  {
    id: 'fromHourReturn',
    label: { it: 'Ora di partenza ritorno', en: 'Return Departure Time' },
    service: 'train',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.fromHourReturn',
    type: 'time',
    order: 11
  },
  {
    id: 'toHourReturn',
    label: { it: 'Ora di arrivo ritorno', en: 'Return Arrival Time' },
    notRequired: true,
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.toHourReturn',
    type: 'time',
    order: 12
  },
  {
    id: 'trainStationDeparture',
    label: { it: 'Stazione di partenza', en: 'Train Departure Station' },
    type: 'station',
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.trainStationDepartureDyn',
    order: 13
  },
  {
    id: 'trainStationArrival',
    label: { it: 'Stazione di arrivo', en: 'Train Arrival Station' },
    type: 'station',
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.trainStationArrivalDyn',
    order: 14
  },
  {
    id: 'withinHoursDeparture',
    label: { it: 'Arrivo entro le ore andata', en: 'Within hours departure' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.withinHoursDeparture',
    order: 15
  },
  {
    id: 'withinHoursArrival',
    label: { it: 'Arrivo entro le ore ritorno', en: 'Within hours arrival' },
    service: 'train',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.train.#COUNT.withinHoursArrival',
    order: 16
  },
  {
    id: 'checkInDate',
    label: { it: 'Data di check-in', en: 'Check-In Date' },
    service: 'hotel',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.checkInDate',
    type: 'date',
    minDate: new Date(),
    order: 1
  },
  {
    id: 'checkOutDate',
    label: { it: 'Data di check-out', en: 'Check-Out Date' },
    service: 'hotel',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.checkOutDate',
    type: 'date',
    minDate: new Date(),
    order: 2
  },
  {
    id: 'hotelCity',
    label: { it: 'Città o zona hotel', en: 'Hotel city or zone' },
    service: 'hotel',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.hotelCity',
    order: 3
  },
  {
    id: 'hotelName',
    label: { it: 'Nome hotel', en: 'Hotel Name' },
    service: 'hotel',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.hotelName',
    order: 5
  },
  {
    id: 'hotelStars',
    label: { it: 'Stelle hotel', en: 'Hotel Stars' },
    service: 'hotel',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.hotelStars',
    order: 6
  },
  {
    id: 'hotelParking',
    label: { it: 'Parcheggio hotel', en: 'Hotel Parking' },
    service: 'hotel',
    type: 'dropdown',
    options: [{ label: 'Si', value: true }, { label: 'No', value: false }],
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.hotelParking',
    order: 7
  },
  {
    id: 'treatment',
    label: { it: 'Trattamento', en: 'Treatment' },
    service: 'hotel',
    type: 'dropdown',
    options: [
      { value: ' ', label: 'Non specificato' },
      { value: 'RO', label: 'Solo stanza' },
      { value: 'BB', label: 'Bed and Breakfast' },
      { value: 'HB', label: 'Mezza pensione' },
      { value: 'FB', label: 'Pensione completa' },
      { value: 'DN', label: 'Cena' },
      { value: 'LN', label: 'Pranzo' },
      { value: 'AL', label: 'All Inclusive' }
    ],
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.hotel.#COUNT.treatment',
    order: 8
  },
  {
    id: 'typeTrip',
    label: { it: 'Tipo di viaggio', en: 'Trip Type' },
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.typeTrip',
    type: 'dropdown',
    options: [{ label: 'Sola andata', value: 'OW' }, { value: 'RT', label: 'Andata e ritorno' }],
    order: 1
  },
  {
    id: 'companyName',
    label: { it: 'Nome della compagnia', en: 'Company Name' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.companyName',
    order: 2
  },
  {
    id: 'classType',
    label: { it: 'Tipo di classe', en: 'Class Type' },
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.classType',
    notRequired: true,
    order: 3
  },
  {
    id: 'seatPreference',
    label: { it: 'Preferenza posto', en: 'Seat Preference' },
    notRequired: true,
    service: 'plane',
    type: 'dropdown',
    options: [{ value: 'W', label: 'Finestrino' }, { value: 'A', label: 'Corridoio' }, { value: 'N', label: 'Centrale' }, { value: ' ', label: 'Non specificato' }],
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.seatPreference',
    order: 4
  },
  {
    id: 'departureDate',
    label: { it: 'Data di partenza', en: 'Departure Date' },
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.departureDate',
    type: 'date',
    minDate: new Date(),
    order: 5
  },
  {
    id: 'airportDeparture',
    label: {
      value: { it: 'Aeroporto di partenza', en: 'Departure Airport' },
      label: { it: 'Descrizione aeroporto di partenza', en: 'Departure Airport Description' }
    },
    type: 'airport',
    options: airports,
    service: 'plane',
    location: {
      value: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.airportDeparture',
      label: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.airportDepartureDescription'
    },
    order: 6
  },
  {
    id: 'fromHourDeparture',
    label: { it: 'Ora di partenza andata', en: 'Departure Time Departure' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.fromHourDeparture',
    type: 'time',
    order: 7
  },
  {
    id: 'toHourDeparture',
    label: { it: 'Ora di arrivo andata', en: 'Arrival Time Departure' },
    notRequired: true,
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.toHourDeparture',
    type: 'time',
    order: 8
  },
  {
    id: 'arrivalDate',
    label: { it: 'Data di ritorno', en: 'Arrival Date' },
    notRequired: true,
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.arrivalDate',
    type: 'date',
    minDate: new Date(),
    order: 9
  },
  {
    id: 'airportArrival',
    label: {
      value: { it: 'Aeroporto di arrivo', en: 'Arrival Airport' },
      label: { it: 'Descrizione aeroporto di arrivo', en: 'Arrival Airport Description' }
    },
    type: 'airport',
    options: airports,
    service: 'plane',
    location: {
      value: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.airportArrival',
      label: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.airportArrivalDescription'
    },
    order: 10
  },
  {
    id: 'fromHourReturn',
    label: { it: 'Ora di partenza ritorno', en: 'Return Departure Time' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.fromHourReturn',
    type: 'time',
    order: 11
  },
  {
    id: 'toHourReturn',
    label: { it: 'Ora di arrivo ritorno', en: 'Return Arrival Time' },
    notRequired: true,
    service: 'plane',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.toHourReturn',
    type: 'time',
    order: 12
  },
  {
    id: 'luggageType',
    label: { it: 'Tipo di bagaglio', en: 'Luggage Type' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.luggageType',
    order: 13
  },
  {
    id: 'typeBoarding',
    label: { it: 'Tipo di imbarco', en: 'Boarding Type' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.typeBoarding',
    order: 14
  },
  {
    id: 'companyCode',
    label: { it: 'Codice compagnia', en: 'Company Code' },
    service: 'plane',
    notRequired: true,
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.plane.#COUNT.companyCode',
    order: 15
  },
  {
    id: 'pickupPlace',
    label: { it: 'Luogo di Ritiro', en: 'Pick-up Place' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.pickupPlace',
    order: 1
  },
  {
    id: 'pickupDate',
    label: { it: 'Data di Ritiro', en: 'Pick-up Date' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.pickupDate',
    type: 'date',
    minDate: new Date(),
    order: 2
  },
  {
    id: 'pickupHour',
    label: { it: 'Ora di Ritiro', en: 'Pick-up Hour' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.pickupHour',
    type: 'time',
    order: 3
  },
  {
    id: 'dropoffPlace',
    label: { it: 'Luogo di Rilascio', en: 'Drop-off Place' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.dropoffPlace',
    order: 4
  },
  {
    id: 'dropoffDate',
    label: { it: 'Data di Rilascio', en: 'Drop-off Date' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.dropoffDate',
    type: 'date',
    minDate: new Date(),
    order: 5
  },
  {
    id: 'dropoffHour',
    label: { it: 'Ora di Rilascio', en: 'Drop-off Hour' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.dropoffHour',
    type: 'time',
    order: 6
  },
  {
    id: 'carCategory',
    label: { it: 'Categoria auto', en: 'Car Category' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.carCategory',
    type: 'dropdown',
    order: 7,
    options: [
      { value: '_', label: 'Non specificato' },
      { value: 'M', label: 'Mini' },
      { value: 'E', label: 'Economy' },
      { value: 'C', label: 'Compact' },
      { value: 'I', label: 'Intermediate' },
      { value: 'S', label: 'Standard' },
      { value: 'F', label: 'Full size' },
      { value: 'L', label: 'Luxury' },
      { value: 'P', label: 'Premium' }
    ]
  },
  {
    id: 'carType',
    label: { it: 'Tipo auto', en: 'Car Type' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.carType',
    type: 'dropdown',
    order: 8,
    options: [
      { value: '_', label: 'Non specificato' },
      { value: 'D', label: '4-5 porte' },
      { value: 'F', label: 'Suv' },
      { value: 'V', label: 'Van' }
    ]
  },
  {
    id: 'carTransmission',
    label: { it: 'Trasmissione auto', en: 'Car Transmission' },
    service: 'car',
    location: 'transferInfo.infoPax.listOfPax.0.infoServices.car.#COUNT.carTransmission',
    type: 'dropdown',
    order: 9,
    options: [
      { value: '_', label: 'Non specificato' },
      { value: 'M', label: 'Manuale' },
      { value: 'A', label: 'Automatico' }
    ]
  }
]

export const getElementsByService = () => ({
  transfer: transferFields.reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {}),
  traveller: travellerFields.reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {}),
  train: serviceFields.filter(({ service }) => service === 'train').reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {}),
  hotel: serviceFields.filter(({ service }) => service === 'hotel').reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {}),
  plane: serviceFields.filter(({ service }) => service === 'plane').reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {}),
  car: serviceFields.filter(({ service }) => service === 'car').reduce((acc, { id, ...otherFields }) => ({ ...acc, [id]: { id, ...otherFields } }), {})
})

export const getLabelMidofficeRef = (state) => {
  const { transferInfo: { infoPax: { listOfPax: [{ transferMidOfficeRef: { annoTrasferta, numeroPratica, numeroTrasferta } = {} } = {}] = [] } = {} } = {} } = state
  if (!annoTrasferta || !numeroPratica || !numeroTrasferta) return ''
  return '| Anno ' + annoTrasferta + ' - Pratica ' + numeroPratica + ' - Trasferta ' + numeroTrasferta
}

export const getStateFromInfoServices = (infoServices) => ({
  transferInfo: { infoPax: { listOfPax: [{ infoServices }] } }
})
