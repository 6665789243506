const searchDivs = ['key', 'string', 'number']

export const searchInView = (searchString, numberSearch) => {
  let searchedElement = null
  const highlights = document.querySelectorAll('.highlight')
  for (const el of highlights) el.outerHTML = el.innerHTML
  if (!searchString.trim()) return
  const element = document.querySelector('#compare .c-json-view')
  if (!element) return
  const search = searchString.toLowerCase()
  let occurence = 0
  for (const div of searchDivs) {
    const elements = element.querySelectorAll(`.c-json-${div}`)
    for (const el of elements) {
      if (el.textContent.toLowerCase().includes(search)) {
        if (occurence === numberSearch) searchedElement = el
        occurence++
      }
    }
  }
  if (searchedElement) {
    searchedElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    const regex = new RegExp(`(${searchString})`, 'gi')
    const newHtml = searchedElement.innerHTML.replace(regex, '<span class="highlight">$1</span>')
    searchedElement.innerHTML = newHtml
  }
  return occurence
}
