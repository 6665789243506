import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import _ from 'lodash'
import React from 'react'

const debounce = _.debounce((editor, id, onChange) => {
  if (!editor.getData()) return onChange({ [id]: '' })
  const data = '<div class="ck-content">' + editor.getData() + '</div>'
  onChange({ [id]: data })
}, 600)

const Editor = ({ id, onChange, initialValue }) => {
  const customConfig = {
    toolbar: [
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'link',
      'blockquote',
      'insertTable',
      '|',
      'undo',
      'redo'
    ],
    extraPlugins: []
  }
  return (
    <div style={{ backgroundColor: 'white', width: '100%', color: 'black', border: '1px solid var(--ck-color-toolbar-border)' }}>
      <CKEditor
        onReady={editor => {
          editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
            editor?.ui?.view?.toolbar?.element,
            editor?.ui?.getEditableElement()
          )
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            this.editor?.ui?.view?.toolbar?.element?.remove()
          }
        }}
        config={customConfig}
        onChange={(event, editor) => {
          debounce(editor, id, onChange)
        }}
        editor={DecoupledEditor}
        data={initialValue}
      />
    </div>
  )
}

export { Editor }
