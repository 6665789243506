import React from 'react'
import { Flex, Icon, Text } from '../../../Components'
import LogoSiap from '../LogoSiap.png'
const cssGlass = {
  backdropFilter: 'blur(22px) saturate(146%)',
  WebkitBackdropFilter: 'blur(22px) saturate(146%)',
  backgroundColor: 'rgba(17, 25, 40, 0.78)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.125)'
}
export default function FooterBrochure () {
  return (
    <Flex fw row js as style={{ marginTop: 10 }}>
      <Flex
        style={{
          padding: 20,
          margin: 10,
          borderRadius: 30,
          ...cssGlass
        }} fw row wrap
      >
        <Flex row style={{ minWidth: 170, marginTop: 10 }}>
          <Icon name='phone' color='white' />
          <a href='tel:+39 0172 652511' style={{ textDecoration: 'underline', textDecorationColor: 'white', color: 'white' }}>
            <Text value='+39 0172 652511' color='white' style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 10 }} />
          </a>
        </Flex>
        <Flex row style={{ minWidth: 170, marginTop: 10 }}>
          <Icon name='mail' color='white' />
          <a href='mailto:info@siapcn.it' style={{ textDecoration: 'underline', textDecorationColor: 'white', color: 'white' }}>
            <Text value='info@siapcn.it' color='white' style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 10 }} />
          </a>
        </Flex>
        <Flex row style={{ minWidth: 170, marginTop: 10 }}>
          <Icon name='world' color='white' />
          <a href='https://www.siapcn.it' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline', textDecorationColor: 'white', color: 'white' }}>
            <Text value='https://www.siapcn.it' color='white' style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 10 }} />
          </a>
        </Flex>
        <Flex fw row>
          <img src={LogoSiap} alt='Logo Siap' style={{ maxWidth: 200, maxHeight: 200, resizeMode: 'contain', marginTop: 20 }} />
        </Flex>
      </Flex>
    </Flex>
  )
}
