// ritorna il numero di processi raggruppati per stato
export const groupByState = (processes = []) => {
  const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
  for (const item of processes) {
    if (item.state in counts) counts[item.state]++
    else counts[item.state] = 1
  }
  return counts
}

export const getColor = (value, max) => {
  const states = max / 10
  const color = value / states
  if (color < 1) return '#00ff40'
  if (color < 2) return '#00ff00'
  if (color < 3) return '#40ff00'
  if (color < 4) return '#80ff00'
  if (color < 5) return '#bfff00'
  if (color < 6) return '#ffff00'
  if (color < 7) return '#ffbf00'
  if (color < 8) return '#ff8000'
  if (color < 9) return '#ff4000'
  if (color < 10) return '#ff0000'
}

export const formatTime = (value, noMilliseconds) => {
  const minutes = Math.floor(value / 60)
  const seconds = Math.floor(value - minutes * 60)
  const milliseconds = Math.floor((value - minutes * 60 - seconds) * 1000)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}${noMilliseconds ? '' : `.${milliseconds.toString().padStart(3, '0')}`}`
}

export const getDate = (dateString) => {
  const [day, month, year] = dateString.split('/')
  return new Date(year, month - 1, day)
}
