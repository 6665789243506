import React from 'react'
import { Flex, PanelText } from '../../../Components'
import { Editor } from '../../../Components/Common/Editor'
import { Divider } from 'primereact/divider'
import { Checkbox } from 'primereact/checkbox'

export const Editors = ({ userRedirectToOperatorMessage: [userRedirectToOperatorMessage, setUserRedirectToOperatorMessage], checkUserRedirectToOperatorMessage: [checkUserRedirectToOperatorMessage, setCheckUserRedirectToOperatorMessage], checkMeteo: [checkMeteo, setCheckMeteo], quotesMessage: [quotesMessage, setQuotesMessage], initialMessages }) => (
  <Flex js as wrap style={{ gap: 20 }} fw>
    <Divider />
    <Flex row js fw wrap style={{ gap: 15 }}>
      <Checkbox checked={checkMeteo} onChange={e => setCheckMeteo(e.checked)} />
      <Flex as fh>
        <PanelText value='Inclusione informazioni meteo' header />
        <PanelText value='Se selezionato verranno inserite nel messagio le informazioni sul meteo nel periodo della trasferta' />
      </Flex>
    </Flex>
    <Flex js as>
      <PanelText value='Messaggio per preventivi:' header />
      <PanelText value={'All\'interno del messaggio dove compare:'} />
      <PanelText value={'- \'#link\': verrà inserito il link per visualizzare i preventivi'} />
      {checkMeteo && <PanelText value={'- \'#meteo\': verranno inserite le informazioni sul meteo (se richiesta viaggio è entro 5 gg dalla partenza). Per far visualizzare la frase è necessario abilitare il flag "Inclusione informazioni meteo"'} />}
    </Flex>
    <Editor
      id='quotesMessage'
      onChange={({ quotesMessage: changedQuotesMessage }) =>
        changedQuotesMessage.trim() !== quotesMessage.trim() && setQuotesMessage(changedQuotesMessage)}
      initialValue={initialMessages.quotesMessage || ''}
    />
    <Flex row js fw wrap style={{ gap: 15 }}>
      <Checkbox checked={checkUserRedirectToOperatorMessage} onChange={e => setCheckUserRedirectToOperatorMessage(e.checked)} />
      <Flex as fh>
        <PanelText value='Messaggio preimpostato per utente' header />
        <PanelText value='Se selezionato verrà notificato il viaggiatore che la sua richiesta è presa in carico da un operatore reale' />
      </Flex>
    </Flex>
    {checkUserRedirectToOperatorMessage &&
      <>
        <Flex js as>
          <PanelText value='Messaggio per reindirizzamento operatore:' header />
          <PanelText value="Messaggio preimpostato da inviare all'utente in caso di reindirizzamento all'operatore" />
        </Flex>
        <Editor
          id='userRedirectToOperatorMessage'
          onChange={({ userRedirectToOperatorMessage: changedUserRedirectToOperatorMessage }) =>
            changedUserRedirectToOperatorMessage.trim() !== userRedirectToOperatorMessage.trim() && setUserRedirectToOperatorMessage(changedUserRedirectToOperatorMessage)}
          initialValue={initialMessages.userRedirectToOperatorMessage || ''}
        />
      </>}
  </Flex>
)
