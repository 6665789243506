import { useState } from 'react'
import { Flex, PanelText } from '../../../Components'
import { InputText } from 'primereact/inputtext'
import { FC } from '../../../Services'
import { AutoComplete } from 'primereact/autocomplete'
import _ from 'lodash'

const fieldMap = [{ id: 'mastro', label: 'Mastro', maxLength: 2 }, { id: 'gruppo', label: 'Gruppo', maxLength: 2 }, { id: 'conto', label: 'Conto', maxLength: 2 }, { id: 'sottoConto', label: 'Sotto Conto', maxLength: 6 }]

export const SearchByClient = ({ clientCode: [clientCode, setClientCode], selectedClient: [selectedClient, setSelectedClient], ragioneSociale: [ragioneSociale, setRagioneSociale], disabled }) => {
  const [suggestions, setSuggestions] = useState([])

  const debounceSearchDescrizione = _.debounce(async (event) => {
    const value = event.query.trim()
    if (value.length < 3) return setSuggestions([])
    const { data: suggestions } = await FC.service('info').get('searchClient', { query: { ragioneSociale: value } })
    setSuggestions(suggestions
      .filter(({ datiGeneraliCliente: { ragioneSociale } }) => ragioneSociale)
      .map(client => ({
        codCliente12: client?.datiGeneraliCliente?.codCliente12,
        ragioneSociale: client?.datiGeneraliCliente?.ragioneSociale
      })))
  }, 500)

  return (
    <Flex js as wrap style={{ rowGap: 15, width: '49%' }}>
      <PanelText value='Ricerca cliente' header />
      <Flex row js as fw wrap>
        {Object.entries(clientCode).map(([key, value], ind) => (
          <InputText
            readOnly={ind === 0}
            disabled={disabled}
            key={ind}
            style={{ borderRadius: 13, marginLeft: (ind + 1) % 2 === 0 ? 15 : 0, width: 'calc(50% - 7.5px)', marginBottom: 15 }}
            value={value || ''}
            label={fieldMap?.find(({ id }) => id === key)?.label}
            id={key}
            onChange={(e) => setClientCode({ ...clientCode, [key]: e?.target?.value })}
            keyfilter='int'
            placeholder={fieldMap?.find(({ id }) => id === key)?.label}
          />
        ))}
        <AutoComplete
          inputId='ragioneSociale' placeholder='Ragione Sociale'
          inputStyle={{ width: '100%', borderRadius: 13 }}
          style={{ width: '100%' }} value={ragioneSociale || ''}
          suggestions={suggestions} completeMethod={debounceSearchDescrizione}
          onChange={({ value }) => setRagioneSociale(value)}
          onSelect={({ value }) => {
            setSelectedClient(value)
            setClientCode({
              ...clientCode,
              mastro: value?.codCliente12?.slice(0, 2),
              gruppo: value?.codCliente12?.slice(2, 4),
              conto: value?.codCliente12?.slice(4, 6),
              sottoConto: value?.codCliente12?.slice(6, 12)
            })
            setRagioneSociale(value?.ragioneSociale)
          }}
          field='ragioneSociale'
          disabled={disabled}
        />
      </Flex>
    </Flex>
  )
}
