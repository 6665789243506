const hoursMinutesCheck = (firstPart, secondPart) => {
  if (parseInt(firstPart) < 24 && parseInt(secondPart) < 60) return [firstPart.padStart(2, '0'), ':', secondPart.padStart(2, '0')].join('')
  return ''
}

export const formattingTime = (time = '') => {
  const regex = /[a-zA-Z]/
  if (regex.exec(time)) return ''
  switch (time.length) {
    case 5:
      if (time.includes(':')) {
        const firstPart = time.slice(0, 2)
        const secondPart = time.slice(3)
        return hoursMinutesCheck(firstPart, secondPart)
      }
      break
    case 4:
      if (time.includes(':')) {
        const colonIndex = time.indexOf(':')
        const firstPart = time.slice(0, colonIndex)
        const secondPart = time.slice(colonIndex + 1)
        return hoursMinutesCheck(firstPart, secondPart)
      } else {
        const firstPart = time.slice(0, 2)
        const secondPart = time.slice(2)
        return hoursMinutesCheck(firstPart, secondPart)
      }
    case 3:
      if (time.includes(':')) {
        const colonIndex = time.indexOf(':')
        const firstPart = time.slice(0, colonIndex)
        const secondPart = time.slice(colonIndex + 1)
        return hoursMinutesCheck(firstPart, secondPart)
      }
      break
    case 2:
      if (!time.includes(':')) return hoursMinutesCheck(time, '00')
      break
    case 1:
      if (!time.includes(':')) return hoursMinutesCheck('0' + time, '00')
      break
  }
  return ''
}
