export const cityOptions = [
  {
    label: 'NH HOTEL PALERMO',
    value: { place: { description: 'Hotel Nh Palermo,Foro Italico, Palermo, IT' }, placeDetail: { name: 'Foro Italico Umberto I, 22/B, 90133 Palermo PA, Italy', geometry: { location: { lat: 38.1153049, lng: 13.3749962 }, viewport: { northeast: { lat: 38.1167389802915, lng: 13.3764202802915 }, southwest: { lat: 38.11404101970851, lng: 13.3737223197085 } }, location_type: 'ROOFTOP' }, addressComponents: [{ types: ['street_number'], longName: '22/B', shortName: '22/B' }, { types: ['route'], longName: 'Foro Italico Umberto I', shortName: 'Foro Italico Umberto I' }, { types: ['locality', 'political'], longName: 'Palermo', shortName: 'Palermo' }, { types: ['administrative_area_level_3', 'political'], longName: 'Palermo', shortName: 'Palermo' }, { types: ['administrative_area_level_2', 'political'], longName: 'Città metropolitana di Palermo', shortName: 'PA' }, { types: ['administrative_area_level_1', 'political'], longName: 'Sicilia', shortName: 'Sicilia' }, { types: ['country', 'political'], longName: 'Italy', shortName: 'IT' }, { types: ['postal_code'], longName: '90133', shortName: '90133' }] } }
  },
  {
    label: 'Milano',
    value: { place: { description: 'Milano' }, placeDetail: { addressComponents: [{ longName: 'Milan', shortName: 'Milan', types: ['locality', 'political'] }, { longName: 'Milan', shortName: 'Milan', types: ['administrative_area_level_3', 'political'] }, { longName: 'Metropolitan City of Milan', shortName: 'MI', types: ['administrative_area_level_2', 'political'] }, { longName: 'Lombardy', shortName: 'Lombardy', types: ['administrative_area_level_1', 'political'] }, { longName: 'Italy', shortName: 'IT', types: ['country', 'political'] }], geometry: { location: { lat: 45.4642035, lng: 9.189982 }, viewport: { northeast: { lat: 45.53568898729802, lng: 9.290346273733416 }, southwest: { lat: 45.38977870977718, lng: 8.228119999999999 } } }, name: 'Milan' } }
  },
  {
    label: 'Firenze',
    value: { place: { description: 'Firenze' }, placeDetail: { addressComponents: [{ longName: 'Florence', shortName: 'Florence', types: ['locality', 'political'] }, { longName: 'Florence', shortName: 'Florence', types: ['administrative_area_level_3', 'political'] }, { longName: 'Metropolitan City of Florence', shortName: 'FI', types: ['administrative_area_level_2', 'political'] }, { longName: 'Tuscany', shortName: 'Tuscany', types: ['administrative_area_level_1', 'political'] }, { longName: 'Italy', shortName: 'IT', types: ['country', 'political'] }], geometry: { location: { lat: 43.7695604, lng: 11.2558136 }, viewport: { northeast: { lat: 43.83293684020723, lng: 11.32789933774615 }, southwest: { lat: 43.72697953575286, lng: 11.15403651983466 } } }, name: 'Florence' } }
  },
  {
    label: 'Lucca',
    value: { place: { description: 'Lucca' }, placeDetail: { addressComponents: [{ longName: 'Lucca', shortName: 'Lucca', types: ['locality', 'political'] }, { longName: 'Lucca', shortName: 'Lucca', types: ['administrative_area_level_3', 'political'] }, { longName: 'Province of Lucca', shortName: 'LU', types: ['administrative_area_level_2', 'political'] }, { longName: 'Tuscany', shortName: 'Tuscany', types: ['administrative_area_level_1', 'political'] }, { longName: 'Italy', shortName: 'IT', types: ['country', 'political'] }, { longName: '55100', shortName: '55100', types: ['postal_code'] }], geometry: { location: { lat: 43.8429197, lng: 10.5026977 }, viewport: { northeast: { lat: 43.93071584510447, lng: 10.55952407495376 }, southwest: { lat: 43.76870284897495, lng: 10.38515504374414 } } }, name: 'Lucca' } }
  },
  {
    label: 'Palermo',
    value: { place: { description: 'Palermo' }, placeDetail: { addressComponents: [{ longName: 'Palermo', shortName: 'Palermo', types: ['locality', 'political'] }, { longName: 'Palermo', shortName: 'Palermo', types: ['administrative_area_level_3', 'political'] }, { longName: 'Metropolitan City of Palermo', shortName: 'PA', types: ['administrative_area_level_2', 'political'] }, { longName: 'Sicily', shortName: 'Sicily', types: ['administrative_area_level_1', 'political'] }, { longName: 'Italy', shortName: 'IT', types: ['country', 'political'] }], geometry: { location: { lat: 38.11569, lng: 13.3614868 }, viewport: { northeast: { lat: 38.21954798877328, lng: 13.44715662204694 }, southwest: { lat: 38.06153916908529, lng: 13.26742045711205 } } }, name: 'Palermo' } }
  },
  {
    label: 'Asti',
    value: { place: { description: 'Asti' }, placeDetail: { addressComponents: [{ longName: 'Asti', shortName: 'Asti', types: ['locality', 'political'] }, { longName: 'Asti', shortName: 'Asti', types: ['administrative_area_level_3', 'political'] }, { longName: 'Province of Asti', shortName: 'AT', types: ['administrative_area_level_2', 'political'] }, { longName: 'Piedmont', shortName: 'Piedmont', types: ['administrative_area_level_1', 'political'] }, { longName: 'Italy', shortName: 'IT', types: ['country', 'political'] }, { longName: '14100', shortName: '14100', types: ['postal_code'] }], geometry: { location: { lat: 44.90075119999999, lng: 8.2064257 }, viewport: { northeast: { lat: 44.9293240536237, lng: 8.263725068439213 }, southwest: { lat: 44.86442636030586, lng: 8.155009141984765 } } }, name: 'Asti' } }
  }
]

export const treatmentOptions = [
  { value: ' ', label: 'non selezionato' },
  { value: 'RO', label: 'room only' },
  { value: 'BB', label: 'bed and breakfast' },
  { value: 'HB', label: 'half board' },
  { value: 'FB', label: 'full board' },
  { value: 'DN', label: 'dinner' },
  { value: 'LN', label: 'launch' },
  { value: 'AL', label: 'all inclusive' }
]

export const seatOptions = [
  { value: ' ', label: 'non specificato' },
  { value: 'W', label: 'Finestrino' },
  { value: 'A', label: 'Corridoio' },
  { value: 'N', label: 'Centrale' }
]
