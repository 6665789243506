import React from 'react'
import { Flex } from '../../Components'
import noraAvatar from './NoraAvatar.png'
import noraScritta from './NoraScritta.png'

import { Background } from '../Fiera/Components/Background'
import FooterBrochure from './components/FooterBrochure'
import ParagrafiBrochure from './components/ParagrafiBrochure'
import ChatBrochure from './components/ChatBrochure'

export const Brochure = () => {
  return (
    <Background>
      <div style={{ scrollBehavior: 'smooth', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}>
        <Flex fw as js fh>
          <Flex fw row jb as>
            <Flex as js style={{ width: '50%', maxHeight: 200 }}>
              <img src={noraAvatar} alt='Nora Avatar' style={{ maxWidth: '100%', maxHeight: 200, objectFit: 'contain' }} />
            </Flex>
            <div style={{ width: '50%', maxHeight: 200, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 20 }}>
              <img src={noraScritta} alt='Nora Avatar' style={{ maxHeight: 200, maxWidth: '100%', resizeMode: 'contain' }} />
            </div>
          </Flex>
          <Flex fw as row js>
            <ParagrafiBrochure />
            <ChatBrochure />
          </Flex>
          <Flex fw>
            <FooterBrochure />
          </Flex>
        </Flex>
      </div>
    </Background>

  )
}
