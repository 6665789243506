import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { useCQuery } from '../../../../Services/QueryCache'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Modal, Spinner, Text } from '../../../../Components'
import { Column } from 'primereact/column'
import { columnProps } from '../../../Prompts/Functions'
import { deleteUser } from '../Editor/Functions'

export function UsersList () {
  const { data: users = [], isSuccess } = useCQuery(['users'])
  const [usersTable, setUsersTable] = useState([])
  const navigate = useNavigate([])

  useEffect(() => {
    setUsersTable(users.map((r) => r))
  }, [users])

  const BtnActions = ({ id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={160} row>
        <Modal visible={modalVisible} header='Cancellazione Utente' onHide={() => setModalVisible(false)}>
          <Flex fw>
            <Text value='Vuoi cancellare questo utente?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='bin' onClick={() => setModalVisible(false)} />
              <Button label='Conferma' icon='check' onClick={() => deleteUser(id)} />
            </Flex>
          </Flex>
        </Modal>
        <Button round tooltip='Modifica' icon='edit' onClick={() => navigate('/admin/users/' + id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='bin' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/admin/users/new')} />
      </Flex>
      <Text value='Lista Utenti' size={20} bold />
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={usersTable} emptyMessage='Nessun utente trovato' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='username' {...columnProps('Username')} />
        <Column field='createdAt' {...columnProps('Data creazione')} body={({ createdAt }) => new Date(createdAt).toLocaleDateString()} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
