import React, { useRef, useState } from 'react'
import { readClients, uploadClients } from './Functions'
import { Button, Flex, Text } from '../../../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ImportTutorial, TextEditor } from './Components'

export const TravellersImport = () => {
  const refReadClients = useRef(null)
  const [importedClients, setImportedClients] = useState([])

  const importClients = async (e) => {
    const res = await readClients(e)
    if (!res) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante l\'importazione dei viaggiatori' })
    window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Trovati ' + res.length + ' viaggiatori' })
    setImportedClients(res)
  }

  const onRowEditComplete = ({ newData, index }) => {
    const _products = [...importedClients]
    _products[index] = newData
    setImportedClients(_products)
  }

  const confirmClients = async () => {
    await uploadClients(importedClients)
    setImportedClients([])
  }

  return (
    <Flex fw>
      <input type='file' accept='.csv' ref={refReadClients} style={{ display: 'none' }} onChange={(e) => importClients(e)} />
      {importedClients?.length
        ? (
          <DataTable
            style={{ width: '100%', boxShadow: '0 0 10px rgb(80, 80, 80)', borderRadius: 20, padding: 10 }} sortField='createdAt' sortOrder={-1}
            scrollHeight='90vh' value={importedClients} emptyMessage='Niente da visualizzare' responsiveLayout='scroll' paginator rows={10}
            className='TableSignatures' filterDisplay='row' onRowEditComplete={onRowEditComplete} editMode='row'
          >
            {Object.keys(importedClients[0]).map((key, ind) => (
              <Column
                key={ind} header={key} alignHeader='center' showClearButton={false} filter
                filterMatchMode='contains' style={{ textAlign: 'center' }} sortable
                filterPlaceholder={'🔍   ' + key} showFilterMenu={false} field={key}
                body={({ [key]: value }) => <Text center value={value} />}
                editor={(options) => TextEditor(options)}
              />))}
            <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} />
          </DataTable>
          )
        : <ImportTutorial />}
      <Flex row>
        <Button icon='cloud-up' label='Carica CSV' style={{ marginTop: 10 }} onClick={() => refReadClients.current.click()} />
        {!!importedClients?.length &&
          <Button label='Carica su DB' icon='check' style={{ marginTop: 10, marginLeft: 10 }} onClick={confirmClients} />}
      </Flex>
    </Flex>
  )
}
