import React from 'react'
import { Button, Flex, Spinner, DateSelectRange } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useNavigate } from 'react-router-dom'
import { ProcessBar } from './ProcessBar'
import { ProcessTimeLine } from './ProcessTimeLine'
import { ServerStatus } from './ServerStatus'
import { useCapsule } from '../../../State/Functions'
import { cap } from '../../../State'
import { ProcessSteps } from './ProcessSteps'
import { PricingTimeline } from './PricingTimeline'

export function ProcessesDashboard () {
  const [startDate, setStartDate] = useCapsule(cap.selectedStartDateDashboard)
  const [endDate, setEndDate] = useCapsule(cap.selectedEndDateDashboard)
  const { isSuccess } = useCQuery(['getStats', startDate, endDate])
  const navigate = useNavigate()
  return (
    <Flex>
      <Flex row fw>
        <Button label='Lista conversazioni' onClick={() => navigate('/conversations/list')} style={{ height: 40, width: 170 }} />
        <Button label='Statistiche errori' onClick={() => navigate('/process/errorStats')} style={{ marginLeft: 20, height: 40, width: 170 }} />
      </Flex>
      <Flex row fw style={{ width: '100%', marginTop: 10 }}>
        <DateSelectRange startDate={[startDate, setStartDate]} endDate={[endDate, setEndDate]} />
      </Flex>
      {!isSuccess
        ? <Flex fw fh> <Spinner /> </Flex>
        : (
          <Flex style={{ width: '100%' }}>
            <Flex fw fh row wrap style={{ marginTop: 10, gap: 20, alignItems: 'stretch' }}>
              <Flex fh style={{ width: 'calc(50% - 10px)' }}>
                <ProcessBar dates={[startDate, endDate]} />
              </Flex>
              <Flex style={{ width: 'calc(50% - 10px)' }}>
                <ServerStatus dates={[startDate, endDate]} />
              </Flex>
              <Flex fh fw>
                <ProcessTimeLine dates={[startDate, endDate]} />
              </Flex>
              <Flex fh fw>
                <ProcessSteps dates={[startDate, endDate]} />
              </Flex>
              <Flex fh fw>
                <PricingTimeline dates={[startDate, endDate]} />
              </Flex>
            </Flex>
          </Flex>)}
    </Flex>
  )
}
