import React from 'react'

export const Text = ({ style, title, panel, bold, italic, size, color, center, upCase, value, className }) => (
  <div
    className={(className || '') + ' textContainer'}
    style={{
      fontWeight: bold ? 'bold' : 'normal',
      fontStyle: italic ? 'italic' : 'normal',
      fontSize: size || 15,
      fontFamily: title ? 'Lato, sans-serif' : 'Lexend, \'Source Sans Pro\', sans-serif',
      ...(color && { color }),
      textAlign: center ? 'center' : 'start',
      ...style
    }}
  >
    {upCase ? value.toUpperCase() : value}
  </div>
)
