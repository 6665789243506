import { useLocation } from 'react-router-dom'
// import giginoPaneVino from '../Resources/giginoPaneVino.png'
const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path }) => path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = ({ role }) => ([
  { label: 'Chat', icon: 'send', path: '/chat/', role: ['admin', 'tester'] },
  { label: 'Elenco Società', icon: 'business', path: '/admin/society/list', role: ['admin'] },
  { label: 'Elenco Utenti', icon: 'emoticons', path: '/admin/users/list', role: ['admin'] },
  { label: 'Elenco Viaggiatori', icon: 'plane', path: '/admin/travellers/list', role: ['admin'] },
  { label: 'Operazioni', icon: 'settings', path: '/operations/list', role: ['admin'] },
  { label: 'Processi', icon: 'chat-history', path: '/process/dashboard', role: ['admin'] },
  { label: 'Pannello Community', icon: 'behaviour', path: '/community/search', role: ['admin', 'dynamicTester', 'tester', 'operatorCommunity'] },
  { label: 'FORM OPERATORE', icon: 'climbing', path: '/operatore', role: ['admin', 'operator', 'tester', 'operatorCommunity'] },
  { label: 'Admin Coefficienti', icon: 'paperwork', path: '/admin/coefficient', role: ['admin', 'dynamicTester', 'operatorCommunity'] },
  { label: 'Test Dinamico', icon: 'experiments', path: '/testDyn', role: ['admin', 'dynamicTester', 'tester', 'operatorCommunity'] },
  { label: 'Test Automatico', icon: 'experiments', path: '/autotest', role: ['admin'] },
  { label: 'Logout', icon: 'primeicon pi-power-off', path: 'logout' }
]).filter(item => !item.role || item?.role?.includes(role))
