export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const search = Array.isArray(params) ? params : [params]
  const found = search.map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const padZero = (num) => num.toString().padStart(2, '0')

export const getDateObj = (dateStr) => {
  const [day, month, year] = dateStr.split('/')
  return new Date(year, month - 1, day)
}

export const getDateString = (dateObj) => {
  const day = padZero(dateObj.getDate())
  const month = padZero(dateObj.getMonth() + 1)
  const year = dateObj.getFullYear()
  return `${day}/${month}/${year}`
}

export const formatEuro3Decimals = (value) => {
  if (!value) return ''
  const firstDecimal = value?.toString()?.split('.')[1]?.split('')?.findIndex((d) => d !== '0') || 0
  return value?.toFixed(Math.max(firstDecimal + 1, 3))?.replace('.', ',') + ' €'
}
