import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

export const BarChartComponent = ({
  data,
  xaxis, // se presente e valorizzato mostra l'asse delle x con la relativa etichetta
  yaxis, // se presente mostra l'asse delle y
  dataKeys,
  cartesian, // se presente mostra il piano cartesiano
  legend, // se presente mostra la legenda
  tooltipProps,
  yAxisProps
}) => {
  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <BarChart
        data={data}
      >
        {cartesian ? <CartesianGrid strokeDasharray='3 3' /> : null}
        {xaxis ? <XAxis dataKey={xaxis} /> : null}
        {yaxis ? <YAxis {...yAxisProps} /> : null}
        <Tooltip {...tooltipProps} />
        {legend ? <Legend /> : null}
        {dataKeys.map((dk) => (<Bar dataKey={dk.dataKey} key={dk.dataKey} fill={dk.color} />))}
      </BarChart>
    </ResponsiveContainer>
  )
}
