import React, { useEffect, useState } from 'react'
import { Card, ChatMessages, ChatWrapper, Flex, Spinner, Writer } from '../../Components'
import { FC, useCQuery } from '../../Services'
import { useAuth } from '../../Provider/AuthProvider'
import SettingsParameterTest from './Components/SettingsParameterTest'

export function NewChat () {
  const { data: unitsData = [] } = useCQuery(['units'])
  const { data: travellersData = [], isSuccess: isSuccessTravellers } = useCQuery(['travellers'])
  const { data: environments = [], isSuccess: isSuccessEnvironments } = useCQuery(['environments'])
  const [conversationId, setConversationId] = useState()
  const { data: conversation = {} } = useCQuery(['conversation', conversationId])
  const units = unitsData?.map(({ name }) => name)
  const [travellersOptions, setTravellersOptions] = useState([])
  const [selectedUnit, setSelectedUnit] = useState('newMessage')
  const [selectedPID, setSelectedPID] = useState()
  const [selectedFormalita, setSelectedFormalita] = useState(75)
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id: userId, username } = userObj
  const [messages, setMessages] = useState([])
  const [selectedEmail, setSelectedEmail] = useState(username === 'mbolla@siapcn.it' ? 'mbolla@siapcn.it' : 'mranocchia@siapcn.it')
  const [selectedEnvironment, setSelectedEnvironment] = useState()

  useEffect(() => {
    isSuccessEnvironments && !selectedEnvironment && setSelectedEnvironment(environments.find(({ tomcatCode }) => user?.user?.environment?.tomcatCode === tomcatCode)?.codSociety || '')
  }, [environments])

  useEffect(() => {
    if (conversation?.messages?.length !== messages?.length) {
      setMessages(conversation?.messages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))?.map(({ message, sender, createdAt }) => ({ user: sender === 'traveller' ? conversation?.traveller?.email : 'Nora', message, createdAt: new Date(createdAt) })) || [])
    }
  }, [conversation?.messages])

  useEffect(() => {
    if (isSuccessTravellers) {
      const environmentId = environments?.find(({ codSociety }) => codSociety === selectedEnvironment)?.id
      setTravellersOptions(travellersData?.filter(({ environmentId: envId }) => envId === environmentId)?.map(({ pid }) => pid))
      setSelectedPID(travellersData?.filter(({ environmentId: envId }) => envId === environmentId)?.[0]?.pid)
    }
  }, [travellersData, selectedEnvironment])

  const sendMessage = async (message, attachments) => {
    setMessages([...messages, { user: 'me', message }])
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: 'newMessage',
      attachments,
      user: userId,
      pid: selectedPID,
      tomcatCode: user?.user?.environment?.tomcatCode,
      userEmail: selectedEmail,
      operatorEmail: selectedEmail,
      userInput: message,
      levelFormalita: selectedFormalita || 75,
      isDebug: false,
      codSociety: selectedEnvironment
    })
    res?.conversationData?.conversationId !== conversationId && setConversationId(res?.conversationData?.conversationId)
    return true
  }
  if (!isSuccessTravellers) return <Flex fw fh><Spinner /> </Flex>
  return (
    <Flex fw fh js>
      <Card style={{ width: '90%' }}>
        <Flex fw row>
          <SettingsParameterTest
            selectedFormalita={selectedFormalita}
            setSelectedFormalita={setSelectedFormalita}
            units={units}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            travellersOptions={travellersOptions}
            selectedPID={selectedPID}
            setSelectedPID={setSelectedPID}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            selectedEnvironment={selectedEnvironment}
            setSelectedEnvironment={setSelectedEnvironment}
            environmentList={environments?.filter(({ tomcatCode }) => user?.user?.environment?.tomcatCode === tomcatCode)}
          />
        </Flex>
        <Flex fw style={{ marginTop: 30 }}>
          <Flex fw row>
            <ChatWrapper>
              <ChatMessages messages={messages} jsonVisualized={false} />
              <Writer sendMessage={sendMessage} />
            </ChatWrapper>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}
