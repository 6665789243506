import React from 'react'
import { Button } from '../Common/Button'
/* global Audio */

export const AudioControl = ({ url, label, style }) => {
  const [audio, setAudio] = React.useState(null)
  return (
    <>
      <Button
        icon={audio ? 'close' : 'play'}
        allowMultipleClick
        label={label}
        onClick={() => {
          if (audio) {
            audio.pause()
            return setAudio(null)
          }
          const newAudio = new Audio(url)
          setAudio(newAudio)
          newAudio.play()
        }}
        style={{ ...style }}
      />
    </>
  )
}
