import React from 'react'
import { Flex, Input, PanelText } from '../../../Components'

const listOfFormalita = [
  { label: 'poco formale', value: 30 },
  { label: 'formale (default)', value: 75 },
  { label: 'molto formale', value: 100 }
]

const listOfEmails = [
  { label: 'mranocchia@siapcn.it', value: 'mranocchia@siapcn.it' },
  { label: 'murru@siapcn.it', value: 'murru@siapcn.it' },
  { label: 'atomatis@siapcn.it', value: 'atomatis@siapcn.it' },
  { label: 'mbolla@siapcn.it', value: 'mbolla@siapcn.it' }
]

function SettingsParameterTest ({
  selectedFormalita,
  setSelectedFormalita,
  debugMode,
  units,
  selectedUnit,
  setSelectedUnit,
  travellersOptions,
  selectedPID,
  setSelectedPID,
  selectedEmail,
  setSelectedEmail,
  selectedEnvironment,
  setSelectedEnvironment,
  environmentList
}) {
  return (
    <Flex fw row wrap style={{ gap: 30 }}>
      <Flex as>
        <PanelText value='Indicare livello di formalità' />
        <Input
          id='levelFormalita' dropdown options={listOfFormalita} value={selectedFormalita} placeholder='Seleziona livello di formalità'
          onChange={({ levelFormalita }) => setSelectedFormalita(levelFormalita)}
        />
      </Flex>
      {!!debugMode && (
        <>
          <Flex js as>
            <PanelText value='Seleziona unit da eseguire' />
            <Input
              id='unit' dropdown options={units} value={selectedUnit} placeholder='Seleziona unit da eseguire'
              style={{ fontSize: 12, width: 300, textAlign: 'center' }} onChange={({ unit }) => setSelectedUnit(unit)}
            />
          </Flex>
          <Flex js as>
            <PanelText value='Seleziona Pid' />
            <Input
              id='unit' dropdown options={travellersOptions} value={selectedPID} placeholder='Seleziona PID'
              style={{ fontSize: 12, width: 300, textAlign: 'center' }} onChange={({ unit }) => setSelectedPID(unit)}
            />
          </Flex>
        </>
      )}
      <Flex js as>
        <PanelText value='Seleziona Email Redirect To Operator' />
        <Input
          id='unit' dropdown options={listOfEmails} value={selectedEmail} placeholder='Seleziona Email'
          style={{ fontSize: 12, width: 300, textAlign: 'center' }} onChange={({ unit }) => setSelectedEmail(unit)}
        />
      </Flex>
      <Flex js as>
        <PanelText value='Seleziona Environment' />
        <Input
          id='environment' dropdown options={environmentList} value={selectedEnvironment}
          optionLabel='societyName' optionValue='codSociety'
          placeholder='Seleziona Environment' style={{ fontSize: 12, width: 300, textAlign: 'center' }}
          onChange={({ environment }) => setSelectedEnvironment(environment)}
        />
      </Flex>
      {!!debugMode && (<hr style={{ width: '90%', borderTop: '1px solid black', margin: '30px 0px' }} />)}
    </Flex>

  )
}

export default SettingsParameterTest
