import React from 'react'
import { Dialog } from 'primereact/dialog'
import { cap } from '../../State'

export const Modal = ({
  children,
  noDismissable,
  visible = false,
  closable = true,
  style = {},
  header,
  footer,
  contentStyle = {},
  draggable = false,
  onHide = () => {}
}) => {
  const onHideFunction = () => closable ? onHide() : null
  const [darkMode] = cap.darkMode.useState()
  return (
    <Dialog
      className={`theme-${darkMode ? 'dark' : 'light'}`}
      contentStyle={{ ...contentStyle }}
      header={header}
      modal
      dismissableMask={!noDismissable}
      draggable={draggable}
      showHeader={!!header}
      visible={visible}
      onHide={onHideFunction}
      closable={closable}
      style={{ ...style }}
      footer={footer}
    >
      {children}
    </Dialog>
  )
}
