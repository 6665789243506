import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts'

export const PieChartComponent = ({
  data,
  colors,
  innerRadius, // raggio interno => da la forma al grafico
  outerRadius, // raggio esterno => da la forma al grafico
  paddingAngle, // distanza tra gli elementi
  legend, // se presente mostra la legenda,
  dataKey,
  onCellClick,
  tooltipProps,
  legendProps
}) => {
  return (
    <ResponsiveContainer width='100%'>
      <PieChart style={{ cursor: 'pointer' }}>
        <Tooltip
          contentStyle={{ background: 'white', borderRadius: '5px' }}
          {...tooltipProps}
        />
        {legend ? <Legend {...legendProps} /> : null}
        <Pie
          dataKey={dataKey}
          data={data}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          paddingAngle={paddingAngle}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} onClick={() => onCellClick && onCellClick(entry.name)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
