import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { Flex } from '../../../Components'
import { ChatHeader } from '../../Fiera/Components/ChatContainer'
import { ChatMessages } from '../../Fiera/Components/ChatMessages'
const cssGlass = { backdropFilter: 'blur(22px) saturate(146%)', WebkitBackdropFilter: 'blur(22px) saturate(146%)', backgroundColor: 'rgba(17, 25, 40, 0.78)', borderRadius: '12px', border: '1px solid rgba(255, 255, 255, 0.125)' }
const WIDTH_DIMENSION = 1000
const exampleConvs = [
  [
    { message: 'Ciao Nora,\ndevo andare in trasferta a Roma, parto da Milano.\nParto il 10 ottobre e torno il 12. Mi serve un aereo ed un hotel in zona Colosseo.', user: 'Me', createdAt: new Date() },
    { message: 'Ciao Luca, mi servirebbero alcune informazioni per poter preparare un preventivo per il tuo viaggio. Potresti gentilmente fornirmi gli orari di partenza e ritorno?', user: 'Nora', createdAt: new Date() },
    { message: 'Vorrei la partenza al mattino e il ritorno in tarda serata.', user: 'Me', createdAt: new Date() },
    { message: 'Ciao Luca, le quotazioni dei servizi richiesti sono pronte. Puoi trovare tutte le informazioni necessarie nel seguente link: apri.', user: 'Nora', createdAt: new Date() }
  ],
  [
    { message: 'Ciao Nora,\nHo bisogno di un preventivo per la mia prossima trasferta a Milano.\nUn volo per il 6 novembre e ritorno il 9.\nPer quanto riguarda l\'hotel avrei bisogno di stare nella zona del Nhow Milano.\nPartirò da Roma e non dalla sede aziendale di Bari.\nGrazie mille', user: 'Me', createdAt: new Date() },
    { message: 'Buongiorno sig. Rossi,\nHa delle preferenze di orario per la partenza e il ritorno?\nGrazie anticipatamente per la sua collaborazione.\n', user: 'Nora', createdAt: new Date() },
    { message: 'Buongiorno Nora\nMi va bene partire nel primo pomeriggio e per il ritorno mi va bene entro le ore 8:00\nGrazie mille', user: 'Me', createdAt: new Date() },
    { message: 'Sig. Rossi la ringrazio per le informazioni forniteci.\nMi può fornire gentilmente ancora il codice della commessa?\nAttendo sue\nNora', user: 'Nora', createdAt: new Date() },
    { message: 'Certo Nora\nCodice commessa: Q2M345.\nSaluti', user: 'Me', createdAt: new Date() },
    { message: 'Buongiorno,\nLe quotazioni per i servizi richiesti sono pronte. Per accedere alle informazioni, può visitare il seguente link: apri.\nCordialmente,\nNora', user: 'Nora', createdAt: new Date() }
  ]
]

const TIME_BETWEEN_MESSAGES = 4000
const TIME_BETWEEN_CONVERSATIONS = 15000

export default function ChatBrochure () {
  const { width = 0 } = useWindowSize()
  const [messages, setMessages] = useState([])

  const renderChatFn = async () => {
    for (const conversation of exampleConvs) {
      setMessages([])
      await new Promise((resolve) => setTimeout(resolve, 100))
      for (const message of conversation) {
        setMessages((oldMessages) => [...oldMessages, message])
        await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_MESSAGES))
      }
      await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_CONVERSATIONS))
    }
  }

  useEffect(() => {
    renderChatFn()
    setInterval(() => {
      renderChatFn()
    }, TIME_BETWEEN_MESSAGES * exampleConvs.reduce((acc, curr) => acc + curr.length, 0) + (TIME_BETWEEN_CONVERSATIONS * exampleConvs?.length))
  }, [exampleConvs])

  return width > WIDTH_DIMENSION
    ? (
      <Flex
        fh as js
        style={{
          margin: 10,
          width: '40%',
          overflow: 'hidden',
          borderRadius: 30,
          ...cssGlass
        }}
      >
        <ChatHeader />
        <ChatMessages messages={messages} brochure />
      </Flex>)
    : null
}
