import { Flex, Text } from '../../../Components'
import nora from '../nora.png'
import account from '../account.png'
import { motion } from 'framer-motion'
import { useEffect } from 'react'

const cssGlassMessage = {
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(14.1px)',
  WebkitBackdropFilter: 'blur(14.1px)'
}
const dateOptionsShort = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }
const dateOptionsLong = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }
const fontSmall = { color: '#ECECEC', fontSize: 12, fontWeight: 300 }

export const ChatMessages = ({ messages, brochure }) => {
  useEffect(() => {
    document.getElementById('chatMessages').scrollTo({ top: 100000, behavior: 'smooth' })
  }, [messages])
  return (
    <Flex id='chatMessages' fw js style={{ width: '100%', overflowY: 'auto', flex: 1, padding: '0px 20px', paddingBottom: 20 }}>
      {messages?.length > 0 &&
        <Text value={`Conversazione iniziata il ${messages[0]?.createdAt?.toLocaleString('it-IT', dateOptionsLong)} - Richiesta viaggio`} style={{ ...fontSmall, marginTop: 20, marginBottom: 10 }} />}
      {messages.map((message, index) => <ChatMessage key={index} message={message} brochure={brochure} />)}
    </Flex>
  )
}

const ChatMessage = ({ message, brochure }) => {
  const { message: messageText = '', user, createdAt } = message

  return (
    <Flex fw js>
      <Flex fw ae={user !== 'Nora'} as={user === 'Nora'}>
        <motion.div
          initial={{ opacity: 0, x: user !== 'Nora' ? 100 : -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7 }}
          style={{ width: '60%' }}
        >

          <Flex
            as js row style={{
              borderRadius: 10,
              ...cssGlassMessage,
              background: brochure ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.39)',
              width: '100%',
              padding: 10,
              maxWidth: '100%',
              marginTop: 15
            }}
          >
            {user === 'Nora' && <img src={nora} style={{ width: 50, borderRadius: '50%' }} />}
            <Flex as style={{ width: 'calc(100% - 50px)', ...(user === 'Nora' ? { paddingLeft: 10 } : { paddingRight: 10 }) }}>
              <Flex fw as row jb>
                <Text value={user} style={{ ...fontSmall }} />
                <Text value={createdAt?.toLocaleString('it-IT', dateOptionsShort)} style={{ ...fontSmall }} />
              </Flex>
              <hr style={{ width: '100%', margin: '5px 0' }} />
              <Flex fw as>
                <Text value='Oggetto: Richiesta viaggio' style={{ ...fontSmall }} />
              </Flex>
              <hr style={{ width: '100%', margin: '5px 0' }} />
              <Flex as>
                {renderMessage(messageText)}
              </Flex>
            </Flex>
            {user !== 'Nora' && <img src={account} style={{ width: 50 }} />}
          </Flex>
        </motion.div>
      </Flex>
    </Flex>
  )
}

const renderMessage = (messageText) => {
  const urlRegex = /(https?:\/\/[^\s]+[^.\s])/g
  const splitted = messageText.split(urlRegex)
  return (
    <Text
      value={splitted.map((text, index) =>
        text?.match(urlRegex)
          ? (
            <span key={index} style={{ color: '#FFF' }}>
              <a href={text} target='_blank' style={{ color: '#FFF' }} rel='noreferrer'>
                apri
              </a>
            </span>
            )
          : (
            <span key={index} style={{ color: '#FFF', whiteSpace: 'pre-wrap' }}>
              {text}
            </span>)
      )}
    />
  )
}
