import React from 'react'
import { Flex, PanelChip, PanelText } from '../../../Components'
import { Divider } from 'primereact/divider'

const StepChooseFormalita = ({ selectedTone: [selectedTone, setSelectedTone] }) => (
  <Flex wrap fw>
    <Flex js as fw>
      <PanelText value='Tono email:' header />
      <Flex row as wrap style={{ padding: '9px 0px', gap: 15 }}>
        <PanelChip value='Informale' selected={selectedTone === 'informale'} onClick={() => setSelectedTone('informale')} />
        <PanelChip value='Formale' selected={selectedTone === 'formale'} onClick={() => setSelectedTone('formale')} />
        <PanelChip value='Molto formale' selected={selectedTone === 'molto'} onClick={() => setSelectedTone('molto')} />
      </Flex>
    </Flex>
    <Divider />
  </Flex>
)

export default StepChooseFormalita
