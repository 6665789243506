import React from 'react'
import { Flex, PanelText } from '../../../Components'
import { Checkbox } from 'primereact/checkbox'

const Controlli = ({
  checkControlliIA: [checkControlliIA, setCheckControlliIA],
  checkPubblicaLoStesso: [checkPubblicaLoStesso, setCheckPubblicaLoStesso],
  checkControllaPreventivi: [checkControllaPreventivi, setCheckControllaPreventivi]
}) => (
  <Flex fw as js wrap style={{ gap: 20 }}>
    <Flex row js fw wrap style={{ gap: 15 }}>
      <Checkbox checked={checkControlliIA} onChange={e => setCheckControlliIA(e.checked)} />
      <Flex as fh>
        <PanelText value='Controlla dati IA' header />
        <PanelText value={'Una volta conclusa la fase di raccolta dati, l\'operatore deve controllare e confermare i dati inseriti dall\'IA nella sezione "Form Operatore"'} />
      </Flex>
    </Flex>
    <Flex row js fw wrap style={{ gap: 15 }}>
      <Checkbox checked={checkPubblicaLoStesso} onChange={e => setCheckPubblicaLoStesso(e.checked)} />
      <Flex as fh>
        <PanelText value='Pubblica lo stesso' header />
        <PanelText value='Se non vengono trovati risultati in uno dei servizi richiesti, i preventivi vengono comunque pubblicati' />
      </Flex>
    </Flex>
    <Flex row js fw wrap style={{ gap: 15 }}>
      <Checkbox checked={checkControllaPreventivi} onChange={e => setCheckControllaPreventivi(e.checked)} />
      <Flex as fh>
        <PanelText value='Controlla preventivi' header />
        <PanelText value={'Se attivo, i preventivi non vengono inviati al viaggiatore, ma vengono creati nello stato "in lavorazione" per essere visionati'} />
      </Flex>
    </Flex>
  </Flex>
)

export default Controlli
