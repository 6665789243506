import { FC } from '../../Services'
import quotesType from '../../State/quotesType'
export const alert = (severity, summary, detail) => window.growl.show({ severity, summary, detail })
export const services = quotesType

export const prepareOptionsFilters = (type) => services.find(obj => obj.id === type).filters

/**
   * This function processes an array `servicePriority` by mapping each offerType to a promise that
   * retrieves service information associated with that offerType. It returns an array of objects,
   * maintaining the original order of `servicePriority`, where each object has a key as `offerType`
   * and value as an array of codes obtained from the service response after splitting it by commas.
   *
   * @async
   * @function prepareServicePriority
   * @returns {Promise<Array<Object>>} A promise that resolves to an array of objects, each containing
   *                                   an offerType as the key and associated service codes as the value.
   * @throws Will log an error to the console if an error occurs.
   */
export const prepareServicePriority = async (servicePriority) => {
  try {
    // Restituisce l'array di oggetti mantenendo l'ordine originale di servicePriority.
    return await Promise.all(
      servicePriority.map(async (service) => {
        const result = await FC.service('info').get('getTrainOffer', { query: { offerType: service.id } })
        return { ...service, codes: result.split(',') }
      })
    )
  } catch (error) {
    throw new Error(error)
  }
}
