import { useState } from 'react'
import { cityOptions, treatmentOptions } from './Functions'
import { FC } from '../../Services'
import { Button, Flex, Input, Spinner, Text } from '../../Components'
import { CardResult } from './CardResult'
import { alert } from '../AdminCoefficient/Functions'
import { InputNumber } from 'primereact/inputnumber'
import { useAuth } from '../../Provider/AuthProvider'

const checkDateFormat = (date) => (/^\d{1,2}-\d{1,2}-\d{4}$/).test(date)

export const HotelServices = (type) => {
  const [checkInDate, setCheckInDate] = useState('15-01-2024')
  const [checkOutDate, setCheckOutDate] = useState('20-01-2024')
  const { user: { user: { environment: { codSociety } } } } = useAuth()
  const [idCommunity, setIdCommunity] = useState(codSociety === '90'
    ? '26'
    : process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? '87' : '1')
  const [city, setCity] = useState(cityOptions[0].value)
  const [treatment, setTreatment] = useState(' ')
  const [results, setResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [searchType, setSearchType] = useState('city')
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)

  const sendRequest = async () => {
    setResults([])
    if (!city) return alert('error', 'Città non valida')
    if (!checkDateFormat(checkInDate) || !checkDateFormat(checkOutDate)) return alert('error', 'Formato date non valido')
    setIsSearching(true)
    const res = await FC.service('info').create({
      action: 'previewDynResults',
      serviceType: 'Hotel',
      city: searchType === 'city' ? city : { placeDetail: { geometry: { location: { lat, lng } } } },
      checkInDate,
      checkOutDate,
      idCommunity,
      treatment: treatment === ' ' ? '' : treatment
    })
    setResults(res)
    setIsSearching(false)
  }

  return (
    <>
      <Text value='Ricerca HOTEL' bold title size={24} style={{ marginBottom: 30 }} />
      <Flex as row wrap style={{ gap: 20 }}>
        <span className='p-float-label'>
          <Input id='idCommunity' value={idCommunity} onChange={({ idCommunity }) => setIdCommunity(idCommunity)} style={{ width: 200 }} onEnter={sendRequest} />
          <label htmlFor='idCommunity' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Id Community</label>
        </span>
        <span className='p-float-label'>
          <Input id='searchType' dropdown options={['city', 'coordinates']} value={searchType} onChange={({ searchType }) => setSearchType(searchType)} style={{ width: 200 }} onEnter={sendRequest} />
          <label htmlFor='searchType' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Tipo Ricerca</label>
        </span>
        {searchType === 'city'
          ? (
            <Flex>
              <span className='p-float-label'>
                <Input id='city' dropdown options={cityOptions} value={city} onChange={({ city }) => setCity(city)} style={{ width: 200 }} onEnter={sendRequest} />
                <label htmlFor='city' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Città</label>
              </span>
              <Text value={'Lat: ' + city.placeDetail.geometry.location.lat} />
              <Text value={'Lng: ' + city.placeDetail.geometry.location.lng} />
            </Flex>)
          : (
            <Flex row>
              <span className='p-float-label'>
                <InputNumber inputId='lat' value={lat} onValueChange={({ value }) => setLat(value)} style={{ marginRight: 10, marginLeft: 10 }} inputStyle={{ borderRadius: 20 }} maxFractionDigits={10} />
                <label htmlFor='lat' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Latitudine</label>
              </span>
              <span className='p-float-label'>
                <InputNumber inputId='lng' value={lng} onValueChange={({ value }) => setLng(value)} style={{ marginRight: 10, marginLeft: 10 }} inputStyle={{ borderRadius: 20 }} maxFractionDigits={10} />
                <label htmlFor='lng' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Longitudine</label>
              </span>
            </Flex>)}
        <span className='p-float-label'>
          <Input id='treatment' dropdown options={treatmentOptions} value={treatment} onChange={({ treatment }) => setTreatment(treatment)} style={{ width: 200 }} onEnter={sendRequest} />
          <label htmlFor='treatment' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Trattamento</label>
        </span>
        <span className='p-float-label'>
          <Input value={checkInDate} onChange={({ checkInDate }) => setCheckInDate(checkInDate)} id='checkInDate' onEnter={sendRequest} />
          <label htmlFor='checkInDate' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Data Check In</label>
        </span>
        <span className='p-float-label'>
          <Input value={checkOutDate} onChange={({ checkOutDate }) => setCheckOutDate(checkOutDate)} id='checkOutDate' onEnter={sendRequest} />
          <label htmlFor='checkOutDate' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Data Check Out</label>
        </span>
      </Flex>
      {isSearching
        ? <Spinner />
        : <Button label='Cerca' style={{ marginTop: 20 }} icon='lens' onClick={sendRequest} />}
      <Flex row wrap style={{ marginTop: 10, marginBottom: 30, minHeight: 300, alignItems: 'stretch', gap: 20 }}>
        {results.map((result, index) => <CardResult key={index} result={result} type={result?.serviceType} />)}
      </Flex>
    </>
  )
}
