import { useState } from 'react'
import { FC } from '../../Services'
import { Button, Flex, Input, Spinner, Text } from '../../Components'
import { CardResult } from './CardResult'
import { alert } from '../AdminCoefficient/Functions'
import { AutoComplete } from 'primereact/autocomplete'
import _ from 'lodash'
import { useAuth } from '../../Provider/AuthProvider'

const checkDateFormat = (date) => (/^\d{1,2}-\d{1,2}-\d{4}$/).test(date)
const checkHourFormat = (hour) => (/^\d{1,2}:\d{1,2}$/).test(hour)

export const TrainServices = (type) => {
  const [departureDate, setDepartureDate] = useState('15-01-2024')
  const [departureHour, setDepartureHour] = useState('10:00')
  const { user: { user: { environment: { codSociety } } } } = useAuth()
  const [idCommunity, setIdCommunity] = useState(codSociety === '90'
    ? '26'
    : process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ? '87' : '1')
  const [departureStation, setDepartureStation] = useState()
  const [suggestionsDepartureStation, setSuggestionsDepartureStation] = useState([])
  const [arrivalStation, setArrivalStation] = useState()
  const [suggestionsArrivalStation, setSuggestionsArrivalStation] = useState([])
  const [offerType, setOfferType] = useState('')
  const [results, setResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  const debounceSearch = _.debounce(async (setter, query) => {
    if (query?.length < 3) return setter([])
    const res = await FC.service('info').get('getTrainStation', { query: { inputTrain: query } })
    setter(res)
  }, 450)

  const sendRequest = async () => {
    setResults([])
    if (!departureStation) return alert('error', 'Stazione di partenza non valida')
    if (!arrivalStation) return alert('error', 'Stazione di arrivo non valida')
    if (!checkDateFormat(departureDate) || !checkHourFormat(departureHour)) return alert('error', 'Formato date/ora non valido')
    setIsSearching(true)
    const res = await FC.service('info').create({ action: 'previewDynResults', serviceType: 'Train', departureDate, departureHour, departureStation, arrivalStation, trainOffer: offerType, idCommunity })
    setResults(res)
    setIsSearching(false)
  }

  return (
    <>
      <Text value='Ricerca TRENI' bold title size={24} style={{ marginBottom: 30 }} />
      <Flex row wrap style={{ gap: 20 }}>
        <span className='p-float-label'>
          <Input value={idCommunity} onChange={({ idCommunity }) => setIdCommunity(idCommunity)} id='idCommunity' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='idCommunity' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Id Community</label>
        </span>
        <span className='p-float-label'>
          <AutoComplete
            id='departureStation' onSelect={({ value: { stationName } }) => setDepartureStation(stationName)} field='stationName'
            value={departureStation} suggestions={suggestionsDepartureStation} completeMethod={({ query }) => debounceSearch(setSuggestionsDepartureStation, query)} onChange={(e) => setDepartureStation(e.value)}
            inputStyle={{ fontFamily: 'Lexend', width: 300, borderRadius: 20 }} forceSelection
          />
          <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>Stazione Partenza</label>
        </span>
        <span className='p-float-label'>
          <AutoComplete
            id='arrivalStation' onSelect={({ value: { stationName } }) => setArrivalStation(stationName)} field='stationName'
            value={arrivalStation} suggestions={suggestionsArrivalStation} completeMethod={({ query }) => debounceSearch(setSuggestionsArrivalStation, query)} onChange={(e) => setArrivalStation(e.value)}
            inputStyle={{ fontFamily: 'Lexend', width: 300, borderRadius: 20 }} forceSelection
          />
          <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>Stazione Arrivo</label>
        </span>
        <span className='p-float-label'>
          <Input
            id='offerType' dropdown options={['Executive', 'Business', 'Premium', 'Standard', '']} value={offerType}
            onChange={({ offerType }) => setOfferType(offerType)}
            style={{ width: 200 }} onEnter={sendRequest}
          />
          <label htmlFor='offerType' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Classe</label>
        </span>
        <span className='p-float-label'>
          <Input value={departureDate} onChange={({ departureDate }) => setDepartureDate(departureDate)} id='departureDate' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='departureDate' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Data Partenza</label>
        </span>
        <span className='p-float-label'>
          <Input value={departureHour} onChange={({ departureHour }) => setDepartureHour(departureHour)} id='departureHour' onEnter={sendRequest} style={{ width: 200 }} />
          <label htmlFor='departureHour' style={{ fontFamily: 'Lexend', fontSize: 14 }}>Ora Partenza</label>
        </span>
      </Flex>
      {isSearching
        ? <Spinner />
        : <Button label='Cerca' style={{ marginTop: 20 }} icon='lens' onClick={sendRequest} />}
      <Flex row wrap style={{ marginTop: 10, marginBottom: 30, minHeight: 300, gap: 20 }}>
        {results.map((result, index) => <CardResult key={index} result={result} type={result?.serviceType} />)}
      </Flex>
    </>
  )
}
