import { Flex, Text } from '../../../Components'
import nora from '../nora.png'

export const ChatContainer = ({ children }) => (
  <Flex
    jb
    js style={{
      width: '50%',
      height: 'calc(100vh - 155px)',
      overflow: 'hidden',
      borderRadius: 30,
      border: '1px solid #ECECEC',
      background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.11) 2.43%, rgba(255, 255, 255, 0.05) 38.66%, rgba(255, 255, 255, 0.11) 74.9%)',
      backdropFilter: 'blur(40px)'
    }}
  >
    {children}
  </Flex>
)

export const ChatHeader = () => (
  <Flex style={{ background: 'rgba(13, 13, 13, 0.56)', borderTopLeftRadius: 29, borderTopRightRadius: 29, height: 100, padding: 20 }} row fw as js>
    <img src={nora} style={{ borderRadius: '50%', maxWidth: '100%', maxHeight: '100%' }} />
    <Flex style={{ marginLeft: 20 }}>
      <Text value='Nora' center size={35} color='white' />
    </Flex>
  </Flex>
)
