import React, { useEffect, useRef, useState } from 'react'
import { FC, useCQuery } from '../../Services'
import { useAuth } from '../../Provider/AuthProvider'
import siapLogo from './siaplogo.png'
import noraLogo from './noraLogo.png'
import { ChatContainer, ChatHeader } from './Components/ChatContainer'
import { Background } from './Components/Background'
import { ChatMessages } from './Components/ChatMessages'
import { Writer } from './Components/Writer'
import { Flex } from '../../Components'
import { nanoid } from 'nanoid'
import { examplesMessages } from './Functions'

const defaultParams = {
  codSociety: window?._env_?.ENVIRONMENT === 'production' ? 'AA' : 'RO',
  pid: window?._env_?.ENVIRONMENT === 'production' ? 'RNCMTT96A06B001' : 'RNCMTT21R20B001'
}

const TIME_BETWEEN_MESSAGES = 4000
const TIME_BETWEEN_CONVERSATIONS = 15000

export function ChatFiera () {
  const [messages, setMessages] = useState([])
  const firstMessageSent = useRef(false)
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id: userId } = userObj
  const [idConv] = useState(nanoid())
  const [conversationId, setConversationId] = useState()
  const { data: conversation = {} } = useCQuery(['conversation', conversationId])

  const renderChatFn = async () => {
    for (const conversation of examplesMessages) {
      !firstMessageSent.current && setMessages([])
      await new Promise((resolve) => setTimeout(resolve, 100))
      for (const message of conversation) {
        !firstMessageSent.current && setMessages((oldMessages) => [...oldMessages, message])
        await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_MESSAGES))
      }
      await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_CONVERSATIONS))
    }
  }

  useEffect(() => {
    renderChatFn()
    const int = setInterval(async () => {
      if (firstMessageSent.current) {
        clearInterval(int)
        return
      }
      renderChatFn()
    }, TIME_BETWEEN_MESSAGES * examplesMessages.reduce((acc, curr) => acc + curr.length, 0) + (TIME_BETWEEN_CONVERSATIONS * examplesMessages?.length))
  }, [examplesMessages])

  useEffect(() => {
    if (conversation?.messages?.length !== messages?.length) {
      setMessages(conversation?.messages?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))?.map(({ message, sender }) => ({ user: sender === 'traveller' ? 'Me' : 'Nora', message })) || [])
    }
  }, [conversation?.messages])

  const sendMessage = async (message) => {
    const oldMessages = !firstMessageSent?.current ? [] : [...messages]
    firstMessageSent.current = true
    const messageObj = { message, user: 'me', createdAt: new Date() }
    setMessages([...oldMessages, messageObj])
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: 'newMessage',
      user: userId,
      tomcatCode: 'OUTS',
      userEmail: 'mranocchia@siapcn.it',
      operatorEmail: 'mranocchia@siapcn.it',
      userInput: message,
      levelFormalita: 75,
      isDebug: false,
      idConvFiera: idConv,
      ...defaultParams
    })
    res?.conversationData?.conversationId !== conversationId && setConversationId(res?.conversationData?.conversationId)
    return true
  }

  return (
    <Background>
      <Flex fw row jb style={{ height: 70 }}>
        <img src={siapLogo} style={{ height: '100%' }} />
        <img src={noraLogo} style={{ height: '100%' }} />
      </Flex>
      <hr style={{ width: '100%', margin: '20px 0' }} />
      <Flex row fw je>
        <ChatContainer>
          <ChatHeader />
          <ChatMessages messages={messages} />
          <Writer sendMessage={sendMessage} />
        </ChatContainer>
      </Flex>
    </Background>
  )
}
