import React from 'react'
import { Flex } from '../../../Components'
import { Divider } from 'primereact/divider'
import { services } from '../Functions'
import { ServiceElement } from './ServiceElement'

const ServiceSections = (props) => (
  <Flex js as fw>
    {services.map(({ id, ...service }, index) => (
      <Flex key={index} fw>
        <Flex js as row fw>
          <ServiceElement coefficient={props?.[id]?.coefficient} service={service} />
        </Flex>
        {index < services?.length - 1 && <Divider />}
      </Flex>)
    )}
  </Flex>
)

export default ServiceSections
