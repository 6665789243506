import React from 'react'
import { BarChartComponent, Card, Flex, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { formatTime } from './Functions'
const dataKeys = [{ dataKey: 'executionTime', color: 'blue' }]

export const ProcessSteps = ({ dates }) => {
  const { data: { processStepsStats = [] } = {}, isSuccess } = useCQuery(['getStats', ...dates])

  if (!isSuccess) return null
  return (
    <Card style={{ width: '100%' }}>
      <Text title bold value='RESOCONTO UNIT - TEMPO MEDIO' style={{ marginRight: 20, marginBottom: 10, width: '100%' }} size={20} center />
      <Flex fw style={{ height: 400 }} as js wrap>
        {!!processStepsStats?.length && (
          <BarChartComponent
            yaxis data={processStepsStats} dataKeys={dataKeys} xaxis='unit' tooltipProps={{
              content: ({ payload: [{ payload: { unit = '', executionTime } = {} } = {}] = [] }) => (
                <div>
                  <Text value={`${unit}`} />
                  <Text value={`Tempo di esecuzione: ${formatTime(executionTime)}`} />
                </div>
              )
            }}
            yAxisProps={{
              type: 'number',
              dataKey: 'executionTime',
              domain: [0, 'dataMax + 10'],
              tickFormatter: (val) => formatTime(val, true)
            }}
          />)}
      </Flex>
    </Card>
  )
}
