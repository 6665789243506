import React from 'react'

export const PanelText = ({ style, value, header }) => (
  <div
    style={{
      fontWeight: header ? '500' : '400',
      fontFamily: 'Lexend',
      fontSize: header ? 18 : 15,
      fontStyle: 'normal',
      ...style
    }}
    className='textContainer'
  >
    {value}
  </div>
)
