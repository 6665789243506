import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Flex, Spinner, Text, ProgressBarComponent, BarChartComponent, LineChartComponent, PieChartComponent } from '../../../Components'
import { useCQuery } from '../../../Services'
import React, { Fragment, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { STATE_ENUM } from '../../Gigi/GigiList/Functions'
import { formatTime } from '../Dashboard/Functions'
import { Pricing } from './Pricing'

const countStepStates = (processSteps) => {
  const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
  for (const item of processSteps) {
    if (item.stepState in counts) {
      counts[item.stepState]++
    }
  }
  return counts
}

const calculateExecutionTimeAvg = (processes) => {
  let sumExecutionTimes = 0
  let count = 0

  for (let i = 0; i < processes.length; i++) {
    const { executionTime } = processes[i]
    if (executionTime) {
      sumExecutionTimes += executionTime
      count++
    }
  }

  const avgExecutionTimeMs = count ? sumExecutionTimes / count : 0

  if (avgExecutionTimeMs < 60000) {
    const avgExecutionTimeSec = Math.floor(avgExecutionTimeMs / 1000)
    return `${avgExecutionTimeSec} secondi`
  } else {
    const avgExecutionTimeMin = Math.floor(avgExecutionTimeMs / 60000)
    const remainingSeconds = ((avgExecutionTimeMs % 60000) / 1000).toFixed(2)
    return `${avgExecutionTimeMin} minuti e ${remainingSeconds} secondi`
  }
}

const COLORS = ['red', 'orange']
const timeDataKeys = [{ dataKey: 'Time', color: 'red' }]

export function ProcessStepsStats () {
  const { processId } = useParams()
  const { data: processSteps = [], isSuccess } = useCQuery(['processSteps', processId])
  const navigate = useNavigate()
  const [stepStateCounts, setStepStateCounts] = useState([])
  const [executionTimeAvg, setExecutionTimeAvg] = useState('')
  const [errorProcessSteps, setErrorProcessSteps] = useState([])
  const [dataExecutionTimes, setDataExecutionTimes] = useState([])

  useEffect(() => {
    if (isSuccess) {
      const stepStateCounts = countStepStates(processSteps)
      const executionTimeAvg = calculateExecutionTimeAvg(processSteps)
      const errorProcessSteps = processSteps.filter((item) => item.stepState === 5)
      const dataExecutionTimes = processSteps.map((step) => ({ name: step.unit, Time: step.executionTime }))
      setStepStateCounts(stepStateCounts)
      setExecutionTimeAvg(executionTimeAvg)
      setErrorProcessSteps(errorProcessSteps)
      setDataExecutionTimes(dataExecutionTimes)
    }
  }, [processSteps])

  if (!isSuccess) return <Spinner />

  const dataPieChart = [{ name: 'Errore', value: errorProcessSteps.length }]

  const conversationId = processSteps[0].initialState.conversationData.conversationId

  return (
    <>
      <Flex>
        <Button label='Torna indietro' icon='left' onClick={() => navigate('/conversations/' + conversationId)} />
      </Flex>
      <Flex>
        <Flex fw as js row style={{ marginTop: 10 }}>
          <Flex js fh style={{ width: '50%' }}>
            <Card title='Stato process steps' style={{ width: '100%' }}>
              {Object.entries(stepStateCounts)
                .map(([key, value]) => (
                  <Flex key={key} row js width='100%' style={{ justifyContent: 'space-between', marginTop: 20 }}>
                    <Text value={STATE_ENUM[key - 1]?.label} style={{ width: 150 }} />
                    <Text bold value={value + '/' + processSteps.length} style={{ width: 60 }} />
                    <ProgressBarComponent value={value} total={processSteps.length} color={STATE_ENUM[key - 1]?.color} />
                  </Flex>
                ))}
            </Card>
            <Card title='Riepilogo Costi' style={{ width: '100%', marginTop: 20 }}>
              <Pricing />
            </Card>
          </Flex>
          <Flex js ae fh style={{ width: '50%' }}>
            <Card title={'Tempi - media: ' + executionTimeAvg} style={{ width: '98%' }}>
              <Flex style={{ marginTop: 10 }}>
                {processSteps.map((process, id) => <ProcessView key={id} process={process} />)}
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Flex fw fh as js row style={{ marginTop: 30 }}>
          <Flex js ae fh style={{ width: '50%', height: '100%' }}>
            <Card title='Errori' style={{ width: '100%' }}>
              <Flex style={{ height: 400, marginTop: 15 }}>
                <PieChartComponent legend data={dataPieChart} dataKey='value' colors={COLORS} innerRadius='0%' outerRadius='100%' paddingAngle='0' />
              </Flex>
            </Card>
          </Flex>
          <Flex js ae fh style={{ width: '50%', height: '100%' }}>
            <Card title='Process Steps in errore' style={{ width: '98%' }}>
              {errorProcessSteps.length
                ? errorProcessSteps.map((error) => (
                  <div key={error.unit} style={{ marginTop: 10 }}>
                    <Text bold value={error.unit} key={error.id} />
                    <ReactJson
                      name='Error' key={error.id} onAdd={false} collapsed
                      src={error.logStep} displayObjectSize={false}
                      displayDataTypes={false} onEdit={false} onDelete={false}
                    />
                  </div>
                ))
                : (<Text value='Nessun process step in errore.' />)}
            </Card>
          </Flex>
        </Flex>
        <Flex fw fh as js row style={{ marginTop: 30 }}>
          <Flex js ae fh style={{ width: '50%', height: '100%' }}>
            <Card title='Linechart' style={{ width: '98%' }}>
              <Flex style={{ height: 400, marginTop: 15 }}>
                <LineChartComponent
                  data={dataExecutionTimes} dataKeys={timeDataKeys} xaxis='name' yaxis cartesian
                  tooltipProps={{
                    content: ({ payload: [{ payload: { name = '', Time } = {} } = {}] = [] }) => (
                      <div>
                        <Text value={`${name}`} />
                        <Text value={`Tempo di esecuzione: ${formatTime(Time / 1000)}`} />
                      </div>
                    )
                  }}
                  yAxisProps={{
                    type: 'number',
                    dataKey: 'Time',
                    domain: [0, 'dataMax + 20000'],
                    tickFormatter: (value) => formatTime(value / 1000, true)
                  }}
                />
              </Flex>
            </Card>
          </Flex>
          <Flex js ae fh style={{ width: '50%', height: '100%' }}>
            <Card title='BarChart' style={{ width: '98%' }}>
              <Flex style={{ height: 400, marginTop: 15 }}>
                <BarChartComponent
                  data={dataExecutionTimes} dataKeys={timeDataKeys} xaxis='name' yaxis
                  tooltipProps={{
                    content: ({ payload: [{ payload: { name = '', Time } = {} } = {}] = [] }) => (
                      <div>
                        <Text value={`${name}`} />
                        <Text value={`Tempo di esecuzione: ${formatTime(Time / 1000)}`} />
                      </div>
                    )
                  }}
                  yAxisProps={{
                    type: 'number',
                    dataKey: 'Time',
                    domain: [0, 'dataMax + 20000'],
                    tickFormatter: (value) => formatTime(value / 1000, true)
                  }}
                />
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

const ProcessView = ({ process: { unit, id, startTime, endTime, stepState, executionTime, finalState, logStep } }) => (
  <Flex fw key={id} as>
    <Flex fw row as js style={{ padding: '6px 0px' }}>
      <Flex row width='100%'>
        <Flex width='30%'>
          <Text value={STATE_ENUM[stepState - 1]?.label} color={STATE_ENUM[stepState - 1]?.color} />
        </Flex>
        <Flex width='20%'>
          <Text value={executionTime ? `${formatTime(executionTime / 1000)}` : 'Non terminato'} />
        </Flex>
        <Flex width='50%'>
          <Text value={unit} />
        </Flex>
      </Flex>
    </Flex>
  </Flex>
)
