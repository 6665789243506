import { useState } from 'react'
import { Button, Flex, Spinner } from '../../../Components'

export const Writer = ({ sendMessage = false }) => {
  const [currentMessage, setCurrentMessage] = useState('')
  const [sending, setSending] = useState(false)

  const onSend = async () => {
    if (!currentMessage && !currentMessage.length) return // controllo che non sia vuoto il message, al fine di non fare invio inutilmente.
    setSending(true)
    setCurrentMessage('')
    sendMessage(currentMessage)
    setSending(false)
  }

  return (
    <Flex style={{ background: 'rgba(13, 13, 13, 0.56)', borderBottomLeftRadius: 29, borderBottomRightRadius: 29, height: 70 }} row fw js>
      <Composer update={setCurrentMessage} send={onSend} currentMessage={currentMessage} sending={sending} />
    </Flex>
  )
}

const Composer = ({ update, send, currentMessage, sending }) => {
  const handleKeypress = (send) => (e) => { if (e.code === 'Enter' && !e.shiftKey) { e.preventDefault(); send() } }

  return (
    <Flex fw row>
      <textarea
        placeholder='Scrivi un messaggio...'
        id='textAreaFiera'
        style={{
          padding: 10,
          paddingLeft: 20,
          fontSize: 16,
          resize: 'none',
          paddingTop: 12,
          height: 70,
          border: 'none',
          background: 'rgba(13,13,13,0)',
          width: '100%',
          outline: 'none',
          fontFamily: 'Lato,Source Sans Pro'
        }}
        value={currentMessage}
        autoFocus
        onChange={(e) => update(e.target.value)}
        onKeyPress={handleKeypress(send)}
      />
      {sending
        ? <Flex style={{ marginLeft: 10, height: 44, width: 44 }}> <Spinner /></Flex>
        : <Button icon='send' iconSize={22} onClick={send} style={{ margin: 10, height: 40, width: 40, borderRadius: '50%' }} />}
    </Flex>
  )
}
