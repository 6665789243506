import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'
import { saveUser, useUser } from './Functions'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  username: { label: 'Username' },
  password: { label: 'Password' },
  environmentId: { label: 'Id Environment' },
  role: { label: 'Ruolo' }
}

export function UserEditor () {
  const { userId } = useParams()
  const navigate = useNavigate()
  const [user, setUser, isSuccess] = useUser(userId)
  const { id = '' } = user || {}

  const save = async () => {
    const res = await saveUser(user)
    res?.id && navigate('/admin/users/list')
    return res
  }

  if (!isSuccess || !user) return <Spinner />

  const isNew = id === 'new'

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Utente`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {Object.keys(fields).map((key) =>
          <Input key={key} value={user[key]} onChange={({ [key]: value }) => setUser(key, value)} label={fields[key]?.label} id={key} style={{ ...defaultStyle }} />
        )}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/admin/users/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
