import React, { useState } from 'react'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import { Flex } from '../../Common/Flex'
import { Button } from '../../Common/Button'

const defaultJson = {
  userInput: 'Ciao devo andare a Roma il 26 agosto.\n Parto da Milano\n Ho bisogno di preventivi un treno andata e ritorno'
}

export const DebugWriter = ({ sendMessage }) => {
  const [jsonToSend, setJsonToSend] = useState(defaultJson)
  return (
    <Flex fw row style={{ backgroundColor: 'rgb(30, 30, 30)' }}>
      <JSONInput
        id='jsonToSend'
        placeholder={jsonToSend}
        locale={locale}
        waitAfterKeyPress={1000}
        height='200px'
        width='100%'
        onChange={({ jsObject }) => jsObject && setJsonToSend(jsObject)}
      />
      <Button
        icon='send' iconSize={25} onClick={() => sendMessage(jsonToSend)}
        style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 20, margin: 10 }}
      />
    </Flex>
  )
}
