import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { useState } from 'react'
import { getColorDiff } from './Functions'
import { formattingTime } from '../../../../Components/Common/Functions'

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
const fontStyle = { fontFamily: 'Lexend', fontSize: 14 }

export const FloatInput = ({ value: [value, setValue], label, id, type, options, error, autocompleteField = 'label', onSelect, readOnly, minDate, diffStatus }) => {
  const [suggestions, setSuggestions] = useState([])

  const search = (event) => {
    if (!event.query.trim().length) setSuggestions([...options])
    else setSuggestions(options.filter(({ [autocompleteField]: label }) => label.toLowerCase().startsWith(event.query.toLowerCase())))
  }

  const renderCorrectInput = () => {
    switch (type) {
      case 'date':
        return (
          <Calendar
            disabled={readOnly} value={value && new Date(value.split('/')[2], value.split('/')[1] - 1, value.split('/')[0])}
            onChange={(e) => setValue(e.value?.toLocaleDateString('it', dateOptions))} dateFormat='dd/mm/yy'
            id={id} className={error ? 'p-invalid' : ''} inputStyle={{ ...fontStyle, color: getColorDiff(diffStatus, error) }}
            style={{ width: '100%' }} {...minDate && { minDate }}
          />
        )
      case 'dropdown':
        return (
          <Dropdown
            style={{ width: '100%' }} id={id} className={[error ? 'p-invalid' : '', getColorDiff(diffStatus, error)].join(' ')}
            disabled={readOnly} value={value} onChange={(e) => !readOnly && setValue(e.value)} options={options}
          />
        )
      case 'time':
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }}
            id={id} onChange={(e) => setValue(e.target.value)} onBlur={(e) => setValue(formattingTime(e.target.value))}
            value={value} className={error ? 'p-invalid' : ''} disabled={readOnly}
          />
        )
      case 'autocomplete':
        return (
          <AutoComplete
            disabled={readOnly} field={autocompleteField} className={error ? 'p-invalid' : ''} id={id} onSelect={(e) => onSelect(e.value)} style={{ width: '100%' }}
            value={value} suggestions={suggestions} completeMethod={search} onChange={(e) => setValue(e.value)} inputStyle={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }}
          />
        )
      case 'numeric': {
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }} id={id} onChange={(e) => setValue(e.target.value)}
            value={value} className={error ? 'p-invalid' : ''} disabled={readOnly} keyfilter='int'
          />
        )
      }
      default:
        return (
          <InputText
            style={{ ...fontStyle, width: '100%', color: getColorDiff(diffStatus, error) }}
            id={id} onChange={(e) => setValue(e.target.value)}
            value={value} className={error ? 'p-invalid' : ''} disabled={readOnly}
          />
        )
    }
  }

  return (
    <div className='card flex justify-content-center' style={{ marginTop: 25, width: '100%' }}>
      <span className='p-float-label'>
        {renderCorrectInput()}
        <label style={{ fontFamily: 'Lexend', color: getColorDiff(diffStatus, error) }}>{label}
        </label>
      </span>
    </div>
  )
}
