import { Reorder } from 'framer-motion'
import { Text } from '../../Components'

const ulStyle = {
  listStyle: 'none',
  padding: 0,
  margin: 0,
  position: 'relative',
  width: '40%'
}

const liStyle = {
  listStyle: 'none',
  margin: 0,
  borderRadius: 13,
  marginBottom: 10,
  width: '60%',
  padding: '12px 0px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  cursor: 'grab',
  fontFamily: 'Lexend',
  border: '1px solid var(--ck-color-toolbar-border)',
  fontWeight: 'normal'
}

export const DraggableListServicePriority = ({ data: items, onUpdate }) => (
  <Reorder.Group style={ulStyle} axis='y' values={items} onReorder={onUpdate}>
    {items.map((item, index) => (
      <Reorder.Item style={liStyle} key={item.id} value={item} className='reorder-list'>
        <Text
          className='draggableNumber'
          value={index + 1} center
          style={{
            width: 25,
            height: 25,
            position: 'absolute',
            left: 20,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
        <Text value={item.id} />
      </Reorder.Item>
    ))}
  </Reorder.Group>
)
