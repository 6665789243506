import React from 'react'
import { Flex, Text } from '../../Components'
import { HotelServices } from './HotelServices'
import { TrainServices } from './TrainServices'
import { PlaneServices } from './PlaneServices'

export const TestDyn = () => {
  return (
    <Flex fw style={{ marginTop: 20 }}>
      <Text value='Anteprima risultati dinamico' bold title size={24} style={{ marginBottom: 30 }} />
      <HotelServices />
      <TrainServices />
      <PlaneServices />
    </Flex>
  )
}
