import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Text, Flex, Spinner, ChatMessages, Card, Button, ConfirmModal } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { Campi1a10Section, HotelSection, PlaneSection, TrainSection, TransferSection, TravellerSection } from '../../Gigi/GigiForm/Sections'
import { getDiffObj, useDefaultChatData } from './Functions'
import { getStateDefaultData } from '../../Gigi/GigiForm/Functions'
import { nanoid } from 'nanoid'

export const AutoTest = () => {
  const { autoTestId } = useParams()
  const { data: autoTest, isSuccess, isError } = useCQuery(['autoTest', parseInt(autoTestId)])
  const { data: autoTests = [] } = useCQuery(['autoTests'])
  const { correctState } = autoTest || {}
  const defaultProps = [correctState, () => { }]
  const { isSuccess: isSuccessDataChat, ...data } = useDefaultChatData()
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (isError) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Test non trovato' })
      navigate('/autotest/list')
    }
  }, [isError])

  const runTest = () =>
    FC.service('public').create({ ...data, userInput: autoTest?.messages?.[0]?.message, autoTestId: parseInt(autoTestId), idConvFiera: nanoid() })

  const validateTest = async () => {
    const { ok, message } = await FC.service('info').create({ action: 'validateTest', autoTestId })
    invalidateQuery(['autoTest', parseInt(autoTestId)])
    if (ok) return window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Autotest aggiornato' })
    window.growl.show({ severity: 'error', summary: 'Errore', detail: message })
  }

  if (!isSuccessDataChat || !isSuccess) return <Spinner />
  return (
    <Flex fw>
      <ConfirmModal
        visible={[modalVisible, setModalVisible]} header='Conferma' onConfirm={validateTest}
        label="Vuoi validare il test? Il risultato dell'autotest verrà aggiornato"
      />
      <Flex row fw style={{ marginBottom: 10 }}>
        <Button label='Torna indietro' onClick={() => navigate('/autotest/list')} style={{ width: 170 }} icon='left' />
        <Flex row style={{ width: 'calc(100% - 340px)', marginRight: 170 }}>
          <Button
            label='Test precedente' disabled={!autoTests.find((test) => test.id === (parseInt(autoTestId) - 1))}
            onClick={() => navigate(`/autotest/${parseInt(autoTestId) - 1}`)} style={{ marginRight: 10 }} allowMultipleClick
          />
          <Text value={'Test ID: ' + parseInt(autoTestId)} size={18} style={{ width: 120 }} center />
          <Button
            label='Test successivo' disabled={!autoTests.find((test) => test.id === (parseInt(autoTestId) + 1))}
            onClick={() => navigate(`/autotest/${parseInt(autoTestId) + 1}`)} style={{ marginLeft: 10 }} allowMultipleClick
          />
        </Flex>
      </Flex>
      <Card style={{ width: '100%' }}>
        <ChatMessages messages={autoTest?.messages} onVisualized={false} />
      </Card>
      <Flex fw as jb row>
        <Flex style={{ width: 'calc(50% - 10px)' }}>
          <Text value='Risultato atteso' size={18} style={{ marginBottom: 55, marginTop: 20 }} />
          <TravellerSection state={defaultProps} readOnly />
          <TransferSection state={defaultProps} readOnly />
          <Campi1a10Section state={defaultProps} readOnly />
          <PlaneSection state={defaultProps} readOnly />
          <TrainSection state={defaultProps} readOnly />
          <HotelSection state={defaultProps} readOnly />
        </Flex>
        <Flex style={{ width: 'calc(50% - 10px)' }}>
          <Flex row>
            <Text value='Risultato ottenuto' size={18} style={{ marginBottom: 20, marginTop: 20, marginRight: 10 }} />
            <Button label='Esegui Test' onClick={runTest} />
            <Button label='Valida Test' onClick={() => setModalVisible(true)} style={{ marginLeft: 10 }} />
          </Flex>
          <TestResult conversationId={autoTest?.conversationId} correctState={correctState} />
        </Flex>
      </Flex>
    </Flex>
  )
}

const TestResult = ({ conversationId = null, correctState = {} }) => {
  const { data: conversation = {}, isSuccess, isError } = useCQuery(['conversation', conversationId])
  const [lastProcessStepId, setLastProcessStepId] = useState()
  const [state, setState] = useState()
  const [diff, setDiff] = useState({})
  const [lastStatus, setLastStatus] = useState('')

  useEffect(() => {
    if (isSuccess) {
      const lastProcessStep = conversation?.lastProcessStep
      const lastPayload = lastProcessStep?.initialState || {}
      const lastProcess = conversation?.processes[conversation?.processes.length - 1]
      const lastStatus = lastProcess?.state
      setLastStatus(lastStatus)

      if (!state || lastProcessStepId !== lastProcessStep?.id || lastProcessStep?.initialState?.forceUpdate) {
        setLastProcessStepId(lastProcessStep?.id)
        // loop all the serviceFields, and check if it's equal to the correctState same field, else add it to the diff array
        const newState = getStateDefaultData(lastPayload)
        setDiff(getDiffObj(correctState, newState))
        setState(getStateDefaultData(lastPayload))
      }
    }
  }, [isSuccess, conversation])

  if (isError || !conversationId) return null
  if (!isSuccess) return <Spinner />

  const defaultProps = [state, () => { }]

  return (
    <Flex fw>
      <Text value={lastStatus === 2 ? 'In esecuzione' : ''} size={20} style={{ color: 'violet', marginBottom: 20, height: 15 }} />
      <TravellerSection state={defaultProps} readOnly diff={diff?.traveller || []} />
      <TransferSection state={defaultProps} readOnly diff={diff?.transfer || []} />
      <Campi1a10Section state={defaultProps} readOnly />
      <PlaneSection state={defaultProps} readOnly diff={diff?.plane || []} />
      <TrainSection state={defaultProps} readOnly diff={diff?.train || []} />
      <HotelSection state={defaultProps} readOnly diff={diff?.hotel || []} />
    </Flex>
  )
}
