import { Card, Flex } from '../../../../Components'
import { CustomHeader, TransferField } from '../Components'
import { getElementsByService, getServiceCount, readKey } from '../Functions'
import { getDiffStatus } from './Functions'

const { transfer: { departureDate, arrivalDate, departurePlace, arrivalPlace } } = getElementsByService()

export const TransferSection = ({ state: [state, setState], readOnly, diff }) => {
  const stateProp = [state, setState]
  const statePropRender = [state, (data) => setState(data, true)]

  const departureDateValue = readKey(state, departureDate?.location)
  const minDateArrival = departureDateValue
    ? new Date(new Date(departureDateValue.split('/')[2], departureDateValue.split('/')[1] - 1, departureDateValue.split('/')[0]))
    : new Date()

  return (
    <Card customHeader={<CustomHeader title='DATI TRASFERTA' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw wrap row>
        <TransferField state={statePropRender} item={departureDate} readOnly={readOnly} diffStatus={getDiffStatus(departureDate, diff)} />
        <TransferField state={stateProp} item={{ ...arrivalDate, minDate: minDateArrival }} readOnly={readOnly} diffStatus={getDiffStatus(arrivalDate, diff)} />
        <TransferField
          state={stateProp} readOnly={readOnly}
          item={{ ...departurePlace, notRequired: !getServiceCount(state, 'plane') && !getServiceCount(state, 'train') }}
          diffStatus={getDiffStatus(departurePlace, diff)}
        />
        <TransferField state={stateProp} item={arrivalPlace} readOnly={readOnly} diffStatus={getDiffStatus(arrivalPlace, diff)} />
      </Flex>
    </Card>
  )
}
