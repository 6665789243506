import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../../Services'

export const useSociety = (societyId) => {
  const [society, setSociety] = useState()
  const { data, isSuccess } = useCQuery(['environment', societyId])

  useEffect(() => {
    isSuccess && setSociety(data)
  }, [isSuccess, data])

  const setSocietyProp = (prop, value) =>
    setSociety({ ...society, [prop]: value })

  return [society, setSocietyProp, isSuccess]
}

export const saveSociety = async (society) => {
  /* if (!society?.name) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  } */
  if (society.id === 'new') {
    delete society.id
    const res = await FC.service('environments').create(society)
    invalidateQuery(['environment', 'environments'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('environments').patch(society.id, society)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

export const deleteSociety = async (societyId) => {
  const res = await FC.service('environments').remove(societyId)
  invalidateQuery(['environment', 'environments'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
