import React from 'react'
import background from '../bg.png'

export const Background = ({ children }) => (
  <div
    style={{
      backgroundImage: `url(${background})`,
      backgroundColor: 'lightgray 50%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top'
    }}
  >
    {children}
  </div>
)
