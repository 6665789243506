import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Input, Text } from '../../Components'
import { FC, invalidateQuery, useCQuery } from '../../Services'
import { useNavigate, useParams } from 'react-router-dom'

export default function NewPrompt () {
  const { promptId } = useParams()
  const { data: prompt, isSuccess } = useCQuery(['prompt', parseInt(promptId)])
  const [basePrompt, setBasePrompt] = useState('')
  const [preExtractPrompt, setPreExtractPrompt] = useState('')
  const [postExtractPrompt, setPostExtractPrompt] = useState('')
  const [type, setType] = useState('')
  const [jsonField, setJsonField] = useState('')
  const navigate = useNavigate()
  const isNew = promptId === 'new'

  const savePrompt = async () => {
    if (!basePrompt || !preExtractPrompt || !postExtractPrompt || !type) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Compilare tutti i campi' })
    }
    let result
    (isNew)
      ? result = await FC.service('prompts').create({ basePrompt, preExtractPrompt, postExtractPrompt, type, jsonField, user: 'andrea' })
      : result = await FC.service('prompts').patch(promptId, { basePrompt, preExtractPrompt, postExtractPrompt, type, jsonField, user: 'andrea' })
    if (result) {
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Prompt creato' })
      navigate('/prompts/' + result.id)
    }
    return invalidateQuery(['prompts', 'prompt'])
  }

  useEffect(() => {
    if (isSuccess) {
      const { basePrompt = '', preExtractPrompt = '', postExtractPrompt = '', type = '', jsonField = '' } = prompt || {}
      setBasePrompt(basePrompt)
      setPreExtractPrompt(preExtractPrompt)
      setPostExtractPrompt(postExtractPrompt)
      setType(type)
      setJsonField(jsonField)
    }
  }, [isSuccess])

  return (
    <Flex fw js>
      <Card style={{ width: '90%' }}>
        <Flex fw>
          <Text value='Creazione Prompt' size={30} bold title style={{ marginBottom: 20 }} />
          <Flex fw row wrap>
            <Input
              value={basePrompt} id='basePrompt' label='Spiegazione campo'
              style={{ width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }}
              onChange={({ basePrompt }) => setBasePrompt(basePrompt)}
            />
            <Input
              value={preExtractPrompt} id='preExtractPrompt' label='PreExtractPrompt'
              style={{ width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }}
              onChange={({ preExtractPrompt }) => setPreExtractPrompt(preExtractPrompt)}
            />
            <Input
              value={postExtractPrompt} id='postExtractPrompt' label='PostExtractPrompt'
              style={{ width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }}
              onChange={({ postExtractPrompt }) => setPostExtractPrompt(postExtractPrompt)}
            />
            <Input
              value={type} id='type' label='Tipo'
              style={{ width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }}
              onChange={({ type }) => setType(type)}
            />
            <Input
              value={jsonField} id='jsonField' label='Chiave campo JSON'
              style={{ width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }}
              onChange={({ jsonField }) => setJsonField(jsonField)}
            />
          </Flex>
          <Button style={{ width: 150 }} label='Salva' icon='check' onClick={savePrompt} />
        </Flex>
      </Card>
    </Flex>
  )
}
