import React from 'react'
import { Button, Flex, Spinner, Text } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { columnProps } from '../Prompts/Functions'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { FC, useCQuery } from '../../Services'
import { useDefaultChatData } from './AutoTest/Functions'

export const AutoTestsList = () => {
  const { data: AutoTests, isSuccess } = useCQuery(['autoTests'])
  const navigate = useNavigate()
  const { isSuccess: isSuccessDataChat, ...data } = useDefaultChatData()

  const BtnActions = ({ id }) => (
    <Flex row width={100}>
      <Button round tooltip='Apri' icon='beauty' onClick={() => navigate('/autotest/' + id)} />
    </Flex>
  )

  const runAllTests = () =>
    FC.service('info').create({ action: 'runAllTests', chatData: data })

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw>
      <Flex row fw jb>
        <Flex style={{ width: '25%' }} />
        <Flex style={{ width: '25%' }}>
          <Text value='Auto Test' size={20} />
        </Flex>
        <Flex ae style={{ width: '25%' }}>
          <Button label='Lancia tutti i test' onClick={runAllTests} style={{ marginLeft: 10 }} />
        </Flex>
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={AutoTests} emptyMessage='Nessun test trovato' responsiveLayout='scroll' paginator rows={12}
      >
        <Column field='id' {...columnProps('Id')} />
        <Column field='messages.0.message' {...columnProps('Messaggio')} />
        <Column style={{ border: 'none', height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
