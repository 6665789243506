import quotesType from '../../State/quotesType'
export const alert = (severity, summary, detail) => window.growl.show({ severity, summary, detail })
export const services = quotesType

export const planeTrainCoefficient = {
  Base: [
    {
      code: 'coefficienteDurata',
      name: 'Coefficiente Durata',
      value: 0.1
    },
    {
      code: 'coefficienteScostamento',
      name: 'Coefficiente Scostamento',
      value: 0.1
    }
  ]
}

export const hotelCoefficientState = {
  Base: [
    {
      code: 'coefficienteDistanza',
      name: 'Coefficiente Distanza',
      value: 0.1
    }
  ],
  Corporate: [
    {
      code: 'coefficienteTariffaPubblica',
      name: 'Tariffa Pubblica',
      value: 1
    },
    {
      code: 'coefficienteTariffaAgenzia',
      name: 'Tariffa Agenzia',
      value: 0.9
    },
    {
      code: 'coefficienteTariffaCliente',
      name: 'Tariffa Cliente',
      value: 0.95
    }
  ],
  Trattamento: [
    {
      code: 'ro',
      name: 'Room Only',
      value: 0.2
    },
    {
      code: 'bb',
      name: 'Bed and Breakfast',
      value: 0.2
    },
    {
      code: 'hb',
      name: 'Mezza Pensione',
      value: 0.13
    },
    {
      code: 'fb',
      name: 'Pensione Completa',
      value: 0.12
    },
    {
      code: 'dn',
      name: 'Cena Inclusa',
      value: 0.12
    },
    {
      code: 'ln',
      name: 'Pranzo Incluso',
      value: 0.12
    },
    {
      code: 'al',
      name: 'All Inclusive',
      value: 0.12
    }
  ],
  'Livelli Hotel Program': [
    {
      code: 'coefficienteHotelProgram0',
      name: 'Livello 0',
      value: 1
    },
    {
      code: 'coefficienteHotelProgram1',
      name: 'Livello 1',
      value: 0.85
    },
    {
      code: 'coefficienteHotelProgram2',
      name: 'Livello 2',
      value: 0.9
    },
    {
      code: 'coefficienteHotelProgram3',
      name: 'Livello 3',
      value: 0.95
    }
  ]
}

export const prepareStates = (json, service) => {
  const s = quotesType.find(serv => serv.id === service)
  const finalState = []
  s.filters.forEach((filter) => {
    finalState.push({ type: filter.name, ...json })
  })
  return finalState
}
