import React, { useEffect, useState } from 'react'
import { ChatMessages, ChatWrapper, Flex, Spinner, Writer } from '../../Components'
import { FC, useCQuery } from '../../Services'
import { UnitsVisualizer, DebugWriter } from '../../Components/Chat/Debug'
import { useAuth } from '../../Provider/AuthProvider'
import SettingsParameterTest from './Components/SettingsParameterTest'

const defaultParams = {
  codSociety: window?._env_?.ENVIRONMENT === 'production' ? 'AA' : 'RO'
}

export function TestChat () {
  const { data: unitsData = [], isSuccess } = useCQuery(['units'])
  const { data: travellersData = [], isSuccess: isSuccessTravellers } = useCQuery(['travellers'])
  const units = unitsData?.map(({ name }) => name)
  const [travellersOptions, setTravellersOptions] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedUnit, setSelectedUnit] = useState('newMessage')
  const [selectedPID, setSelectedPID] = useState()
  const [selectedEmail, setSelectedEmail] = useState('mranocchia@siapcn.it')
  const [selectedFormalita, setSelectedFormalita] = useState(75)

  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { debugMode, id: userId } = userObj

  useEffect(() => {
    if (isSuccessTravellers) {
      setTravellersOptions(travellersData?.map(({ pid }) => pid))
      setSelectedPID(travellersData?.[0]?.pid)
    }
  }, [travellersData])

  const sendMessage = async (message) => {
    const oldMessages = [...messages]
    setMessages([...oldMessages, { message, user: 'me' }])
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: (!!debugMode && selectedUnit) || 'newMessage',
      user: userId,
      pid: selectedPID,
      tomcatCode: 'OUTS',
      userEmail: selectedEmail,
      operatorEmail: selectedEmail,
      userInput: message,
      levelFormalita: selectedFormalita || 75,
      isDebug: false,
      ...defaultParams
    })
    setMessages([...oldMessages, { message, user: 'me' }, { message: res?.chatGptInfo?.messageToSend, user: 'bot', jsonPayload: res }])
    return true
  }

  const debugSendMessage = async (data = {}) => {
    if (!data?.userInput) return false
    const oldMessages = [...messages]
    setMessages([...oldMessages, { message: data?.userInput, user: 'me' }])
    const res = await FC.service('public').create({
      type: 'testUnit',
      tomcatCode: 'OUTS',
      pid: selectedPID,
      unit: (!!debugMode && selectedUnit) || 'newMessage',
      user: userId,
      userEmail: 'test@siapcn.it',
      levelFormalita: selectedFormalita || 75,
      operatorEmail: selectedEmail,
      isDebug: false,
      ...data,
      ...defaultParams
    })
    setMessages([...oldMessages, { message: data?.userInput, user: 'me' }, { message: res?.chatGptInfo?.messageToSend, user: 'bot', jsonPayload: res }])
    return true
  }

  if (!isSuccess || !isSuccessTravellers) return <Flex fw fh><Spinner /> </Flex>
  return (
    <Flex fw fh>
      <Flex fw as je style={{ marginTop: -15, marginBottom: 5 }} row />
      <Flex fw fh as row>
        <Flex style={{ width: '60%' }}>
          <ChatWrapper>
            <ChatMessages messages={messages} />
            {debugMode
              ? <DebugWriter sendMessage={debugSendMessage} />
              : null}
            <Writer sendMessage={sendMessage} />
          </ChatWrapper>
        </Flex>
        <Flex js style={{ width: '40%', height: '100%' }}>
          <SettingsParameterTest
            selectedFormalita={selectedFormalita}
            setSelectedFormalita={setSelectedFormalita}
            debugMode={debugMode}
            units={units}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            travellersOptions={travellersOptions}
            selectedPID={selectedPID}
            setSelectedPID={setSelectedPID}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
          />
          <UnitsVisualizer selectedUnit={selectedUnit} />
        </Flex>
      </Flex>
    </Flex>

  )
}
