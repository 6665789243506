import { useEffect } from 'react'
import { Flex } from '../../../../Components'
import { FloatInput } from './InputForm'

export const TravellerInput = ({ state: [state, setState], value: [value, setValue], item, secondItem, count, readOnly, miss: [error, setError] }) => {
  const { type, label, id, options, minDate } = item

  useEffect(() => {
    if (error && value) setError(false)
  }, [value, error, setError])

  return (
    <>
      <Flex key={item.id} as js style={{ width: '23%', margin: '0px 1%' }}>
        <FloatInput value={[value, setValue]} id={count + id} label={label.it} type={type} options={options} readOnly={readOnly} minDate={minDate} error={error} />
      </Flex>
    </>
  )
}
