import { CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'

export const ScatterChartComponent = ({
  data,
  xaxis, // se presente e valorizzato mostra l'asse delle x con la relativa etichetta
  yaxis, // se presente mostra l'asse delle y
  cartesian, // se presente mostra il piano cartesiano
  legend, // se presente mostra la legenda
  dataKeys,
  yAxisProps,
  xAxisProps,
  tooltipProps,
  scatterProps
}) => {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ScatterChart>
        {cartesian ? <CartesianGrid strokeDasharray='3 3' /> : null}
        {xaxis ? <XAxis dataKey='name' {...xAxisProps} /> : null}
        {yaxis ? <YAxis {...yAxisProps} /> : null}
        <Tooltip {...tooltipProps} />
        {legend ? <Legend /> : null}
        <Scatter name='Execution Time' data={data} fill='red' {...scatterProps} />
      </ScatterChart>
    </ResponsiveContainer>
  )
}
