import { useNavigate, useParams } from 'react-router-dom'
import { saveTraveller, useTraveller } from './Functions'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  pid: { label: 'PID' },
  email: { label: 'Email' },
  phone: { label: 'Telefono' }
}

export function TravellerEditor () {
  const { travellerId } = useParams()
  const navigate = useNavigate()
  const [traveller, setTraveller, isSuccess] = useTraveller(travellerId)
  const { id = '', clientId } = traveller || {}
  const { data: clients = [] } = useCQuery(['clients'])

  const save = async () => {
    const res = await saveTraveller(traveller)
    res?.id && navigate('/admin/travellers/' + res?.id?.toString())
    return res
  }

  if (!isSuccess || !traveller) return <Spinner />

  const isNew = id === 'new'

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Viaggiatore`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20 }}>
        {Object.keys(fields).map((key) =>
          <Input key={key} value={traveller[key]} onChange={({ [key]: value }) => setTraveller(key, value)} label={fields[key]?.label} id={key} style={{ ...defaultStyle }} />
        )}
        <Input
          value={clientId} dropdown options={clients.map(({ id, name }) => ({ value: id, label: name }))}
          onChange={({ clientId: value }) => setTraveller('clientId', value)}
          label='Cliente' id='clientId' style={{ ...defaultStyle }}
        />
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/admin/travellers/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
