import { Button, Card, Flex, Text } from '../../../../Components'
import { CustomHeader, SingleServiceContainer, TransferField } from '../Components'
import { addService, getElementsByService, getServiceCount, readKey, removeService } from '../Functions'
import { getDiffStatus } from './Functions'

const { hotel: { checkInDate, checkOutDate, hotelCity, hotelName, hotelStars, hotelParking, treatment } } = getElementsByService()

export const HotelSection = ({ state: [state, setState], readOnly, diff }) => {
  const stateProp = [state, setState]
  const statePropRender = [state, (data) => setState(data, true)]

  return (
    <Card key='hotel' collapsable customHeader={<CustomHeader title='SERVIZI HOTEL' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw>
        <Flex fw row jb>
          {!getServiceCount(state, 'hotel') ? <Text value='Nessun servizio inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <div />}
          {!readOnly && <Button icon='plus' label='Aggiungi servizio' onClick={async () => setState(await addService(state, 'hotel'))} />}
        </Flex>
        {Array(getServiceCount(state, 'hotel')).fill().map((_, i) => {
          const departureDateValue = readKey(state, checkInDate?.location, i)
          const minDateCheckOut = departureDateValue
            ? new Date(new Date(departureDateValue.split('/')[2], departureDateValue.split('/')[1] - 1, departureDateValue.split('/')[0]))
            : new Date()
          return (
            <SingleServiceContainer key={i} onRemove={() => setState(removeService(state, 'hotel', i))} readOnly={readOnly}>
              <TransferField state={statePropRender} item={checkInDate} key='checkInDate' count={i} readOnly={readOnly} diffStatus={getDiffStatus(checkInDate, diff, i)} />
              <TransferField state={stateProp} item={{ ...checkOutDate, minDate: minDateCheckOut }} key='checkOutDate' count={i} readOnly={readOnly} diffStatus={getDiffStatus(checkOutDate, diff, i)} />
              <TransferField state={stateProp} item={hotelCity} key='hotelCity' count={i} readOnly={readOnly} diffStatus={getDiffStatus(hotelCity, diff, i)} />
              <TransferField state={stateProp} item={hotelName} key='hotelName' count={i} readOnly={readOnly} diffStatus={getDiffStatus(hotelName, diff, i)} />
              <TransferField state={stateProp} item={hotelStars} key='hotelStars' count={i} readOnly={readOnly} diffStatus={getDiffStatus(hotelStars, diff, i)} />
              <TransferField state={stateProp} item={hotelParking} key='hotelParking' count={i} readOnly={readOnly} diffStatus={getDiffStatus(hotelParking, diff, i)} />
              <TransferField state={stateProp} item={treatment} key='treatment' count={i} readOnly={readOnly} diffStatus={getDiffStatus(treatment, diff, i)} />
            </SingleServiceContainer>
          )
        })}
      </Flex>
    </Card>
  )
}
