import { Button } from '../Common/Button'
import { Flex } from '../Common/Flex'
import { Spinner } from '../Common/Spinner'
import { TextareaAuto } from '../Common/TextareaAuto'

const handleKeypress = (send) => (e) => {
  if (e.code === 'Enter' && !e.shiftKey) {
    e.preventDefault()
    send()
  }
}

/**
 * Componente Composer per la gestione dell'input di messaggi e allegati.
 *
 * @param {Object} props Oggetto che contiene le proprietà passate al componente.
 * @param {Function} props.update Funzione per aggiornare lo stato del messaggio corrente.
 * @param {Function} props.send Funzione per inviare il messaggio e gli allegati.
 * @param {string} props.currentMessage Messaggio attualmente digitato dall'utente.
 * @param {boolean} props.sending Indica se un messaggio è in fase di invio.
 * @param {Object} props.composerStyle Stili CSS aggiuntivi per il componente.
 * @returns {React.Component} Ritorna un componente React che rappresenta l'area di composizione dei messaggi.
 */
export const Composer = ({ update, send, currentMessage, sending, composerStyle }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        paddingRight: 40,
        ...composerStyle
      }}
    >
      <TextareaAuto
        placeholder='Scrivi un messaggio (Enter per inviare, Maiusc + Enter per andare a capo)'
        style={{ paddingTop: 12, minHeight: 45 }}
        value={currentMessage}
        autoFocus
        onChange={(e) => update(e.target.value)}
        onKeyPress={handleKeypress(send)}
      />
      {sending
        ? <Flex style={{ marginLeft: 10, height: 44, width: 44 }}> <Spinner /></Flex>
        : <Button icon='send' iconSize={36} onClick={send} style={{ marginLeft: 10, height: 44, width: 44, borderRadius: 22 }} />}
    </div>
  )
}
