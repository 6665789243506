import React, { useState } from 'react'
import { Flex, Text } from '../../../../Components'
import userLight from '../../../../Resources/users/Utenti_NORA-01-LIGHT.png'
import userDark from '../../../../Resources/users/Utenti_NORA-01-DARK.png'
import userMaleLight from '../../../../Resources/users/Utenti_NORA-02-LIGHT.png'
import userMaleDark from '../../../../Resources/users/Utenti_NORA-02-DARK.png'
import userFemaleLight from '../../../../Resources/users/Utenti_NORA-03-LIGHT.png'
import userFemaleDark from '../../../../Resources/users/Utenti_NORA-03-DARK.png'
import { cap } from '../../../../State'

export const TravellersCard = ({ nome, cognome, email = '', sex = '', onClick }) => {
  const [isDark] = cap.darkMode.useState()
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)
  const hoverColorLight = isHovered ? '#D3D3D3' : ''
  const hoverColorDark = isHovered ? '#333333' : ''

  const setImage = () => {
    if (isDark) return sex === 'M' ? userMaleLight : sex === 'F' ? userFemaleLight : userLight
    else return sex === 'M' ? userMaleDark : sex === 'F' ? userFemaleDark : userDark
  }

  return (
    <Flex wrap style={{ width: 210, cursor: 'pointer', backgroundColor: isDark ? hoverColorDark : hoverColorLight, borderRadius: 10 }} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Flex fw row wrap jb>
        <img src={setImage()} alt='User' style={{ maxWidth: 210, maxHeight: 210, marginTop: 5, overflow: 'hidden', textOverflow: 'ellipsis' }} />
      </Flex>
      <Flex style={{ backgroundColor: isDark ? 'white' : 'black', borderRadius: 10, height: 60, width: 182, padding: 2, marginBottom: 10 }}>
        <Text value={nome.toUpperCase() + ' ' + cognome.toUpperCase()} color={isDark ? 'black' : 'white'} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
        <Text size={12} value={email} color={isDark ? 'black' : 'white'} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} />
      </Flex>
    </Flex>
  )
}
