import React from 'react'
import { Card, Flex, Icon, PanelText } from '../../../Components'
import { QuotesTypeElement } from './QuotesTypeElement'

export const ServiceElement = ({ service: { icon, name, filters }, coefficient }) => (
  <Flex js as fw wrap style={{ gap: 15 }}>
    <Flex row js as>
      <Flex
        style={{
          width: 53,
          height: 48,
          borderRadius: 10,
          border: '1px solid #E4E7EC',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          marginRight: 10
        }}
      >
        <Icon name={icon} />
      </Flex>
      <Flex as fh>
        <PanelText value={`Servizio ${name}:`} header />
        <PanelText value={`Imposta coefficienti per ${name}:`} />
      </Flex>
    </Flex>
    <Flex fw as js style={{ gap: 15 }}>
      {filters.map((filter, index) => (
        <Card key={index}>
          <QuotesTypeElement coefficient={coefficient} filter={filter} />
        </Card>
      ))}
    </Flex>
  </Flex>
)
