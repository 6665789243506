import { useNavigate } from 'react-router-dom'
import { useCQuery } from '../../Services'
import { Button, Flex, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { columnProps } from './Functions'

export function PromptsTable () {
  const { data: prompts, isSuccess } = useCQuery(['prompts'])
  const navigate = useNavigate()

  const BtnActions = ({ id }) => (
    <Flex fw row je ae>
      <Button label='Modifica' icon='edit' style={{ marginRight: 10 }} onClick={() => navigate(`/prompts/${id}`)} />
      {/*   <Button label='Elimina' icon='trash' style={{ marginRight: 10 }} onClick={() => navigate(`/prompts/${id}`)} /> */}
    </Flex>
  )

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/prompts/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }}
        value={prompts} emptyMessage='Nessun prompt inserito' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        filterDisplay='row' sortField='createdAt' sortOrder={-1}
      >
        <Column field='jsonField' {...columnProps('Campo')} />
        <Column field='basePrompt' {...columnProps('Spiegazione')} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
