import React from 'react'
import { FilterElement } from './FilterElement'
import { Flex } from '../../../Components'
import { Divider } from 'primereact/divider'
import { services } from '../Functions'

const FilterSections = (props) => {
  return (
    <>
      {services.map(({ id, ...service }, index) => {
        const flagAbilitazioneAuto = props?.flagAbilitazioneAuto
        const shouldRenderDivider = (index > 0) && (id !== 'car' || (id === 'car' && flagAbilitazioneAuto))
        const shouldRenderElement = id !== 'car' || flagAbilitazioneAuto

        return (
          <React.Fragment key={id}>
            {shouldRenderDivider && <Divider /> /* Render del Divider se necessario */}
            {shouldRenderElement &&
              <Flex key={index} fw>
                <Flex js as row fw>
                  <FilterElement
                    selectedFilters={props?.[id]?.selectedFilters}
                    options={props?.[id]?.optionsFilters}
                    service={service}
                    inputValues={props?.[id]?.inputValues}
                    inputRimborso={props?.[id]?.inputRimborso}
                    inputNoRimborso={props?.[id]?.inputNoRimborso}
                    radius={props?.[id]?.radius}
                    checkAutomaticHotel={props?.[id]?.checkAutomaticHotel}
                    logicaCameraNonInPenale={props?.[id]?.logicaCameraNonInPenale}
                    soloCamereNonInPenale={props?.[id]?.soloCamereNonInPenale}
                    tolleranzaGiorniPenale={props?.[id]?.tolleranzaGiorniPenale}
                    servicePriority={props?.[id]?.servicePriority}
                    flagAbilitazione={props?.[id]?.flagAbilitazione}
                    flagAbilitazioneAuto={props?.[id]?.flagAbilitazioneAuto}
                  />
                </Flex>
              </Flex>}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default FilterSections
