import React from 'react'
import { Card, Text } from '../../Components'

export const CardResult = ({ result, type }) => {
  if (type === 'Hotel') {
    return (
      <Card style={{ width: 300 }}>
        {result?.quoteType && <Text center value={'TIPO: ' + result.quoteType} bold title size={18} style={{ marginBottom: 10 }} />}
        <Text center value={result.nameHotel} bold title size={16} style={{ marginBottom: 10 }} />
        <Text center value={'TypeRoom: ' + result.typeRoom} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Trattamento: ' + result.treatment} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Città: ' + result.location} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Indirizzo: ' + result.address} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Latitudine: ' + result.latitudine} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Longitudine: ' + result.longitudine} title size={14} style={{ marginBottom: 10 }} />
        <Text center bold value={'Distanza: ' + parseInt(result?.distanza) + ' metri'} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Data Cancellazione: ' + result.cancellationDate} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Echo Token: ' + result.echoToken} title size={14} style={{ marginBottom: 10 }} />
        <Text center value={'Prezzo: ' + result.price} title size={14} style={{ marginBottom: 10 }} />
      </Card>
    )
  }
  if (type === 'Train') {
    return (
      <Card style={{ width: 300 }}>
        {result?.quoteType && <Text center value={'TIPO: ' + result.quoteType} bold title size={18} style={{ marginBottom: 10 }} />}
        {result?.routes?.map((route, ind) =>
          <React.Fragment key={ind}>
            <Text center value={'Tratta N°' + (ind + 1)} bold title size={16} style={{ marginBottom: 10 }} />
            <Text center value={'Compagnia: ' + route.companyLabel} title size={16} style={{ marginBottom: 10 }} />
            <Text center value={'Classe: ' + route.classTrain} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Tipo: ' + route.typeTrenoDescription} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Partenza: ' + route.labelStationDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Arrivo: ' + route.labelStationArrival} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Data Partenza: ' + route.dateDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Data Arrivo: ' + route.dateArrival} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Ora Partenza: ' + route.hourDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Ora Arrivo: ' + route.hourArrival} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Durata: ' + route.journeyDuration} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Echo Token: ' + route.echoToken} title size={14} style={{ marginBottom: 10 }} />
            {ind !== result?.routes?.length - 1 && <hr style={{ width: '80%', margin: 10 }} />}
          </React.Fragment>)}
        <Text center value={'Prezzo: ' + result.price} title size={14} style={{ marginBottom: 10 }} />
      </Card>
    )
  }
  if (type === 'Plane') {
    return (
      <Card style={{ width: 300 }}>
        {result?.quoteType && <Text center value={'TIPO: ' + result.quoteType} bold title size={18} style={{ marginBottom: 10 }} />}
        {result?.routes?.map((route, ind) =>
          <React.Fragment key={ind}>
            <Text center value={'Tratta N°' + (ind + 1)} bold title size={16} style={{ marginBottom: 10 }} />
            <Text center value={'Compagnia: ' + route.companyLabel || route.company} title size={16} style={{ marginBottom: 10 }} />
            <Text center value={'Classe: ' + route.classPlane} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Partenza: ' + route.labelDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Arrivo: ' + route.labelArrival} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Data Partenza: ' + route.dateDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Data Arrivo: ' + route.dateArrival} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Ora Partenza: ' + route.hourDeparture} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Ora Arrivo: ' + route.hourArrival} title size={14} style={{ marginBottom: 10 }} />
            {route.additionalServicesDetailed?.length && (
              <>
                <Text center value='Servizi aggiuntivi: ' title size={14} style={{ marginBottom: 10 }} />
                {route.additionalServicesDetailed?.map(({ name, amount, currency }, ind) =>
                  <Text key={ind} center value={ind + 1 + ') ' + name + ' ' + amount + ' ' + currency} title size={14} style={{ marginBottom: 10 }} />)}
              </>)}
            <Text center value={'Mini Rules: ' + route.miniRules || ''} title size={14} style={{ marginBottom: 10 }} />
            <Text center value={'Echo Token: ' + route.echoToken} title size={14} style={{ marginBottom: 10 }} />
            {ind !== result?.routes?.length - 1 && <hr style={{ width: '80%', margin: 10 }} />}
          </React.Fragment>)}
        <Text center value={'Prezzo: ' + result.price} title size={14} style={{ marginBottom: 10 }} />
        {result.ancillaryPrice && <Text center value={'Prezzo + Ancillary: ' + (result.totalPrice || '')} title size={14} style={{ marginBottom: 10 }} />}
      </Card>
    )
  }
  return null
}
