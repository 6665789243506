import React, { useEffect, useState } from 'react'
import { Card, Flex, ProgressBarComponent, Text } from '../../../Components'
import { STATE_ENUM } from '../../Gigi/GigiList/Functions'
import { useCQuery } from '../../../Services'
import { groupByState } from './Functions'

export const ProcessBar = ({ dates }) => {
  const { data: { processes = [] } = {}, isSuccess } = useCQuery(['getStats', ...dates])
  const [groupedProcesses, setGroupedProcesses] = useState({})

  useEffect(() => {
    isSuccess && setGroupedProcesses(groupByState(processes))
  }, [processes])

  if (!isSuccess) return null
  return (
    <Card title='Stato processi' style={{ width: '100%' }}>
      {Object.entries(groupedProcesses)
        .map(([key, value]) => (
          <Flex key={key} row js fw style={{ justifyContent: 'space-between', marginTop: 20 }}>
            <Flex style={{ width: 180, paddingRight: 10 }} row jb>
              <Text value={STATE_ENUM[key - 1]?.label} />
              <Text bold value={value + '/' + processes.length} />
            </Flex>
            <ProgressBarComponent value={value} total={processes.length} color={STATE_ENUM[key - 1]?.color} />
          </Flex>
        ))}
    </Card>
  )
}
