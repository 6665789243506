import React from 'react'
import { Flex, Spinner } from '../../../Components'
import ReactJsonViewCompare from 'react-json-view-compare'
import { useCQuery } from '../../../Services'
import { useParams } from 'react-router-dom'

export const JsonView = ({ stateIndex }) => {
  const { processId } = useParams()
  const { data: processSteps = [], isSuccess } = useCQuery(['processSteps', processId])

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw style={{ maxHeight: 'calc(100vh - 110px)' }} id='compare'>
      <ReactJsonViewCompare
        oldData={processSteps[stateIndex]?.initialState || {}}
        newData={processSteps[stateIndex]?.finalState || {}}
      />
    </Flex>
  )
}
