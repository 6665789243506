import { useEffect, useState } from 'react'
import { readKey, writeKey } from '../Functions'

export const useTransferField = ([json, setJson], location, count) => {
  const [value, setValue] = useState(readKey(json, location, count)) // lo inizializzo subito senza aspettare il primo render perché i campi di input sono rossi per un attimo

  const arrDependencies = [
    location?.includes('.hotel.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.hotel?.length : null,
    location?.includes('.train.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.train?.length : null,
    location?.includes('.plane.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.plane?.length : null,
    location?.includes('.hotel.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.hotel : null,
    location?.includes('.train.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.train : null,
    location?.includes('.plane.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.infoServices?.plane : null,
    location?.includes('.requestDataTransfer.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.requestDataTransfer : null,
    location?.includes('.profileData.') ? json?.transferInfo?.infoPax?.listOfPax?.[0]?.profileData : null
  ].filter(i => i !== null)

  const customSet = (value = '') => {
    setValue(value)
    const newJson = writeKey(json, location, count, value)
    Object.keys(newJson)?.length && setJson(newJson)
  }

  useEffect(() => {
    customSet(readKey(json, location, count))
  }, [...arrDependencies])

  return [value, customSet]
}
